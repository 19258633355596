import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
//import Conexao from '../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {TextField, Tooltip,Grid, Checkbox} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalImportarParametros = (props) => {
    const [open, setOpen] = React.useState(false)
    const [modeloOrigem, setModeloOrigem] = React.useState(-1)
    const [parametrosWeb, setParametrosWeb] = React.useState(false)
    const [parametrosSnmp, setParametrosSnmp] = React.useState(false)

    const handleClickOpen = () =>{
        setOpen(true)
    }

    const handleClose = () =>{
        setOpen(false)
    }
  return (
    <div>
     
      <Dialog 
        disableBackdropClick disableEscapeKeyDown
        hideBackdrop
        fullWidth
        maxWidth='sm'
        onClose={()=>props.closeModal()} aria-labelledby="customized-dialog-title" open={props.modeloDestino.modeloDestino > 0}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
         Importar parametros de outro modelo
        </DialogTitle>
        
        <DialogContent dividers >
            <Grid container spacing = {2} style ={{marginTop : '10px'}}>
                <TextField
                label = 'Modelo Origem'
                variant='outlined'
                InputLabelProps={{shrink :true}}
                fullWidth
                value = {props.modeloDestino.modeloDestino+' - '+props.modeloDestino.descricao}
                />
            </Grid>
            <br/>
           

            <Checkbox checked = {parametrosSnmp} onChange={()=>{
                setParametrosSnmp(!parametrosSnmp)
            }}/>Importar parametros SNMP<br/>

            <Checkbox checked = {parametrosWeb} onChange={()=>{
                setParametrosWeb(!parametrosWeb)
            }}/>Importar parametros WEB
            
        
            <Grid container spacing = {2} style ={{marginTop : '20px'}}>
                <TextField
                    label = 'Modelo Origem'
                    variant='outlined'
                    InputLabelProps={{shrink :true}}
                    fullWidth
                    select
                    onChange={(e)=>{
                        setModeloOrigem(e.target.value)
                    }}
                    SelectProps={{native:true}}
                    >
                        <option value ='-1'>Selecione um modelo</option>
                        {
                            props.modeloDestino.modeloDestino > 0 &&  props.modelos && props.modelos.filter(item => item.idmodelo !==props.modeloDestino.modeloDestino ).map(function(modelo){
                                return <option value ={modelo.idmodelo}>{modelo.idmodelo +' - '+modelo.descricao}</option>
                            })
                        }
                </TextField>
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={props.loading} autoFocus onClick={()=>props.closeModal()} variant='contained' color="secondary">
            Cancelar
          </Button>
          <Button disabled={props.loading} autoFocus onClick={()=>{
            if(modeloOrigem <=0){
                Swal.fire({
                    title : 'Datacount', 
                    text : 'Selecione um modelo de origem antes de continuar!', 
                    icon : 'error', 
                    showConfirmButton: false, 
                    confirmButtonText : 'SIM', 
                    showCancelButton : false, 
                    cancelButtonText : 'Não', 
                    timer : 2000
                })
                return;
            }
            Swal.fire({
                title : 'Datacount', 
                text : 'Deseja importar os parametros do modelo selecionado?', 
                icon : 'question', 
                showConfirmButton: true, 
                confirmButtonText : 'SIM', 
                showCancelButton : true, 
                cancelButtonText : 'Não'
            }).then(resp=>{
                if(resp.isConfirmed){
                    props.setLoading(true)
                    const parametrosURL = '&web='+(parametrosWeb ? 'true' : 'false')
                    +'&snmp='+(parametrosSnmp ? 'true' : 'false')
                    props.Conexao.get('parametrosController/importarParametrosModelo?modeloBase='+modeloOrigem+'&modeloDestino='+props.modeloDestino.modeloDestino+parametrosURL
                    ).then(response=>{
                        if(response.status == 200 && response.data.status == 'OK'){
                            Swal.fire({
                                title : 'Datacount', 
                                text : 'Parametros importados com sucesso!', 
                                icon : 'success', 
                                showConfirmButton: false, 
                                confirmButtonText : 'SIM', 
                                showCancelButton : false, 
                                cancelButtonText : 'Não', 
                                timer : 2000
                            }) 
                        }else{
                            Swal.fire({
                                title : 'Datacount', 
                                text : 'Não foi possível importar', 
                                icon : 'warning', 
                                showConfirmButton: false, 
                                confirmButtonText : 'SIM', 
                                showCancelButton : false, 
                                cancelButtonText : 'Não', 
                                timer : 2000
                            }) 
                        }
                        props.setLoading(false)
                        props.closeModal()
                    }).catch(error=>{
                        Swal.fire({
                            title : 'Datacount', 
                            text : 'Não foi possível importar', 
                            icon : 'error', 
                            showConfirmButton: false, 
                            confirmButtonText : 'SIM', 
                            showCancelButton : false, 
                            cancelButtonText : 'Não', 
                            timer : 2000
                        }) 
                        props.setLoading(false)
                    props.closeModal()
                    })
                    
                }
            })
          }} color="primary" variant='contained'>
            CONFIRMAR
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalImportarParametros)