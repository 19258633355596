import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow, Badge} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Template/TemplateRevenda';
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ExtensionTwoToneIcon from '@material-ui/icons/ExtensionTwoTone';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import DisplayToner from '../../components/DisplayToner'
import DisplayLinearToner from '../../components/DisplayLinearToner'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'; //case 2 
import SettingsPowerTwoToneIcon from '@material-ui/icons/SettingsPowerTwoTone';//energsaving
import ReportProblemIcon from '@material-ui/icons/ReportProblem';//case 5
import PrintIcon from '@material-ui/icons/Print';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import HelpIcon from '@material-ui/icons/Help';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import TablePagination from '@material-ui/core/TablePagination';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ContainerOrderBy from '../../components/ContainerOrderBy';


let temporizador = null;
const TrocaSuprimentos = (props)=>{
    const classes = useStyles();
    const [idLeitura, setIdLeitura]= React.useState(-1);
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
  
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [filtro, setFiltro] = useState([])
 
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [ trocas, setTrocas] = useState([])
   
    const [totalRegistros, setTotalRegistros]= useState([])
    const [orderBy, setOrderBy] = useState('datalog'); // Estado para a coluna de ordenação
    const [orderDirection, setOrderDirection] = useState('desc'); 
    const [registrosFiltrados, setRegistrosFiltrados] = useState([])

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const refStatus = React.createRef();
    const refDataInicial = React.createRef();
    const refDataFinal = React.createRef();
    const refPlaca = React.createRef();
    const refNumeroSerie = React.createRef();
    const refModelo = React.createRef();
    const refMarca = React.createRef();
    
    const refRazaoSocial = React.createRef();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        displayPage(registrosFiltrados, newPage, rowsPerPage)
        
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        getData();
    }, [])

  


    useEffect(()=>{
     
    }, [trocas])

    

    const clearForm = () =>{

        refDataInicial.current.value  ='';
        refDataFinal.current.value  ='';
        refPlaca.current.value  ='';
        refNumeroSerie.current.value  ='';
        refModelo.current.value  ='';
        refMarca.current.value  ='';
        refRazaoSocial.current.value  ='';
    }
   

    const getData = () =>{
        setLoading(true)
        Conexao.post('coletasController/getTrocasToner', {
            idempresa : props.match.params.chaveEmpresa, 
            idusuario : props.match.params.idempresa, 
            lidas : refStatus.current ?  refStatus.current.value : '', 
            datainicial : refDataInicial.current ?  refDataInicial.current.value : '', 
            datafinal : refDataFinal.current ?  refDataFinal.current.value : '', 
            numeroserie :refNumeroSerie.current ?  refNumeroSerie.current.value : '' , 
            modelo :refModelo.current ?  refModelo.current.value : '', 
            marca : refMarca.current ?  refMarca.current.value : '', 
            razaosocial  : refRazaoSocial.current ?  refRazaoSocial.current.value : '' 
        }).then(response=>{
            
            if(response.data.status == 'OK' ){
                
                setRegistrosFiltrados(response.data.payload)
            }else{
                setRegistrosFiltrados([])
            }
            setLoading(false)
        }).catch(error =>{
            setLoading(false)
            setRegistrosFiltrados([])
           
            Swal.fire({
                title : 'Printerconnect', 
                text : 'Sem conexão com servidor remoto !', 
                showCancelButton : true,
                cancelButtonText : 'Cancelar', 
                showConfirmButton :false, 
                icon : 'error'
            })
        });
    }

   
   const renderStatus = (status, ip) =>{
    switch(status){
    case 1:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><HelpIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>DESCONHECIDO</Typography></div>
            
        case 2:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><PowerSettingsNewIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>ON LINE</Typography></div>
            
        case 3:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><SettingsPowerIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>ENERGY SAVING</Typography></div>
            
        case 4:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><PrintIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>IMPRIMINDO</Typography></div>

        case 5:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><ReportProblemIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>OCORRÊNCIA</Typography></div>

        default:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><HelpIcon className={'icon-status-coleta'}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>DESCONHECIDO</Typography></div>
    }
        
   }

   const renderNivelToner = (nivel, rotulo, descricao, contador) =>{
       if(!nivel){
           nivel = 0;
       }
        switch(rotulo){
            case 'CIANO': return <DisplayLinearToner   
                rotulo ='ciano'
                noTop={true}                 
                width={"48px"} 
                height={"48px"} 
                color = {"#0d47a1"} 
                percentual={nivel} 
                lineSize={8}  
                contador={contador}
                descricao={descricao}/>

            case 'MAGENTA': return <DisplayLinearToner  
                rotulo ='magenta' 
                noTop={true}                 
                width={"48px"} 
                height={"48px"} 
                color = {"#c62828"} 
                percentual={nivel} 
                lineSize={8}  
                contador={contador}
                descricao={descricao}/>

            case 'AMARELO': return <DisplayLinearToner  
                rotulo ='amarelo' 
                noTop={true}                 
                width={"48px"} 
                height={"48px"} 
                color = {"#ffb300"} 
                percentual={nivel} 
                lineSize={8}  
                contador={contador}
                descricao={descricao}/>

            case 'PRETO': return <div>
                <DisplayLinearToner 
                    value ={nivel ? nivel : 0} 
                    width='100'
                    rotulo = 'preto'
                    />
                <div style={{textAlign:'center'}}>
                    <span style={{fontSize:'0.5vw'}}>S/N:{descricao}</span>
                </div>     
            </div>
                
        }
        
    
   }

   const renderCapacidade = (toner) =>{
        switch(toner){
            case 'PRETO':return toner.capacidadetonerpreto+'%'
            case 'CIANO':return toner.capacidadetonerciano+'%'
            case 'AMARELO':return toner.capacidadetoneramarelo+'%'
            case 'MAGENTA':return toner.capacidadetonerpreto+'%'
        }
   }


   useEffect(()=>{
    if(registrosFiltrados.length > 0 ){
        displayPage(registrosFiltrados, 0, rowsPerPage)
    }
   
}, [registrosFiltrados, rowsPerPage])


const handleOrderChange = (name, value) => {
    console.log("order by "+name+' direcao '+value)
   setOrderBy(name);
   setOrderDirection(value);
   

};


useEffect(()=>{
    displayPage(registrosFiltrados, 0, rowsPerPage)
}, [orderBy, orderDirection])



   function displayPage(dados, pagina, regs) {
        
    const startIndex = (pagina) * regs;
    const endIndex = startIndex + regs;

    if(orderBy != null){
        registrosFiltrados.sort((a, b) => {
            // Obtém os valores dos objetos para a chave especificada por orderBy
            const valueA = a[orderBy] != null ? a[orderBy] : '';
            const valueB = b[orderBy] != null ? b[orderBy] : '';
        
            // Função para verificar se um valor é numérico
            const isNumeric = (val) => !isNaN(parseFloat(val)) && isFinite(val);
        
            // Checa se algum dos valores é vazio
            if (valueA === '' && valueB !== '') {
                return orderDirection === 'asc' ? 1 : -1;
            }
            if (valueA !== '' && valueB === '') {
                return orderDirection === 'asc' ? -1 : 1;
            }
        
            // Verifica se os valores são numéricos
            const areBothNumeric = isNumeric(valueA) && isNumeric(valueB);
        
            if (areBothNumeric) {
                // Comparação numérica
                return orderDirection === 'asc' ? valueA - valueB : valueB - valueA;
            } else {
                // Comparação alfabética
                const strA = String(valueA);
                const strB = String(valueB);
                return orderDirection === 'asc' ? strA.localeCompare(strB) : strB.localeCompare(strA);
            }
        });
    }
    
    
    
    const pageItems = registrosFiltrados.slice(startIndex, endIndex);
    setTrocas(pageItems)
    setTotalRegistros(registrosFiltrados.length);
}

    return <Principal atualizar = {loading} breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">  
        <Typography>Você esta em:</Typography>          
            <Link color="inherit" to={'#'} >
                Detecções e Trocas de Suprimentos
            </Link>                
        </Breadcrumbs>
    }>
       <Grid container spacing = {2}>
            <Grid item xs = {9}></Grid>
            <Grid item xs = {3}>
                <div style={{width :'100%',display:'flex', justifyContent:'space-around'}}>
                    <div style={{paddingTop : '1%'}}> <Typography variant='caption' style={{fontSize:'1vw'}}>Linhas por página</Typography></div>
                    <div><TextField
                            value={rowsPerPage}
                            variant='outlined'
                            size='small'
                            select
                            onChange={(e)=>setRowsPerPage(e.target.value)}
                            SelectProps={{native:true}}
                            >
                                <option value='25'>25</option>
                                <option value='50'>50</option>
                                <option value='100'>100</option>
                            </TextField>
                    </div>
                </div>
           
            
            </Grid>
        </Grid>
        
        <Grid container spacing = {2} style ={{marginTop :'30px'}}>
            <Grid item xs = {2}>
                <TextField
                    label ='Status'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}
                    select
                    SelectProps={{native : true}}
                    inputRef={refStatus}
                    size ='small'
                    >
                    <option value ='f'>NÃO LIDAS</option>
                    <option value = 't'>LIDAS</option>
                    
                    </TextField>
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Data'
                    variant ='outlined'
                    fullWidth
                    size ='small'
                    InputLabelProps={{shrink : true}} 
                    type= 'date'      
                    inputRef={refDataInicial}             
                   />
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Data'
                    variant ='outlined'
                    fullWidth
                    size ='small'
                    InputLabelProps={{shrink : true}} 
                    type= 'date'      
                    inputRef={refDataFinal}             
                   />
            </Grid>

            <Grid item xs = {4}>
                <TextField
                    label ='Número Série'
                    variant ='outlined'
                    fullWidth
                    size ='small'
                    InputLabelProps={{shrink : true}} 
                    inputRef={refNumeroSerie}                   
                   />
            </Grid>
            
            <Grid item xs = {2} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' onClick={()=>getData()}><SearchIcon/> Pesquisar</Button>
                <Button color='secondary' variant='contained' onClick={()=>clearForm()}><ClearIcon/> Limpar</Button>
            </Grid>
        </Grid>
        <Grid container spacing = {2}>
            <Grid item xs = {2}>
                <TextField
                    label ='Marca'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}
                    inputRef={refMarca}
                    size ='small'
                    />                   
            </Grid>
            <Grid item xs = {3}>
                <TextField
                    label ='Modelo'
                    variant ='outlined'
                    fullWidth
                    size ='small'
                    InputLabelProps={{shrink : true}} 
                    inputRef={refModelo}                   
                   />
            </Grid>
            <Grid item xs = {5}>
                <TextField
                    label ='Razão Social'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}
                    inputRef={refRazaoSocial}
                    size ='small'
                    />                   
            </Grid>
            

        </Grid>
        <br/>
        
        <div className='div-form-container'>
            <Grid spacing={1}>
            <Table  className='table table-bordered table-striped table-hover table-sm'>
                <TableHead>
                    <TableCell align='left' style = {{width:"15%"}}>
                    <ContainerOrderBy 
                        title='Cliente' 
                        help ='Ordena a lista pelo endereço IP'
                        handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                        direcao = {orderDirection}
                        ativo = {orderBy}
                        campo ='razaosocial'
                        sizeIcon = {'30%'}
                        sizeText ={'70%'}
                        font = {10}
                        /> 
                    </TableCell>
                    <TableCell align='left' style = {{width:"8%"}}>
                    <ContainerOrderBy 
                        title='Localização' 
                        help ='Ordena a lista pelo endereço IP'
                        handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                        direcao = {orderDirection}
                        ativo = {orderBy}
                        campo ='setor'
                        sizeIcon = {'30%'}
                        sizeText ={'70%'}
                        font = {10}
                        /> 
                    </TableCell>
                    <TableCell align='left' style = {{width:"15%"}}>
                    <ContainerOrderBy 
                        title='Equipamento' 
                        help ='Ordena a lista pelo endereço IP'
                        handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                        direcao = {orderDirection}
                        ativo = {orderBy}
                        campo ='modelo'
                        sizeIcon = {'30%'}
                        sizeText ={'70%'}
                        font = {10}
                        /> 
                    </TableCell>  
                    <TableCell align='center' style = {{width:"5%"}}>Descrição Item</TableCell>
                    <TableCell align='center' style = {{width:"5%"}}>Retirado</TableCell>
                    <TableCell align='center' style = {{width:"5%"}}>Instalado</TableCell>
                                                 
                    <TableCell align='center' style = {{width:"8%"}}>Data</TableCell>

                </TableHead>
                <TableBody>
               {
                   trocas && trocas.map(function(troca, i){
                       return <TableRow key = {i}>
                            <TableCell align='left'>
                                <Typography variant='caption' style ={{fontSize :'0.6vw'}}>{troca.razaosocial}</Typography>
                            </TableCell>
                            <TableCell align='left'>
                                <Typography variant='caption' style ={{fontSize :'0.6vw'}}>{troca.setor}</Typography>
                            </TableCell>
                            <TableCell align='left' >
                                <Typography variant='caption' style ={{fontSize :'0.6vw'}}>{troca.modelo }</Typography><br/>
                                <Typography variant='caption' style ={{fontSize :'0.5vw'}}>{troca.numeroserie }</Typography>
                            </TableCell>
                            <TableCell align='center'>
                                <Typography variant='caption' style ={{fontSize :'0.6vw'}}>TONER {troca.cor}</Typography>
                            </TableCell>
                            <TableCell align='center' style={{textAlign:'center'}}>
                                <div style ={{width:'100%',display:'flex', alignContent:'center', alignItems:'center', justifyContent:'space-around'}}>
                                    {renderNivelToner(troca.nivel, troca.cor, troca.serialtoneranterior ? troca.serialtoneranterior :'n/d', '')}
                                </div>
                            </TableCell>
                            <TableCell align='center' style={{textAlign:'center'}}>
                                <div style ={{width:'100%',display:'flex', alignContent:'center', alignItems:'center', justifyContent:'space-around'}}>
                                    {renderNivelToner(troca.nivelnovo, troca.cor, troca.serialtonernovo ? troca.serialtonernovo : 'n/d', '')}
                                </div>
                            </TableCell>
                            
                            <TableCell align='center'>                            
                                <Typography variant='caption' style ={{fontSize :'05.vw'}}>{troca.datalog ? troca.datalog.substr(0,10)+' '+troca.horalog.substr(0,8) : ''}</Typography>   
                            </TableCell>
                       </TableRow>
                   })
               }
                </TableBody>
                
                
                <TableBody>
                   
                
                </TableBody>
            </Table>
        </Grid>                     
        </div>
        <div className='div-paginator-button' >
            <div style={{display :'flex', alignContent:'center',  alignItems:'center', backgroundColor : '#f0f0f0', borderRadius :'5px', width : '200px', paddingLeft :'5px', paddingRight :'5px'}}>
            <Typography variant='caption'><strong>Total de registros:</strong> {registrosFiltrados.length}</Typography>
            </div>
            <div style ={{ display:'flex', alignItems:'center'}}>
                <TablePagination
                        component="div"
                        count={registrosFiltrados.length}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage='Linhas por página'
                        rowsPerPageOptions={[]}
                        />
            </div>
            <div style={{ backgroundColor : '#f0f0f0', borderRadius :'5px', width : '200px', paddingLeft :'5px', paddingRight :'5px', paddingTop:'0px'}}>
            <Typography variant='caption'><strong>Order by:</strong> {orderBy}</Typography><br/>
            <Typography variant='caption'><strong>Direction:</strong> {orderDirection}</Typography>
            </div>
        </div>
     
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(TrocaSuprimentos)