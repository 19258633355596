import React, {useState, useEffect, useRef, Fragment} from 'react'

import {TextField,   Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
,Switch, TableRow, Badge, dat} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Block';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link , useHistory } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ExtensionTwoToneIcon from '@material-ui/icons/ExtensionTwoTone';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import LabeData from '../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import DisplayToner from '../../components/DisplayToner'
import DisplayLinearToner from '../../components/DisplayLinearToner'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'; //case 2 
import SettingsPowerTwoToneIcon from '@material-ui/icons/SettingsPowerTwoTone';//energsaving
import ReportProblemIcon from '@material-ui/icons/ReportProblem';//case 5
import PrintIcon from '@material-ui/icons/Print';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import HelpIcon from '@material-ui/icons/Help';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import SearchIcon from '@material-ui/icons/Search';

import TablePagination from '@material-ui/core/TablePagination';
import ModalDetalhesLeitura from './ModalDetalhesColeta'
import BlockIcon from '@material-ui/icons/Block';
import Alert from '@material-ui/lab/Alert'
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ModalFullDetalhes from '../../components/ModalFull2'
import DetalhesColeta from './DetalhesColeta'
import GestureIcon from '@material-ui/icons/Gesture'
import DoorOpen from '@material-ui/icons/MeetingRoom'
import CachedIcon from '@material-ui/icons/Cached'
import LinkOff from '@material-ui/icons/LinkOff'
import Slider from '@material-ui/core/Slider'
import FilterIcon from '@material-ui/icons/DateRange'
import FilterIcone2 from '@material-ui/icons/FilterList'
import DetailIcon from '@material-ui/icons/Apps'
import ModalHabilitaMonitorIndividual from './ModalMonitoramentoIndividual'
import IconeLocalizarClienteModal from '../../components/IconeLocalizarClienteModal'
import TemplateRevenda from '../Template/TemplateRevenda';
import SideBarGestaoColetas from './SideBarGestaoColetas'
import SideBarPadrao from './SideBarPadrao';
import SideBarFiltro from './SideBarFiltro';
import ModalOpcaoRelatorio from './ModalOpcaoRelatorio';
import ModalFiltroPapercutRelatorios from './ModalFiltroPapercutRelatorios';
import { AlertTitle } from '@material-ui/lab';
import BotaoMenuRelatorios from './BotaoMenuRelatorios';
import IconePDF from './Componentes/pdf.png'
import IconeCSV from './Componentes/csv.png'

//import {setColetaRepositorio} from '../../providers/Repository'
//
import {getDataRepository, substituirColetaEditada, listarClientesEquipamentos} from '../../providers/Repository'

let temporizador = null;

const GestaoColetasRelatorios = (props)=>{
    let history = useHistory();
    const classes = useStyles();
    const [showSelection, setShowSelection] = useState(false)
    const [checkdAll, setCheckedAll] = useState(false)
    
    const [idLeitura, setIdLeitura]= React.useState(-1);
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [colunaFiltro, setColunaFiltro] = useState('Cliente')
    const [resetFiltro, setResetFiltro]= useState(false)
    const [disabledRelatorios, setDisabledRelatorios]= useState(false)
    const [parametroFiltro, setParametroFiltro] = useState({
        marca : '', 
        modelo : '', 
        enderecoIP : '', 
        numeroSerie : '', 
        tipoData : 'cadastro', 
        dataInicial : '', 
        dataFinal : ''
    })
    const [filtro, setFiltro] = useState(false)
    const [selecionado, setSelecionado]= useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [ coletas, setColetas] = useState([])
    const [ coleta, setColeta] = useState([])
    const [ registros, setRegistros] = useState([])
   const [totalEquipamentosNovos, setTotalEquipamentosNovos]= useState(0)
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [totalRegistros, setTotalRegistros]= useState([])
    const [registrosFiltrados, setRegistrosFiltrados] = useState([])
    const [dias, setDias]= useState(0)
    const [chaveDca, setChaveDca]= useState('')
    const [clientes, setClientes ] = useState([])
    const [codigoCliente, setCodigoCliente]= useState(-1)

    //filtrosSidebar
    const resetParametrosFiltro = () =>{
        setParametroFiltro({
            marca : '', 
            modelo : '', 
            enderecoIP : '', 
            numeroSerie : '', 
            tipoData : 'cadastro', 
            dataInicial : '', 
            dataFinal : ''
        });
    }


   

    const [cliente, setCliente]= useState([])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        displayPage(registrosFiltrados, newPage, rowsPerPage)
        
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        displayPage(registrosFiltrados,0, event.target.value)
    };




    useEffect(()=>{
       // getData();
       setPage(0)
      
    }, [])
    useEffect(()=>{
        // getData();
        if(coletas){
            const temp = coletas
            for(let i = 0; i < temp.length; i++){
                temp[i].selecionado = 'f';
            }
            setColetas(temp);
        }
       
      
     }, [])
     useEffect(()=>{
        // getData();
        if(coletas){
            const temp = coletas
            for(let i = 0; i < temp.length; i++){
                temp[i].selecionado = 'f';
            }
            setColetas(temp);
        }
       
      
     }, [showSelection])

   
   


    useEffect(()=>{
        if(registrosFiltrados.length > 0 ){
            displayPage(registrosFiltrados, 0, rowsPerPage)
        }
       
    }, [registrosFiltrados])

    useEffect(()=>{
        if(registros.length > 0)
       setRegistros(registros.reduce((acumulador, item)=>{
        return item.equipamentonovo == 't' && item.ativo =='t' ? acumulador + 1 : acumulador
       }, 0))
       
    }, [registros])

   

    
    function displayPage(dados, pagina, regs) {
        
        const startIndex = (pagina) * regs;
        const endIndex = startIndex + regs;
        const pageItems = registrosFiltrados.slice(startIndex, endIndex);
        setColetas(pageItems)
        setTotalRegistros(registrosFiltrados.length);
    }

 


   

   
    const renderStatus = (status, ip, dias, ativo, painel = "") =>{
        if(ativo =='f'){
            

            return <div><Tooltip title ={'Equipamento Desativado'}><BlockIcon className={'icon-status-coleta5'}/></Tooltip> <Typography style = {{fontSize:"9px", marginTop:"0px"}}>DESATIVADO</Typography></div>        
        }

        if(ativo == 'f'){
            return <div><Tooltip title ={'Este equipamento esta desativado, atualizações de coleta não são processadas'}><LockIcon className={'icon-status-coleta5'}/></Tooltip> <Typography style = {{fontSize:"9px", marginTop:"0px"}}>DESATIVADA</Typography></div>        
        }

       if(!dias){
            return <div><Tooltip title ={'Sem comunicação'}><LinkOffIcon className={'icon-status-coleta5'}/></Tooltip> <Typography style = {{fontSize:"9px", marginTop:"0px"}}>Sem Conexão</Typography></div>        
        }



    switch(status){
        case 1:return <div><HelpIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"8px", marginTop:"0px"}}>DESCONHECIDO</Typography></div>
            
        case 2:return <div><PowerSettingsNewIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"8px", marginTop:"0px"}}>PRONTO</Typography></div>
            
        case 3:return <div><SettingsPowerIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"8px", marginTop:"0px"}}>ENERGY SAVING</Typography></div>
            
        case 4:return <div><PrintIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"8px", marginTop:"0px"}}>IMPRIMINDO</Typography></div>

        case 5:return <div><ReportProblemIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"8px", marginTop:"0px"}}>OCORRÊNCIA</Typography></div>

        case 6:return <div><Tooltip title={painel}><NotificationsActiveIcon className={'icon-status-coleta'+status}/></Tooltip><Typography style = {{fontSize:"8px", marginTop:"0px"}}>ATENÇÃO</Typography></div>

        case 7:return <div><Tooltip title={painel}><GestureIcon className={'icon-status-coleta'+status}/></Tooltip><Typography style = {{fontSize:"8px", marginTop:"0px"}}>ATOLAMENTO</Typography></div>

        case 8:return <div><Tooltip title={painel}><DoorOpen className={'icon-status-coleta'+status}/></Tooltip><Typography style = {{fontSize:"8px", marginTop:"0px"}}>Porta Aberta</Typography></div>


        default:return <div><HelpIcon className={'icon-status-coleta'}/><Typography style = {{fontSize:"8px", marginTop:"0px"}}>DESCONHECIDO</Typography></div>
    }
        
   }


 

   
    //https://theroadtoenterprise.com/blog/how-to-download-csv-and-json-files-in-react
   const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })
  
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }
  
  const exportToJson = (usersData) => {
   
    downloadFile({
      data: JSON.stringify(usersData),
      fileName: 'users.json',
      fileType: 'text/json',
    })
  }
  
  const exportToCsv = (userData) => {
   
  
    // Headers for each column
    let headers = ['Endereco IP ,Data Coleta, Hora Coleta, Razao Social, Numero de Serie, Marca, Modelo, A4 Mono, A3 Mono, Total Mono, A4 Color, A3 Color, Total Color, Total Geral, Scan 1, Scan 2']
  
    // Convert users data to a csv
    let usersCsv = registrosFiltrados.reduce((acc, user) => {
      const { enderecoip, datacoleta, horacoleta, razaosocial, numeroserie, marca, modelo, contadora4mono, contadora3mono, monototal, contadora4color, contadora3color, colortotal, contadortotal} = user
      acc.push([enderecoip, datacoleta, horacoleta,razaosocial, numeroserie, marca, modelo, contadora4mono, contadora3mono, monototal, contadora4color, contadora3color, colortotal, contadortotal].join(','))
      return acc
    }, [])
  
    downloadFile({
      data: [...headers, ...usersCsv].join('\n'),
      fileName: 'users.csv',
      fileType: 'text/csv',
    })
  }

    


    return <TemplateRevenda atualizar = {loading} full={true} breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">  
        <Typography>Você esta em</Typography>          
            <Link color="inherit" to={'#'} >
                GERAÇÃO DE RELATÓRIOS 
            </Link>                
        </Breadcrumbs>
    }>

        
       <div style={{marginTop : '0px', paddingBottom :'5px', height :'95px'}}>
       <Grid container spacing={1}>
        <Grid item xs = {7}>
        {totalEquipamentosNovos > 0 && <div style={{paddingTop:'5px'}}>
            
            <Alert severity='info'>
                <AlertTitle>Aviso de novas descobertas</AlertTitle>
                <Link  to ={{pathname : '/novosdispositivos/revenda/'+props.match.params.idempresa+'/empresa/'+props.match.params.chaveEmpresa+'/page/1'}}>
                    <Typography variant='caption' style ={{color: '#000'}}>Existe(m) {totalEquipamentosNovos} equipamento(s) novo(s) recém descoberto(s), <strong >click aqui para acessar.</strong></Typography>
                </Link>
            </Alert>           

        </div>}
        </Grid>
        
       </Grid>

        </div>
     
    
        <Grid container spacing ={1} style={{ paddingTop: '0px',marginTop:'0px'}}>
            <Grid item xs={12}>
                <ModalFiltroPapercutRelatorios 
                    chaveEmpresa ={props.match.params.chaveEmpresa}
                    codigoRevenda ={props.match.params.idempresa}
                    dataType='aoVivo'
                    Conexao ={Conexao}
                    registrosFiltrados={registrosFiltrados}
                    setRegistrosFiltrados={(data)=>setRegistrosFiltrados(data)}
                    setRegistros={(data)=>setRegistros(data)}
                    setLoading = {(acao)=>{
                        setLoading(acao)
                    }}
                    setShowSelection = {(op)=>setShowSelection(op)}
                    disabled={showSelection}
                    registrosDesativar ={
                        coletas.filter(item =>item.selecionado =='t' && item.ativo == 't').map((item)=>{
                            return item.idcoleta
                        })
                    }
                    updateGrid = {()=>setShowSelection(false)}
                   
                    disabledRelatorios = {(op)=>setDisabledRelatorios(op)}
                    />
            </Grid>                    
        </Grid>
        
        <br/>
        
       {
        //reportDisabled
       }
       
        
      
    </TemplateRevenda>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(GestaoColetasRelatorios)