
/*https://twitter.com/i/status/1782803461513359734*/
var axios = require('axios');

//var server = 'fleetconnect1.websiteseguro.com'
var serverDatacount = 'https://datacount.com.br/api/'
//var server = 'printerconnect.com.br'

var md5 = require('md5');



const serverPrinterconnect = 'https://printerconect.com.br/api/';
var url = window.location.host.toLowerCase();
url = 'datacount'

const axiosInstance = axios.create({
  baseURL: 'http://databitbh.com:8089/dataInventory/index.php/',
  timeout: 30000,
 
});
 


  axiosInstance.interceptors.request.use(async config => {
  const token = md5('printerconnect#2021');//getToken();

  if (token) {
    config.headers.Authorization = 'Bearer '+token;
  }

  return config;

});
export default axiosInstance;
