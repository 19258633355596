import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'

import {withRouter} from 'react-router-dom'
import Principal from '../Template/TemplateRevenda';
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import RemoveIcon from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PersonIcon from '@material-ui/icons/Person';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import Build from '@material-ui/icons/Build'
import ComputerOutlined from '@material-ui/icons/ComputerRounded';
import ConexaoInventory from  '../../providers/ConexaoInventory'
import path from 'path';

const CadastroComputadoresInventory = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [colunaFiltro, setColunaFiltro] = useState('Cliente')
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [filtro, setFiltro] = useState([])
    const [dataTecnico, setDataTecnico] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dcas, setDcas] = useState([])
    const [dcasFiltro, setDcasFiltro] = useState([])
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [ registros, setRegistros] = useState(0)
    const refTipoDataPesquisa = React.createRef();
    const refDataPesquisa = React.createRef();
    const refChaveInstalacao = React.createRef();
    const refRazaoSocial = React.createRef();
    const [inventorys, setInventorys]= useState([])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

  

    useEffect(()=>{
        getDcas();
    }, [page])


    const getDcas = () =>{
        
        setLoading(true)
        ConexaoInventory.get('InventoryController/getShortedAll?idusuario=1&idempresa='+props.location.state.empresa.idempresa+'&idcliente=1').then( response =>{
            if(response.data.status == 'OK'){
                setInventorys(response.data.payload)
            }else{
             
                setInventorys([])
            }
            setLoading(false)
        }).catch( error =>{
       
            setInventorys([])
            setLoading(false)
        });
    }

   

    const renderUltimoAcesso = (acesso) =>{
        if(acesso == 'printerconnect-app-collector'){
            return <PersonIcon color='primary' />
        }else{
            return <SettingsApplicationsIcon  color='primary'/>
        }
    }

    return <Principal atualizar = {loading} getData={getDcas} breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">  
        <Typography>Você esta em:</Typography>          
            <Link color="inherit" to={'#'} >
                Inventory Computadores
            </Link>                
        </Breadcrumbs>
    }>
            <div style={{paddingLeft : '10px', paddingTop : '5px', paddingBottom : '5px',marginTop : '10px', }}>
            <Grid container spacing = {2} style={{paddingBottom:'0px!important', paddingTop : '0px!important'}}>            
                <Grid item xs = {7}></Grid>
                <Grid item xs = {5}>
               
                </Grid>
            </Grid>
        </div>
        
      
        
        <Grid container spacing ={0} style={{ paddingTop: '5px', paddingBottom:'5px', marginTop:'20px'}}>
        <Grid item xs = {3} >
            <TextField
                InputProps={{
                    startAdornment : <InputAdornment position='start'><Typography variant='caption'>{colunaFiltro.toString().toUpperCase()}</Typography></InputAdornment>
                }}
                variant='outlined'
                size='small'
                fullWidth
                inputRef={refRazaoSocial}
                
                onKeyDown={(e)=>{
                    if(e.keyCode == 13){
                        const palavraChave = refRazaoSocial.current.value;
                        switch(colunaFiltro){
                            case 'Chave':
                                setDcasFiltro(dcas.filter(function(dca) {
                                    return dca.chavecoletor.toLowerCase().includes(palavraChave.toLowerCase());
                                  })
                                 )
        
                            break;
                            case 'Cliente':
                                setDcasFiltro(dcas.filter(function(dca) {
                                    return dca.razaosocial.toLowerCase().includes(palavraChave.toLowerCase());
                                  })
                                 )
        
                            break;
                            case 'Criação':
                                setDcasFiltro(dcas.filter(function(dca) {
                                    return dca.datacadastro.toLowerCase().includes(palavraChave.toLowerCase());
                                  })
                                 )
        
                            break;
           
                            case 'Instalação':
                                setDcasFiltro(dcas.filter(function(dca) {
                                    return dca.dataativacao.toLowerCase().includes(palavraChave.toLowerCase());
                                  })
                                 )
        
                            break;
        
                            case 'Acesso':
                                setDcasFiltro(dcas.filter(function(dca) {
                                    return dca.dataatualizacao.toLowerCase().includes(palavraChave.toLowerCase());
                                  })
                                 )
        
                            break;
                            case 'HostName':
                                dcasFiltro(dcas.filter(function(dca) {
                                    return dca.hostname.toLowerCase().includes(palavraChave.toLowerCase());
                                  })
                                 )
        
                            break;
                        }
                    }
                }}
            />
        </Grid>
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Tooltip title = 'Buscar rregistros'>
                <Button variant = 'outlined'  style={{height:'100%', backgroundColor:"#fff"}} onClick={()=>{
                
                refRazaoSocial.current.value = ''
                getDcas()
                 
            }}><SearchIcon/></Button></Tooltip>
        </Grid>
        </Grid>
       <div className='div-form-container'>
        <Table className='table table-striped table-hover table-sm table-small table-hovered'>
            <TableHead>
                <TableCell align='center' style={{width :'10%'}}>#</TableCell>
                <TableCell style={{width :'20%'}}>Equipamento</TableCell>
                <TableCell style={{width :'25%'}}>Cliente</TableCell>
                <TableCell style={{width :'20%'}}>Sistema Operacional</TableCell>
                <TableCell align='center' style={{width :'15%'}}>Dominio</TableCell>
                <TableCell align='center' style={{width :'20%'}}>Data Coleta</TableCell>
            </TableHead>
            <TableBody>

            {
                    inventorys ? inventorys.map(function(inventory, i){
                        return <TableRow>
                                <TableCell align='center'>
                                    <ComputerOutlined style={{width :'42px', height :'42px'}} />
                                    <div>
                                    <Typography variant='caption' style={{fontSize:'0.5vw'}}>{inventory.dominio.Hostname  ? inventory.dominio.Hostname  : '* não disponível'}</Typography>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <Link to={{pathname : '/DataInventoryDetails', state : {
                                            empresa : props.location.state.empresa,
                                            usuario : props.location.state.usuario,  
                                            idcliente : inventory.idcliente, 
                                            inventory :inventory 
                                        }}}>
                                            <div>
                                                {inventory.computador.Modelo ? inventory.computador.Modelo : '* não disponível'}
                                            </div>
                                            <div>
                                                {inventory.computador.Marca  ? inventory.computador.Marca  : '* não disponível'}
                                            </div>
                                    </Link>
                                </TableCell>
                                <TableCell>{inventory.razaosocial ? inventory.razaosocial : '* não disponível'}</TableCell>
                                <TableCell>
                                    <div>
                                    {inventory.SistemaOperacional.sistemaOperacional ? inventory.SistemaOperacional.sistemaOperacional : '* não disponível'}
                                    </div>
                                    <div>
                                    {inventory.SistemaOperacional.build ? inventory.SistemaOperacional.build : '* não disponível'}
                                    </div>
                                </TableCell>
                                <TableCell align='center'>{inventory.dominio.Dominio  ? inventory.dominio.Dominio  : '* não disponível'}</TableCell>
                                <TableCell align='center'>{inventory.computador.dataColeta ? inventory.computador.dataColeta : '* não disponível'}</TableCell>
                    </TableRow>
                    }) : null
                }
            </TableBody>
        </Table>
        
        </div>                  
    
        <div className='fabIncluir'>
        {/*<ModalNovoDca idusuario={props.match.params.idempresa} idempresa={props.match.params.chaveEmpresa} getData = {()=>getDcas()}/>*/}
        </div>
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(CadastroComputadoresInventory)