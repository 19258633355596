import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow, Badge, Checkbox} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Template/TemplateRevenda';
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';


import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Pagination from '@material-ui/lab/Pagination';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Tooltip from '@material-ui/core/Tooltip';

import ClienteAtivo from '@material-ui/icons/Domain';
import ClienteInativo from '@material-ui/icons/DomainDisabled';
import SearchIcon from '@material-ui/icons/Search';

import TablePagination from '@material-ui/core/TablePagination';


import IconeNovoLocal from '@material-ui/icons/AddLocation'
import Iconfilter from '@material-ui/icons/FilterList'
import DesativarIcon from '@material-ui/icons/Block'
import AtivarIcon from '@material-ui/icons/Done'
import BuildIcon from '@material-ui/icons/Build'
import RemoveIcon from '@material-ui/icons/DeleteForever'
import DetalhesIcon from '@material-ui/icons/Assignment'
import ModalNovoUsuario from './ModalNovoUsuario';
import ModalEdicaoUsuario from './ModalEdicaoUsuario';
import DeleteForever from '@material-ui/icons/DeleteForever';


const CadastroUsuariosRevenda = (props)=>{
    const {idrevenda, idempresa} = props.location.state.empresa
    const controller ='clientesController';
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [usuarios, setUsuarios] = useState([])
    const [empresa, setEmpresa] = useState([])
    const [edicao, setEdicao] = useState([])
    const [selecionado, setSelecionado] = useState([])
    const [parametroFiltro, setParametroFiltro] = useState('ativos')
    const [filtro, setFiltro] = useState([])

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [paginas, setPaginas]= React.useState(1);
    const [anchorEl, setAnchorEl] = React.useState(null);





    const refStatusCadastro = useRef();

    const refCpnj = useRef();
    const refRazaoSocial = useRef();
    const refPlaca = useRef();
    const refCidade = useRef();
    const refMarca = useRef();
    const refModelo = useRef();
    const refSetor = useRef();
    const refErp = useRef();
    const refStatus = useRef();


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    useEffect(()=>{
        getUsuariosRevenda()
    }, [])


    useEffect(()=>{
       // getClientes();
    }, [rowsPerPage])


    useEffect(()=>{
       console.log(props.location.state)
    }, [])

    useEffect(()=>{
        if(!openModal){
            setEdicao([])
        }

    }, [openModal])


    const getUsuariosRevenda = () =>{
        setLoading(true)
        Conexao.post('usuariosController/getUsuariosRevenda', {
            idrevenda : idrevenda,
            idempresa : idempresa,
        }).then(response=>{
            if(response.status == 200 && response.data.status == 'OK'){
                setUsuarios(response.data.payload);
            }
            setLoading(false)
        }).catch(error=>{
            setLoading(false)
        })
    }

    const deleteUsuarioRevenda = (idusuario) =>{
        setLoading(true)
        Conexao.post('usuariosController/deleteUsuarioRevenda', {
            idrevenda : idrevenda,
            idempresa : idempresa,
            idusuario : idusuario
        }).then(response=>{
            if(response.status == 200 && response.data.status == 'OK'){
                
                setUsuarios(usuarios.filter(item => item.idusuario != idusuario))
                Swal.fire({
                    title : 'Datacount', 
                    text : 'Usuário removido com sucesso!', 
                    icon : 'success', 
                    showCancelButton : false, 
                    showConfirmButton : false, 
                    timer : 1500
                })
            }else{
                Swal.fire({
                    title : 'Datacount', 
                    text : 'Não foi possível excluir!', 
                    icon : 'warning', 
                    showCancelButton : false, 
                    showConfirmButton : false, 
                    timer : 1500
                })
            }
            setLoading(false)
        }).catch(error=>{
            Swal.fire({
                title : 'Datacount', 
                text : 'Não foi possível excluir!', 
                icon : 'error', 
                showCancelButton : false, 
                showConfirmButton : false, 
                timer : 1500
            })
            setLoading(false)
        }) 
        
    }

    const statusUsuarioRevenda =(idusuario, status, index) =>{
        setLoading(true)
        Conexao.post('usuariosController/statusUsuarioRevenda', {
            idrevenda : idrevenda,
            idempresa : idempresa,
            idusuario : idusuario, 
            status : status =='t' ? 'f' : 't'
        }).then(response=>{
            if(response.status == 200 && response.data.status == 'OK'){
                
                const temp = usuarios;
                setUsuarios([])
                temp[index].ativo = status =='t' ? 'f' : 't'
                setUsuarios(temp)
                Swal.fire({
                    title : 'Datacount', 
                    text : 'Usuário atualizado com sucesso!', 
                    icon : 'success', 
                    showCancelButton : false, 
                    showConfirmButton : false, 
                    timer : 1500
                })
            }else{
                Swal.fire({
                    title : 'Datacount', 
                    text : 'Não foi possível atualizar!', 
                    icon : 'warning', 
                    showCancelButton : false, 
                    showConfirmButton : false, 
                    timer : 1500
                })
            }
            setLoading(false)
        }).catch(error=>{
            Swal.fire({
                title : 'Datacount', 
                text : 'Não foi possível atualizar!', 
                icon : 'error', 
                showCancelButton : false, 
                showConfirmButton : false, 
                timer : 1500
            })
            setLoading(false)
        }) 
    }
    


    return <Principal atualizar = {loading} breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">  
        <Typography>Você esta em:</Typography>          
            <Link color="inherit" to={'#'} >
                Usuários Revenda
            </Link>                
        </Breadcrumbs>
    }>
   
        <br/>
        <br/>
        <Typography variant='h4'>Cadastro de Usuários</Typography>
        <br/>
        <br/>
        <ModalNovoUsuario 
            Conexao={Conexao} 
            setNovoUsuario = {(usuario)=>{
                console.log(usuario)
                const temp = usuarios
                setUsuarios([]);
                temp.push(usuario)
                setUsuarios(temp)
                
            }}
            empresa={props.location.state.empresa}/>
            <br/>
            <br/>
        <Table className='table table-striped table-hover table-small table-sm'>
            <TableHead>
                <TableCell align='left' style={{width: '40%'}}>Email</TableCell>
                <TableCell align='left' style={{width: '30%'}}>Nome</TableCell>
        
                <TableCell align='center' style={{width: '8%'}}>Editar</TableCell>
                <TableCell align='center' style={{width: '8%'}}>Status</TableCell>
                <TableCell align='center'  style={{width: '5%'}}>Remover</TableCell>
            </TableHead>

            {
                usuarios && usuarios.map(function(usuario, index){
                    return <TableRow>
                    <TableCell align='left' >{usuario.email}</TableCell>
                    <TableCell align='left' >{usuario.nomecompleto}</TableCell>
          
                    <TableCell align='center' ><ModalEdicaoUsuario disabled={usuario.ativo == 'f'} Conexao ={Conexao} Usuario={usuario} empresa={props.location.state.empresa} index={index} updateUsuarioLista = {(user)=>{
                        const temp = usuarios;
                        setUsuarios([])
                        temp[index] = user;
                        setUsuarios(temp)
                    }}/></TableCell>
                    <TableCell align='center' >
                        <Button fullWidth variant='outlined' color={usuario.ativo =='t' ? 'primary' :'secondary'} onClick={()=>{
                             Swal.fire({
                                title : 'Datacount', 
                                text : `Deseja ${usuario.ativo =='t' ? 'DESATIVAR' :'ATIVAR'} o usuário agora?`, 
                                icon:'question', 
                                showConfirmButton : true, 
                                confirmButtonText : 'SIM', 
                                showCancelButton : true, 
                                cancelButtonText : 'Não'
                            }).then(resposta=>{
                                if(resposta.isConfirmed){
                                    statusUsuarioRevenda(usuario.idusuario, usuario.ativo, index);
                                }
                            })
                        }}>{usuario.ativo =='t' ? 'ATIVO' :'INATIVO'}</Button>
                    </TableCell>
                    <TableCell align='center'  style={{width: '5%'}}>
                        <Button fullWidth variant='contained' color='secondary' onClick={()=>{
                             Swal.fire({
                                title : 'Datacount', 
                                text : 'Deseja realmente excluir o registro?', 
                                icon:'question', 
                                showConfirmButton : true, 
                                confirmButtonText : 'SIM', 
                                showCancelButton : true, 
                                cancelButtonText : 'Não'
                            }).then(resposta=>{
                                if(resposta.isConfirmed){
                                    deleteUsuarioRevenda(usuario.idusuario);
                                }
                            })
                        }}><DeleteForever/></Button>
                    </TableCell>
                </TableRow>
                })
            }
        </Table>


    <div className='fabIncluir'>
        
    </div>

    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(CadastroUsuariosRevenda)