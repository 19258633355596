import React, {useState, useEffect, useRef, Fragment} from 'react'
import Drawer from '@material-ui/core/Drawer'
import { Button, Divider, Grid, Typography, Tooltip, TextField} from '@material-ui/core'
import ModalMonitorIndividual from '../pages/ContadoresAoVivo/ModalMonitoramentoIndividual'
import Swal from 'sweetalert2'
import LockIcon from '@material-ui/icons/Lock'
import Close from '@material-ui/icons/Close'
import DetailIcon from '@material-ui/icons/Apps'



export default function SideBarFiltroColetas(props){

    const [open, setOpen] = useState(false)

    const toggleDrawer = () =>{
        setOpen(!open)
    }

    return <div>
    <React.Fragment >
       
        <Drawer
             
          
          anchor={'right'}
          open={props.openSideBar}
          hideBackdrop
          onClose={()=>props.setOpenSidebar()}
        >
            <div style ={{width: "100%", height : '68px', backgroundColor:"#37474F"}}></div>
            <div style ={{width: "100%",  backgroundColor:"#CFD8DC", textAlign:'center', display: 'flex', padding : '0px'}}>
                <div style={{width : '80%', textAlign:'left', alignContent : 'flex-start'}}>
                   
                </div>
                <div style={{width : '20%'}}>
                    <Button fullWidth onClick={()=>props.setOpenSidebar()}><Close/></Button>
                </div>
            </div>
          <div id='div-side-menu' style ={{
            width : '500px', 
            padding : '30px',
            paddingTop : '20px' 
            
          }}>
                <Grid container spacing = {2}>
                    <Grid item xs = {12}>
                        <TextField
                            label='Marca'
                            value={props.marca}
                            onChange={(e)=>props.setMarca(e.target.value)}
                            fullWidth
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            size ='large'
                        />
                    </Grid>
                </Grid>
                <br/>
                <Grid container spacing = {4}>
                    <Grid item xs = {12}>
                        <TextField
                            label='Modelo'
                            value={props.modelo}
                            onChange={(e)=>props.setModelo(e.target.value)}
                            fullWidth
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            size ='large'
                        />
                    </Grid>
                </Grid>
                <br/>
                <Grid container spacing = {4}>
                    <Grid item xs = {12}>
                        <TextField
                            label='Número Série'
                            value={props.numeroSerie}
                            onChange={(e)=>props.setNumeroSerie(e.target.value)}
                            fullWidth
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            size ='large'
                        />
                    </Grid>
                </Grid>
                <br/>
                <Grid container spacing = {4}>
                    <Grid item xs = {12}>
                        <TextField
                            label='Cliente'
                            value={props.razaoSocial}
                            onChange={(e)=>props.setRazaoSocial(e.target.value)}
                            fullWidth
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            size ='large'
                        />
                    </Grid>
                </Grid>
                <br/>
                <Grid container spacing = {4}>
                    <Grid item xs = {12}>
                        <TextField
                            label='Endereço IP'
                            value={props.enderecoIP}
                            onChange={(e)=>props.setEnderecoIP(e.target.value)}
                            fullWidth
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            size ='large'
                        />
                    </Grid>
                </Grid>
                <br/>
                <Grid container spacing = {4}>
                    <Grid item xs = {12}>
                        <TextField
                            label='Data 1º Coleta'
                            fullWidth
                            value={props.dataInicial}
                            type='date'
                            onChange={(e)=>props.setDataInicial(e.target.value)}
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            size ='large'
                        />
                    </Grid>
                </Grid>

                <br/>
                <Grid container spacing = {4}>
                    <Grid item xs = {12}>
                        <TextField
                            label='Última Coleta'
                            fullWidth
                            value={props.dataFinal}
                            type='date'
                            onChange={(e)=>props.setDataFinal(e.target.value)}
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            size ='large'
                        />
                    </Grid>
                </Grid>
                
                <br/>
            
    
               
       
                <Grid container spacing = {1}>
                    <Grid item xs = {8}>
                        <Button fullWidth variant='contained' color='primary' size='large' style={{height : '60px'}} onClick={()=>props.applyFilter()}>Aplicar</Button>
                    </Grid>
                    <Grid item xs = {4}>
                        <Button fullWidth variant='contained' color='secondary' size='large' style={{height : '60px'}} onClick={()=>props.resetFilter()}>Limpar</Button>
                    </Grid>                   
                </Grid>
                <Grid container spacing = {1} style={{marginTop : '5px'}}>                    
                    <Grid item xs = {12}>
                        <Button fullWidth variant='contained' color='default' size='large' style={{height : '60px'}} onClick={()=>props.setOpenSidebar()}>Fechar</Button>
                    </Grid>
                </Grid>
           
          </div>
        </Drawer>
      </React.Fragment>
  </div>
}
