
export class RelatorioParqueCompleto {

    constructor(dadosParaImpressao) {
  
      this.dadosParaImpressao = dadosParaImpressao;
      
    }  
  
    async PreparaDocumento() {
      return new Promise((resolve, reject) => {
        try {
          const corpoDocumento = this.CriaCorpoDocumento();
          const documento = this.GerarDocumento(corpoDocumento, this.dadosParaImpressao.title);
          resolve(documento);
        } catch (error) {
          console.log(error)
          reject(error);
        }
      });
    }
  
    CriaCorpoDocumento() {
  
      const header = [
        { text: 'Data Coleta', bold: true, fontSize: 6, alignment : 'center', color: '#fff', style :'lineBlack'},
        { text: 'Cliente', bold: true, fontSize: 6, alignment : 'left', color: '#fff', style :'lineBlack'},
        { text: 'Modelo', bold: true, fontSize: 7, alignment : 'left', color: '#fff', style :'lineBlack' },
        { text: 'Serial', bold: true, fontSize: 7, alignment : 'center', color: '#fff', style :'lineBlack' },       
        { text: 'A4 Mono', bold: true, fontSize: 6, alignment : 'center', color: '#fff', style :'lineBlack'},
        { text: 'A3 Mono', bold: true, fontSize: 6, alignment : 'center', color: '#fff', style :'lineBlack'},
        { text: 'T Mono', bold: true, fontSize: 6, alignment : 'center', color: '#fff', style :'lineBlack'},
        { text: 'A4 Color', bold: true, fontSize: 6, alignment : 'center', color: '#fff', style :'lineBlack'},
        { text: 'A3 Color', bold: true, fontSize: 6, alignment : 'center', color: '#fff', style :'lineBlack'},
        { text: 'T Color', bold: true, fontSize: 6, alignment : 'center', color: '#fff', style :'lineBlack'},
        { text: 'T Geral', bold: true, fontSize: 6, alignment : 'center', color: '#fff', style :'lineBlack'},
        { text: 'Scan 1', bold: true, fontSize: 6, alignment : 'center', color: '#fff', style :'lineBlack'},
        { text: 'Scan 2', bold: true, fontSize: 6, alignment : 'center', color: '#fff', style :'lineBlack'},
        { text: 'T Scan', bold: true, fontSize: 6, alignment : 'center', color: '#fff', style :'lineBlack'},
      ];
      const body = this.dadosParaImpressao.registros.map((coleta, index) => {
         let corLinha = this.escolherCorLinha(coleta,index)
       
        return [
          { text: coleta.horacoleta ? coleta.datacoleta+' '+coleta.horacoleta.substr(0,8) : coleta.datacoleta+' 00:00:00', fontSize: 6 , alignment : 'center', style:corLinha},
          { text: coleta.razaosocial.length > 55 ? coleta.razaosocial.substr(0,55)+'...':coleta.razaosocial , fontSize: 6, alignment : 'left', style:corLinha },
          { text: coleta.modelo.length > 40 ? coleta.modelo.substr(0,40)+'...':coleta.modelo , fontSize: 6, alignment : 'left', style:corLinha },
          { text: coleta.numeroserie, fontSize: 6 , alignment : 'center', style:corLinha},         
          { text: coleta.contadormonoa4, fontSize: 6, alignment : 'center' , style:corLinha},
          { text: coleta.contadormonoa3, fontSize: 6, alignment : 'center' , style:corLinha},
          { text: coleta.monototal, fontSize: 6, alignment : 'center' , style:corLinha},
          { text: coleta.contadorcolora4, fontSize: 6, alignment : 'center' , style:corLinha},
          { text: coleta.contadorcolora3, fontSize: 6, alignment : 'center' , style:corLinha},
          { text: coleta.colortotal, fontSize: 6, alignment : 'center' , style:corLinha},
          { text: coleta.contadortotal, fontSize: 6, alignment : 'center' , style:corLinha},
          { text: coleta.contadormonoscanner, fontSize: 6, alignment : 'center' , style:corLinha},
          { text: coleta.contadorcolorscanner, fontSize: 6, alignment : 'center' , style:corLinha},
          { text: coleta.contadortotalscanner, fontSize: 6, alignment : 'center' , style:corLinha},
        ];
      });
     
  
      
  
      let content = [header];
    
      
      content = [...content, ...body];
      return content;
    }
  
    escolherCorLinha(coleta, index) {
      let estilo ={
        fillColor: index %2 == 0 ? '#d9d9d9' : '#fff', // Odd row color
        color : '#f00', 
        italics : false, 
        bold : false
      }
      if (coleta.ativo === 't') {
        
          if(coleta.dias){
            estilo.color = '#000'
          }else{
            estilo.color = '#f00'
          }
         
          
      } else {
          estilo.color = '#999'
          estilo.italics =  true
          estilo.bold = true
      }
      return estilo;
   }

   escolherStatus(coleta) {

    if (coleta.ativo === 't') {   

        if(coleta.dias){

          return 'ON-LINE'

        }else{

          return 'OFF-LINE'

        }
               
    } else {

      return 'DESABILITADA'

    }
   
 }


    GerarDocumento(corpoDocumento, title) {
    console.log(corpoDocumento);
      const documento = { 
        pageSize: 'A4',
        pageMargins: [7, 53, 15, 48],
        header: function () {
          return {
              margin: [7, 12, 14, 0],
              layout: 'noBorders',
              table: {
                widths: ['*'],
                body: [    
                    
                  [
                    { text: 'Relatório de Visão de Parque', style: 'reportName' }
                  ] ,                         
                  [
                    { text: 'Cliente : ' + title, style: 'razaoSocial' }
                  ],
                  [
                    { text: 'Data Relatório : ', style: 'razaoSocial' }
                  ],
                  [
                    {
                      text:
                      '________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                      alignment: 'center',
                      fontSize: 5,
                    },
                  ]   
                               
                ],
              },
            };
        },
        pageOrientation: 'landscape',
      content: [
        {
              layout: 'noBorders',
              table: {              
                headerRows: 1,
                widths: [65, 155, 125, 75, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30],
                body: corpoDocumento
              }
            },
      ],
    
      footer(currentPage, pageCount) {
            return {
              layout: 'noBorders',
              margin: [14, 0, 14, 22],
              table: {
                widths: ['auto'],
                body: [
                 
                  [
                    {
                      text:
                      '________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                      alignment: 'center',
                      fontSize: 5,
                    },
                  ],
                  [
                    [
                      {
                        text: `Página ${currentPage.toString()} de ${pageCount}`,
                        fontSize: 7,
                        alignment: 'right',
                        /* horizontal, vertical */
                        margin: [3, 0],
                      },
                      {
                        text: '© Datacount collector',
                        fontSize: 7,
                        alignment: 'center',
                      },
                    ],
                  ],
                ],
              },
            };
          },
      styles: {
        razaoSocial: {
          fontSize: 9,
          bold: true,
          alignment: 'left',
          margin: [0, 4, 0, 0],
        
        },
        reportName: {
            fontSize: 9,
            bold: true,
            alignment: 'center',
            margin: [0, 4, 0, 0],
          
          }, 
        lineWhite: {
          fillColor: '#fff', // Header row color
    
        },
        lineSilver1: {
          fillColor: '#d9d9d9', // Odd row color
          color : '#f00'
        },
        lineSilver: {
          fillColor: '#d9d9d9', // Odd row color
          color : '#000'
        },
        linhaAtiva: {
          fillColor: '#d9d9d9', // Odd row color
          color : '#000'
        },
        linhaInativa: {
          fillColor: '#d9d9d9', // Odd row color
          color : '#000', 
          decoration : 'lineThrough', 
          italics : true
        },
        linhaInativa1: {
          color : '#000', 
          decoration : 'lineThrough', 
          italics : true
        },
        lineBlack1 : {
            fillColor: '#444', // Odd row color
            color : '#f00'
        },
        lineBlack : {
          fillColor: '#444', // Odd row color
        },
        corLinhaheader : {
            color: '#fff', 
            
        }, 
        fontBlack : {
            color : '#000'
        }, 
        fontRed : {
            color : '#f00', 
        }
      },
  
    };
      return documento;
    }
  }
  