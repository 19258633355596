import React, {useState, useEffect} from 'react'
import { ArrowUpOutline, ArrowDownOutline, MenuOutline , FilterOutline} from 'react-ionicons'
import Block from '@material-ui/icons/LineStyle';
export default function ContainerOrderBy(props){

    const {campo, direcao, handleOrderChange, title, help, ativo, font} = props;


    return  campo == ativo ? <div style={{display : 'flex', cursor:'pointer', fontWeight :'bold' }} onClick={()=>{handleOrderChange(campo, direcao === 'desc' ? 'asc' : 'desc')}}>
               
                <div style={{ textAlign:'left'}}>{
                    direcao =='desc' ?  
                    <ArrowUpOutline
                        color={ativo == campo ? '#00f' : '#ccc'} 
                        title={help}
                        height="16px"
                        width="16px"
                        style={{cursor:'pointer'}}
                        /> 
                    :
                    <ArrowDownOutline
                        color={ativo == campo ? '#00f' : '#ccc'} 
                        title={help}
                        height="16px"
                        width="16px"
                        style={{cursor:'pointer'}}
                       />
                }</div>
                <div style={{paddingLeft :'3px'}}><span style={{fontSize : font+'px'}}>{title.toUpperCase()}</span></div> 
            </div> 
            : campo != null ? 
            <div style={{display : 'flex', width : '100%',cursor:'pointer'}} onClick={()=>{handleOrderChange(campo, direcao === 'desc' ? 'asc' : 'desc')}}>
               
                <div style={{ textAlign:'left'}}>{
                    direcao =='asc' ?  
                    <MenuOutline
                        color={ativo == campo ? '#00000' : '#333'} 
                        title={help}
                        height="16px"
                        width="16px"
                        style={{cursor:'pointer'}}
                        onClick={()=>{handleOrderChange(campo, 'desc')}}/> 
                    :
                    <MenuOutline
                        color={ativo == campo ? '#00000' : '#333'} 
                        title={help}
                        height="16px"
                        width="16px"
                        style={{cursor:'pointer'}}
                        onClick={()=>{handleOrderChange(campo, 'asc')}}/>
                }</div>
                 <div style={{paddingLeft :'3px'}}><span style={{fontSize : font+'px'}}>{title.toUpperCase()}</span></div>
            </div> :
            <div style={{display : 'flex', width : '100%'}}>
               
            <div style={{width : '10%', textAlign:'left'}}></div>
             <div style={{width : '90%'}}><span style={{fontSize : font+'px'}}>{title.toUpperCase()}</span></div>
        </div>
}