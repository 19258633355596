import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow, Badge, Checkbox} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Template/TemplateRevenda';
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import FormNovoCliente from './FormNovoCliente'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Pagination from '@material-ui/lab/Pagination';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonAtivoInativo from '../../components/Comuns/ButtonAtivoInativo'
import ClienteAtivo from '@material-ui/icons/Domain';
import ClienteInativo from '@material-ui/icons/DomainDisabled';
import SearchIcon from '@material-ui/icons/Cached';

import TablePagination from '@material-ui/core/TablePagination';

import DisplayStatus from '../../components/DisplayStatus'
import ModalGestaoAcessoCliente from './Componentes/ModalGestaoAcessoCliente'
import TextFieldEmail from '../../components/TextFieldEmail'
import IconeNovoLocal from '@material-ui/icons/AddLocation'
import Iconfilter from '@material-ui/icons/FilterList'
import DesativarIcon from '@material-ui/icons/Block'
import AtivarIcon from '@material-ui/icons/Done'
import BuildIcon from '@material-ui/icons/Build'
import RemoveIcon from '@material-ui/icons/DeleteForever'
import DetalhesIcon from '@material-ui/icons/Assignment'
import ModalDetalhesCliente from './ModalDetalhesCliente';
import VerifiedUser from '@material-ui/icons/PowerSettingsNew';
import ContainerOrderBy from '../../components/ContainerOrderBy';


const CadastroClientes = (props)=>{
    const controller ='clientesController';
    const classes = useStyles();
    const {usuario} = props.location.state
    const [clientes, setClientes] = useState([])
    const [empresa, setEmpresa] = useState([])
    const [edicao, setEdicao] = useState([])
    const [selecionado, setSelecionado] = useState([])
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [mostrarInativos, setMostrarInativos] = useState(false); // Variável para mostrar ou não inativos
    const [orderBy, setOrderBy] = useState('razaosocial'); // Estado para a coluna de ordenação
    const [orderDirection, setOrderDirection] = useState('asc'); 


    useEffect(() => {
        fetchData();
        
    }, []);

    useEffect(() => {
        filterAndPaginateData();
    }, [data, currentPage, rowsPerPage, searchQuery, mostrarInativos, orderBy, orderDirection]); // Adicionando mostrarInativos como dependência

    const fetchData = async () => {
        setSelecionado([])
        setEdicao([])
        setLoading(true);
        try {
            const response = await Conexao.post('clientesController/getAllClientes', {
                idusuario: props.location.state.empresa.idrevenda,
                idempresa: props.location.state.empresa.idempresa,
            });

            if (response.status === 200 && response.data.status === 'OK') {
                setData(response.data.payload);
                setTotalRecords(response.data.payload.length);
            } else {
                setData([]);
                setTotalRecords(0);
            }
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const filterAndPaginateData = () => {
        
        let filtered = data;

        // Inclui registros inativos se mostrarInativos for verdadeiro
        if (!mostrarInativos) {
            filtered = filtered.filter(item => item.ativo === 't');
        }

        // Aplica filtro pela razão social
        filtered = filtered.filter(item =>
            item.razaosocial.toLowerCase().includes(searchQuery.toLowerCase())
        );


        filtered.sort((a, b) => {
            const valueA = a[orderBy] || '';  // Fallback para ''
            const valueB = b[orderBy] || '';  // Fallback para ''
        
            // Checa se algum dos valores é vazio
            if (!valueA && valueB) {
                return orderDirection === 'asc' ? 1 : -1;
            }
            if (valueA && !valueB) {
                return orderDirection === 'asc' ? -1 : 1;
            }
        
            // Se ambos os valores são vazios ou ambos não são, compara normalmente
            if (orderDirection === 'asc') {
                return valueA.localeCompare(valueB);
            } else {
                return valueB.localeCompare(valueA);
            }
        });

        
        // Define totalRecords com base na quantidade de registros filtrados
        setTotalRecords(filtered.length);

        let startIndex = currentPage * rowsPerPage;
        if (startIndex < 0) {
            startIndex = 0;
        }
        const endIndex = Math.min(startIndex + rowsPerPage, filtered.length);

        setFilteredData(filtered.slice(startIndex, endIndex));
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(0);
    };

    const handleMostrarInativosChange = (event) => {
        console.log(event.target.value)
        if(event.target.value === 'Sim'){
            setMostrarInativos(true);
        }else{
            setMostrarInativos(false);
        }
       
        setCurrentPage(0); // Redefine a página atual ao alterar mostrarInativos
    };
    
    
    


    const handleOrderChange = (name, value) => {
             console.log("order by "+name+' direcao '+value)
            setOrderBy(name);
            setOrderDirection(value);
            setCurrentPage(0);
    
        };




    return <Principal atualizar = {loading} breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">  
        <Typography>Você esta em:</Typography>          
            <Link color="inherit" to={'#'} >
                Cadastro de Clientes
            </Link>                
        </Breadcrumbs>
    }>
        <div style={{paddingLeft : '10px', paddingTop : '5px', paddingBottom : '5px',marginTop : '10px',}}>
            <Grid container spacing = {2} style={{paddingBottom:'0px!important', paddingTop : '0px!important'}}>
                <Grid item xs ={11} style={{   textAlign:'right'}}>               
                    <Typography  variant='subtitle1' style ={{marginTop : '5px'}}>Linhas por página</Typography> 
                </Grid>
                <Grid item xs ={1}>
                    <TextField
                        select
                        SelectProps={{native : true}} 
                        variant='outlined'
                        size='small'
                        fullWidth 
                        value={rowsPerPage}    
                        onChange={(e)=>{
                            handleChangeRowsPerPage(e)
                            
                        }}               
                        >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                    </TextField>
                </Grid>
            </Grid>
        </div>


   
    <Grid container spacing ={1} style={{ paddingTop: '5px', paddingBottom:'5px', marginTop:'20px'}}>
        <Grid item xs ={1}>
        <TextField
                select
                SelectProps={{native:true}}
                label ='Exibir Inativos'
                size='small'
                variant='outlined'
                fullWidth
                InputLabelProps={{shrink:true}}
                onChange={handleMostrarInativosChange}>
                <option value='Não'>Não</option>
                <option value='Sim'>Sim</option>
             </TextField>
        </Grid>
        <Grid item xs = {4} >
            <TextField
                InputProps={{
                    startAdornment : <InputAdornment position='start'><span>Nome</span></InputAdornment>
                }}
                variant='outlined'
                size='small'
                fullWidth
                value={searchQuery}
                onFocus={()=>{setCurrentPage(0)}}
                onChange={(e)=>{
                    setSearchQuery(e.target.value)
                }}
            />
        </Grid>
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Button variant = 'outlined'  style={{height:'100%', backgroundColor:"#fff"}}  onClick={fetchData}><SearchIcon className={loading ? 'loading-spinner icone-success' : 'icone-success'}/></Button>
        </Grid>
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Button 
                variant = 'outlined' 
                disabled={selecionado && selecionado.idcliente || (usuario.clientesinclusao =='f')} 
                fullWidth style={{height:'100%', backgroundColor:"#fff", fontSize:'10px'}} 
                onClick={()=>setOpenModal(true)}>
                    <AddIcon /> Novo
            </Button>
        </Grid>
        {/*<Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <ModalDetalhesCliente 
                disabled={selecionado && !selecionado.idcliente || selecionado.ativo == 'f'}
                cliente ={selecionado}
                
                />
            </Grid>*/}
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Button variant = 'outlined' fullWidth style={{height:'100%', backgroundColor:"#fff", fontSize:'10px'}} disabled={selecionado && !selecionado.idcliente || selecionado.ativo == 'f' || (usuario.clientesedicao =='f')} onClick={()=>{
                setEdicao(selecionado)
                setOpenModal(true)
                
            }}><BuildIcon /> Editar</Button>
        </Grid>
        
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Button 
                variant = 'outlined' 
                fullWidth 
                style={{height:'100%', 
                backgroundColor:"#fff", fontSize:'10px'}} 
                disabled={selecionado && !selecionado.idcliente || selecionado.ativo == 'f' || (usuario.clientesedicao =='f')} onClick={()=>{
                    Swal.fire({
                        title : 'Datacount', 
                        text : 'Deseja DESATIVAR este cliente?', 
                        icon : 'question', 
                        showConfirmButton : true, 
                        confirmButtonText : 'Sim', 
                        showCancelButton : true, 
                        cancelButtonText : 'Não'
                    }).then(resposta=>{
                        if(resposta.isConfirmed){
                            Conexao.get('clientesController/disableRegistro?idempresa='+selecionado.idempresa+'&idcliente='+selecionado.idcliente).then(response=>{
                                if(response.data.status =='OK'){
                                    Swal.fire({
                                        title : 'Datacount', 
                                        text:'Operação realizada com sucesso !', 
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer  : 1500, 
                                        icon : 'success'
                                    })
                                    //getClientes()
                                }else{
                                    Swal.fire({
                                        title : 'Datacount', 
                                        text:'Operação não realizada', 
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer  : 1500, 
                                        icon : 'warning'
                                    })
                                }
                            }).catch(error=>{
                                Swal.fire({
                                    title : 'Datacount', 
                                    text:'Ocorreu um erro inesperado !', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer  : 1500, 
                                    icon : 'error'
                                })
                            })
                        }
                    })
                    
                   
                }}>
                    <DesativarIcon /> Desativar
            </Button>
        </Grid>
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Button 
                variant = 'outlined' 
                fullWidth style={{height:'100%', backgroundColor:"#fff", fontSize:'10px'}} 
                disabled={selecionado && !selecionado.idcliente || selecionado.ativo == 't' || (usuario.clientesedicao =='f')} onClick={()=>{
                    Swal.fire({
                        title : 'Datacount', 
                        text : 'Deseja REATIVAR este cliente?', 
                        icon : 'question', 
                        showConfirmButton : true, 
                        confirmButtonText : 'Sim', 
                        showCancelButton : true, 
                        cancelButtonText : 'Não'
                    }).then(resposta=>{
                        if(resposta.isConfirmed){
                            Conexao.get('clientesController/enableRegistro?idempresa='+selecionado.idempresa+'&idcliente='+selecionado.idcliente).then(response=>{
                                if(response.data.status =='OK'){
                                    Swal.fire({
                                        title : 'Datacount', 
                                        text:'Operação realizada com sucesso !', 
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer  : 1500, 
                                        icon : 'success'
                                    })
                                  //  getClientes()
                                }else{
                                    Swal.fire({
                                        title : 'Datacount', 
                                        text:'Operação não realizada', 
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer  : 1500, 
                                        icon : 'warning'
                                    })
                                }
                            }).catch(error=>{
                                Swal.fire({
                                    title : 'Datacount', 
                                    text:'Ocorreu um erro inesperado !', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer  : 1500, 
                                    icon : 'error'
                                })
                            })
                        }
                    })
                    
                   
                }}>
                    <AtivarIcon /> Reativar
            </Button>
        </Grid>
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Button variant = 'outlined' fullWidth style={{height:'100%', backgroundColor:"#fff", fontSize:'10px'}} disabled={selecionado && !selecionado.idcliente || (usuario.clientesexclusao =='f')} onClick={()=>{
                 Swal.fire({
                    title : 'Datacount', 
                    text : 'Deseja EXCLUIR este cliente? esta ação não poderá ser desfeita!', 
                    icon : 'info', 
                    showConfirmButton : true, 
                    confirmButtonText : 'Sim', 
                    showCancelButton : true, 
                    cancelButtonText : 'Não'
                }).then(resposta=>{
                    if(resposta.isConfirmed){
                        Conexao.post('clientesController/deleteClienteRevenda', {
                            idempresa : selecionado.idempresa,
                            idcliente: selecionado.idcliente
                        }).then(response=>{
                            if(response.data.status =='OK'){
                                Swal.fire({
                                    title : 'Datacount', 
                                    text:'Operação realizada com sucesso !', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer  : 1500, 
                                    icon : 'success'
                                })
                               // getClientes()
                                setData(data.filter(cliente => cliente.idcliente != selecionado.idcliente))
                                setSelecionado([]);
                            }else{
                                Swal.fire({
                                    title : 'Datacount', 
                                    text:'Operação não realizada', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer  : 1500, 
                                    icon : 'warning'
                                })
                            }
                        }).catch(error=>{
                            Swal.fire({
                                title : 'Datacount', 
                                text:'Ocorreu um erro inesperado !', 
                                showCancelButton : false, 
                                showConfirmButton : false, 
                                timer  : 1500, 
                                icon : 'error'
                            })
                        })
                    }
                })
                
            }}><RemoveIcon /> Excluir</Button>
        </Grid>
        <Grid item spacing={1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
         <ModalGestaoAcessoCliente 
            usuario={props.location.state.empresa.idrevenda}
            empresa={selecionado.idempresa}
            idusuario={selecionado.idusuario}
            idempresa = {selecionado.idempresa}
            idcliente ={selecionado.idcliente}
            cliente={selecionado.idcliente}            
            disabled={selecionado && !selecionado.idcliente || selecionado.ativo == 'f'  || usuario.idusuariorevenda != null}                                              
            razaosocial={selecionado.razaosocial}/>
        </Grid>
    </Grid>
    <Grid container spacing={1} style={{marginTop :'5px'}}>
        <Grid item xs = {6}>            
           
        </Grid>
    </Grid>
   <div className='div-form-container' style={{paddingTop : '0px!important'}}>
    <Grid container style = {{marginTop:"0px"}} spacing={1}>
        <Table  className='table table-bordered table-striped table-hover table-small table-sm'>
            <TableHead>
                
                <TableCell align='center' style = {{width:"10%"}}>
                    <ContainerOrderBy 
                        title='CNPJ' 
                        help ='Ordena a lista pelo CNPJ do cliente'
                        handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                        direcao = {orderDirection}
                        ativo = {orderBy}
                        campo ='cpf_cnpj'
                        font = {10}
                        />
                </TableCell>
                <TableCell style = {{width:"50%"}}>
                    <ContainerOrderBy 
                        title='Razão Social' 
                        help ='Ordena a lista pela razão social do cliente'
                        handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                        direcao = {orderDirection}
                        ativo = {orderBy}
                        campo ='razaosocial'
                        font = {10}
                        />
                </TableCell>
                <TableCell align='center' style = {{width:"10%"}}><span style = {{fontSize :'10px'}}>CLIENTE ERP</span></TableCell>                
                <TableCell align='center' style = {{width:"15%"}}>
                    <ContainerOrderBy 
                        title='Cidade' 
                        help ='Ordena a lista pela cidade do cliente'
                        handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                        direcao = {orderDirection}
                        ativo = {orderBy}
                        campo ='cidade'
                        font = {10}
                        />
                </TableCell>
                <TableCell align='center' style = {{width:"10%"}}>
                    <ContainerOrderBy 
                        title='UF' 
                        help ='Ordena a lista pelo Estado do cliente'
                        handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                        direcao = {orderDirection}
                        ativo = {orderBy}
                        campo ='estado'
                        font = {10}
                        />
                </TableCell>
                <TableCell align='center' style = {{width:"10%"}}><span style = {{fontSize :'10px'}}>STATUS</span></TableCell>
            </TableHead>

            <TableBody>
                {
                    filteredData && filteredData.map(function(cliente, i){
                        return <TableRow key={'lGLC'+i} style ={{
                            backgroundColor : selecionado && selecionado.idcliente == cliente.idcliente ? "#E3F2FD" : '', textDecoration: cliente.ativo =='f' ? 'line-through!important' : '', 
                            opacity : selecionado.idcliente && selecionado.idcliente != cliente.idcliente ? 0.5 : 1 
                        }}   onClick={()=>{
                            if(selecionado && selecionado.idcliente == cliente.idcliente){
                                setSelecionado([])
                            }else{
                                setSelecionado(cliente)
                            }
                            
                            
                        }}>
                                <TableCell align='center'><Typography variant='caption' >{cliente.cpf_cnpj}</Typography></TableCell>
                                <TableCell>
                                    <Typography variant='subtitle2' >{cliente.razaosocial}</Typography>                                                                     
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography variant='subtitle2' >{cliente.idclienteinterno}</Typography>                                                                     
                                </TableCell>         
                                                      
                                <TableCell align='center'><Typography variant='caption' >{cliente.cidade}</Typography></TableCell>
                                <TableCell align='center'><Typography variant='caption' >{cliente.estado}</Typography></TableCell>                               
                               
                               
                                <TableCell align='center'>
                                {
                                    cliente.ativo =='t' ? <Tooltip title='Cliente ativo'><span style={{paddingLeft:'5px', paddingRight : '5px', borderRadius :'3px', fontSize :'10px', borderColor : 'green', color :'green', border :'solid 1px'}}>ATIVO</span></Tooltip> : 
                                    <Tooltip title='Cliente inativo'><span style={{paddingLeft:'5px', paddingRight : '5px', borderRadius :'3px', fontSize :'10px', borderColor : 'red', color :'red', border :'solid 1px'}}>INATIVO</span></Tooltip>
                                }
                                </TableCell>
                            </TableRow> 
                    }) 
                }
                {filteredData.length == 0 && <TableRow>
                    <TableCell colSpan={11}>
                    <Typography variant = 'caption' color='primary'>* Não existe(m) registro(s)</Typography>
                    </TableCell>
                    
                    
                    </TableRow>}
            </TableBody>
           
              
        </Table>
    </Grid>
    </div>

    <div className='div-paginator-button' >
            <div style={{display :'flex', alignContent:'center',  alignItems:'center', backgroundColor : '#f0f0f0', borderRadius :'5px', width : '200px', paddingLeft :'5px', paddingRight :'5px'}}>
            <Typography variant='caption'><strong>Total de registros:</strong> {totalRecords}</Typography>
            </div>
            <div style ={{ display:'flex', alignItems:'center'}}>
                <TablePagination
                        component="div"
                        count={totalRecords}
                        page={currentPage}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage='Linhas por página'
                        rowsPerPageOptions={[]}
                        />

            </div>
            <div style={{ backgroundColor : '#f0f0f0', borderRadius :'5px', width : '200px', paddingLeft :'5px', paddingRight :'5px'}}>
            <Typography variant='caption'><strong>Order by:</strong> {orderBy}</Typography><br/>
            <Typography variant='caption'><strong>Direction:</strong> {orderDirection}</Typography>
            </div>
        </div>
    <ModalFull 
        showModal={openModal} 
        fixedSize={true}
        size='md'
        closeModal = {()=>{setOpenModal(false)}}
        titulo= {edicao && edicao.idcliente ? 'Alteração cliente : '+edicao.razaosocial+'('+edicao.idcliente+')' : 'Cadastrar Novo Cliente'} >
        <FormNovoCliente  
            idempresa={props.location.state.empresa.idrevenda}
            chaveEmpresa={props.location.state.empresa.idempresa}
            edicaoClientes = {edicao}
            updateClientes = {()=>{fetchData()}}
            closeModal = {()=>{setOpenModal(false)}}/>
    </ModalFull>
   

    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(CadastroClientes)