import React, {Fragment, useEffect, useState, useRef} from 'react'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Grid, TextField, Tooltip, Divider, Button, Typography, InputAdornment} from '@material-ui/core'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import IconeExcel from './imagens/iconeExcel.svg'
import IconeHtml from './imagens/iconeHtml.svg'
import ModalBuscaCliente from '../../Clientes/ModalBuscaCliente'
import {Breadcrumbs} from '@material-ui/core'
import {Link} from 'react-router-dom'


const GestaoRelatorios = (props) =>{
    const [cliente, setCliente] = useState(props.location.state.empresa)
    const [meses, setMeses] = useState(1)
    const refReferencia = useRef();


    useEffect(()=>{
     // console.log(props.location.state)
    },[])

  



    return <Principal>
            <Grid container spacing={1}>
             
                   <Grid item xs = {12}>
                     <br/>
                    <Typography >Relatórios Collector</Typography>
                </Grid>
            </Grid>
            <br/>
            <br/>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                 <Typography variant='caption' color ='primary'>Cliente</Typography> 
                <Typography variant='h6' color ='primary'>{cliente.razaosocial}</Typography>
                </Grid>
                <Grid item xs={2}>
                <TextField
                    label='Mês de referência' 
                    variant='outlined'
                    fullWidth
                    select
                    onChange={(e)=>setMeses(e.target.value)}
                    SelectProps={{native:true}}
                    value = {meses}
                    InputLabelProps={{shrink : true}}>
                      <option value={1}>Mês anterior</option>
                      <option value={0}>Mês atual</option>
                      <option value={2}>2 meses atrás</option>
                      <option value={3}>3 meses atrás</option>
                </TextField>
                </Grid>
            </Grid>
            <br/>
            <br/>
            <div>
            <Breadcrumbs aria-label="breadcrumb">            
                <Link color="inherit" to={"/home-clientes/revenda/"+props.match.params.idempresa} >
                    Home
                </Link>
            <Typography color="textPrimary">Relatórios Collector</Typography>
            </Breadcrumbs>
            </div>
            <br/>
          
            {cliente.idcliente &&
            <Grid container spacing={1}>
                <Grid item xs ={2}>
                   
                  <div className='div-container-btn-relatorio'>
                      <Typography variant='subtitle1'>Contadores Ao Vivo</Typography>
              
                      <div style ={{display:"flex", marginTop:"20px"}}>
                          <div style = {{width:"50%"}}>
                          <a  target='_blank' href ={'https://datacount.com.br/datacount/index.php/clientesController/relatorioContadoresAoVivo?idempresa='+props.match.params.chaveEmpresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=html&meses=1'}>
                          <Tooltip title='Gerar relatório de contadores Ao Vivo em formato web'> 
                            <img src={IconeHtml} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://datacount.com.br/datacount/index.php/clientesController/relatorioContadoresAoVivo?idempresa='+props.match.params.chaveEmpresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=excel&meses=1'}>
                          <Tooltip title='Gerar relatório de contadores Ao Vivo em formato xls'> 
                            <img src={IconeExcel} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>                          
                      </div>
                  
                      <p style = {{fontSize:"9px",  padding:"0px"}}>* Relatório de coleta de contadores extraídos na data de hoje (ao vivo), não incluem equipamentos em datas anteriores.</p>
                  </div>  

                </Grid>
                <Grid item xs ={2}>
                  <div className='div-container-btn-relatorio'>
                      <Typography variant='subtitle1'>Contadores Diários</Typography>
                 
                      <div style ={{display:"flex", marginTop:"20px"}}>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://datacount.com.br/datacount/index.php/clientesController/relatorioContadoresDiarios?idempresa='+props.match.params.chaveEmpresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=html&meses='+meses}>
                          <Tooltip title='Gerar relatório de fechamento (detalhamento diário) em formato web'> 
                            <img src={IconeHtml} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://datacount.com.br/datacount/index.php/clientesController/relatorioContadoresDiarios?idempresa='+props.match.params.chaveEmpresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=excel&meses='+meses}>
                          <Tooltip title='Gerar relatório de fechamento (detalhamento diário) em formato xls'> 
                            <img src={IconeExcel} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>                          
                      </div>
              
                      <p style = {{fontSize:"9px",  padding:"0px"}}>
                          * Equipamentos podem não aparecer em datas onde não foram ligados!
                      </p>
                  </div>  
                </Grid>

                <Grid item xs ={2}>
                  <div className='div-container-btn-relatorio'>
                      <Typography variant='subtitle1'>Fechamento Mensal</Typography>
                 
                      <div style ={{display:"flex", marginTop:"20px"}}>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://www.datacount.com.br/datacount/index.php/clientesController/relatorioContadoresMensais?idempresa='+props.match.params.chaveEmpresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=html&meses='+meses}>
                          <Tooltip title='Gerar relatório de fechamento em formato web'> 
                            <img src={IconeHtml} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://datacount.com.br/datacount/index.php/clientesController/relatorioContadoresMensais?idempresa='+props.match.params.chaveEmpresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=excel&meses='+meses}>
                          <Tooltip title='Gerar relatório de fechamento em formato xls'> 
                            <img src={IconeExcel} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>                          
                      </div>
              
                      <p style = {{fontSize:"9px",  padding:"0px"}}>
                          * Equipamentos podem não aparecer em datas onde não foram ligados!
                      </p>
                  </div>  
                </Grid>

                <Grid item xs ={2}>
                  <div className='div-container-btn-relatorio'>
                      <Typography variant='subtitle1'>Contadores Modelo</Typography>
                 
                      <div style ={{display:"flex", marginTop:"20px"}}>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://www.datacount.com.br/datacount/index.php/coletasController/relatorioContadoresModelo?idempresa='+props.match.params.chaveEmpresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=html'}>
                          <Tooltip title='Gerar relatório de contadores agrupados por modelo em formato web'> 
                            <img src={IconeHtml} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://www.datacount.com.br/datacount/index.php/coletasController/relatorioContadoresModelo?idempresa='+props.match.params.chaveEmpresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=html'}>
                          <Tooltip title='Gerar relatório de contadores agrupados por modelo em formato xls'> 
                            <img src={IconeExcel} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>                          
                      </div>
              
                      <p style = {{fontSize:"9px",  padding:"0px"}}>
                          * Equipamentos podem não aparecer em datas onde não foram ligados!
                      </p>
                  </div>  
                </Grid>

                <Grid item xs ={2}>
                  <div className='div-container-btn-relatorio'>
                      <Typography variant='subtitle1'>Relatório Parque</Typography>
                 
                      <div style ={{display:"flex", marginTop:"20px"}}>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://www.datacount.com.br/datacount/index.php/coletasController/relatorioContadoresAoVivo?idempresa='+props.match.params.chaveEmpresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=html'}>
                          <Tooltip title='Gerar relatório de contadores de todos os equipamentos em formato web'> 
                            <img src={IconeHtml} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://www.datacount.com.br/datacount/index.php/coletasController/relatorioContadoresAoVivo?idempresa='+props.match.params.chaveEmpresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=excel'}>
                          <Tooltip title='Gerar relatório de contadores de todos os equipamentos em formato xls'> 
                            <img src={IconeExcel} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>                          
                      </div>
              
                      <p style = {{fontSize:"9px",  padding:"0px"}}>
                          * Todos equipamentos listados independente da última data de coleta.
                      </p>
                  </div>  
                </Grid>

             
            </Grid>
  }
    </Principal>
}

export default withRouter(GestaoRelatorios)