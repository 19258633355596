import React, {useState, useEffect, useRef, Fragment} from 'react'

import {TextField,   Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
,Switch, TableRow, Badge, dat} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Block';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link , useHistory } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ExtensionTwoToneIcon from '@material-ui/icons/ExtensionTwoTone';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import LabeData from '../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import DisplayToner from '../../components/DisplayToner'
import DisplayLinearToner from '../../components/DisplayLinearToner'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'; //case 2 
import SettingsPowerTwoToneIcon from '@material-ui/icons/SettingsPowerTwoTone';//energsaving
import ReportProblemIcon from '@material-ui/icons/ReportProblem';//case 5
import PrintIcon from '@material-ui/icons/Print';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import HelpIcon from '@material-ui/icons/Help';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import SearchIcon from '@material-ui/icons/Search';

import TablePagination from '@material-ui/core/TablePagination';
import ModalDetalhesLeitura from './ModalDetalhesColeta'
import BlockIcon from '@material-ui/icons/Block';
import Alert from '@material-ui/lab/Alert'
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ModalFullDetalhes from '../../components/ModalFull2'
import DetalhesColeta from './DetalhesColeta'
import GestureIcon from '@material-ui/icons/Gesture'
import DoorOpen from '@material-ui/icons/MeetingRoom'
import CachedIcon from '@material-ui/icons/Cached'
import LinkOff from '@material-ui/icons/LinkOff'
import Slider from '@material-ui/core/Slider'
import FilterIcon from '@material-ui/icons/DateRange'
import FilterIcone2 from '@material-ui/icons/FilterList'
import DetailIcon from '@material-ui/icons/Apps'
import ModalHabilitaMonitorIndividual from './ModalMonitoramentoIndividual'
import IconeLocalizarClienteModal from '../../components/IconeLocalizarClienteModal'
import TemplateRevenda from '../Template/TemplateRevenda';
import SideBarGestaoColetas from './SideBarGestaoColetas'
import SideBarPadrao from './SideBarPadrao';
import SideBarFiltro from './SideBarFiltro';
import ModalOpcaoRelatorio from './ModalOpcaoRelatorio';
import ModalFltroPapercut from './ModalFiltroPapercut';
import { AlertTitle } from '@material-ui/lab';
import BotaoMenuRelatorios from './BotaoMenuRelatorios';
import ContainerOrderBy from '../../components/ContainerOrderBy';

//import {setColetaRepositorio} from '../../providers/Repository'
//
import {getDataRepository, substituirColetaEditada, listarClientesEquipamentos} from '../../providers/Repository'



let temporizador = null;

const GestaoColetas = (props)=>{
    let history = useHistory();
    const classes = useStyles();
    const [showSelection, setShowSelection] = useState(false)
    const [checkdAll, setCheckedAll] = useState(false)
    
    const [idLeitura, setIdLeitura]= React.useState(-1);
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [colunaFiltro, setColunaFiltro] = useState('Cliente')
    const [resetFiltro, setResetFiltro]= useState(false)
    const [parametroFiltro, setParametroFiltro] = useState({
        marca : '', 
        modelo : '', 
        enderecoIP : '', 
        numeroSerie : '', 
        tipoData : 'cadastro', 
        dataInicial : '', 
        dataFinal : ''
    })
    const [filtro, setFiltro] = useState(false)
    const [selecionado, setSelecionado]= useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [ coletas, setColetas] = useState([])
    const [ coleta, setColeta] = useState([])
    const [ registros, setRegistros] = useState([])
   const [totalEquipamentosNovos, setTotalEquipamentosNovos]= useState(0)
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [totalRegistros, setTotalRegistros]= useState([])
    const [registrosFiltrados, setRegistrosFiltrados] = useState([])
    const [dias, setDias]= useState(0)
    const [chaveDca, setChaveDca]= useState('')
    const [clientes, setClientes ] = useState([])
    const [codigoCliente, setCodigoCliente]= useState(-1)
    const [orderBy, setOrderBy] = useState('datacadastro'); // Estado para a coluna de ordenação
    const [orderDirection, setOrderDirection] = useState('desc'); 
    const {usuario} = props.location.state

    //filtrosSidebar
    const resetParametrosFiltro = () =>{
        setParametroFiltro({
            marca : '', 
            modelo : '', 
            enderecoIP : '', 
            numeroSerie : '', 
            tipoData : 'cadastro', 
            dataInicial : '', 
            dataFinal : ''
        });
    }


   

    const [cliente, setCliente]= useState([])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        displayPage(registrosFiltrados, newPage, rowsPerPage)
        
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        displayPage(registrosFiltrados,0, event.target.value)
    };




    useEffect(()=>{
       // getData();
       setPage(0)
        console.log(usuario)
    }, [])

    
    useEffect(()=>{
        // getData();
        if(coletas){
            const temp = coletas
            for(let i = 0; i < temp.length; i++){
                temp[i].selecionado = 'f';
            }
            setColetas(temp);
        }
       
      
     }, [])


     useEffect(()=>{
        // getData();
        if(coletas){
            const temp = coletas
            for(let i = 0; i < temp.length; i++){
                temp[i].selecionado = 'f';
            }
            setColetas(temp);
        }
       
      
     }, [showSelection])

   
   


    useEffect(()=>{
        if(registrosFiltrados.length > 0 ){
            displayPage(registrosFiltrados, 0, rowsPerPage)
        }
       
    }, [registrosFiltrados])

    useEffect(()=>{
        if(registros){
            if(registros.length > 0)
            setRegistros(registros.reduce((acumulador, item)=>{
            return item.equipamentonovo == 't' && item.ativo =='t' ? acumulador + 1 : acumulador
           }, 0))
        }
       
       
    }, [registros])

   

    
    function displayPage(dados, pagina, regs) {
        
        const startIndex = (pagina) * regs;
        const endIndex = startIndex + regs;

        if(orderBy != null){
            registrosFiltrados.sort((a, b) => {
                // Obtém os valores dos objetos para a chave especificada por orderBy
                const valueA = a[orderBy] != null ? a[orderBy] : '';
                const valueB = b[orderBy] != null ? b[orderBy] : '';
            
                // Função para verificar se um valor é numérico
                const isNumeric = (val) => !isNaN(parseFloat(val)) && isFinite(val);
            
                // Checa se algum dos valores é vazio
                if (valueA === '' && valueB !== '') {
                    return orderDirection === 'asc' ? 1 : -1;
                }
                if (valueA !== '' && valueB === '') {
                    return orderDirection === 'asc' ? -1 : 1;
                }
            
                // Verifica se os valores são numéricos
                const areBothNumeric = isNumeric(valueA) && isNumeric(valueB);
            
                if (areBothNumeric) {
                    // Comparação numérica
                    return orderDirection === 'asc' ? valueA - valueB : valueB - valueA;
                } else {
                    // Comparação alfabética
                    const strA = String(valueA);
                    const strB = String(valueB);
                    return orderDirection === 'asc' ? strA.localeCompare(strB) : strB.localeCompare(strA);
                }
            });
        }
        
        
        
        const pageItems = registrosFiltrados.slice(startIndex, endIndex);
        setColetas(pageItems)
        setTotalRegistros(registrosFiltrados.length);
    }

 


   

   
    const renderStatus = (status, ip, dias, ativo, painel = "") =>{
        if(ativo =='f'){
            

            return <div><Tooltip title ={'Equipamento Desativado'}><BlockIcon className={'icon-status-coleta5'}/></Tooltip> <Typography style = {{fontSize:"9px", marginTop:"0px"}}>DESATIVADO</Typography></div>        
        }

        if(ativo == 'f'){
            return <div><Tooltip title ={'Este equipamento esta desativado, atualizações de coleta não são processadas'}><LockIcon className={'icon-status-coleta5'}/></Tooltip> <Typography style = {{fontSize:"9px", marginTop:"0px"}}>DESATIVADA</Typography></div>        
        }

       if(!dias){
            return <div><Tooltip title ={'Sem comunicação'}><LinkOffIcon className={'icon-status-coleta5'}/></Tooltip> <Typography style = {{fontSize:"9px", marginTop:"0px"}}>Sem Conexão</Typography></div>        
        }



    switch(status){
        case 1:return <div><HelpIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"8px", marginTop:"0px"}}>DESCONHECIDO</Typography></div>
            
        case 2:return <div><PowerSettingsNewIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"8px", marginTop:"0px"}}>PRONTO</Typography></div>
            
        case 3:return <div><SettingsPowerIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"8px", marginTop:"0px"}}>ENERGY SAVING</Typography></div>
            
        case 4:return <div><PrintIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"8px", marginTop:"0px"}}>IMPRIMINDO</Typography></div>

        case 5:return <div><ReportProblemIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"8px", marginTop:"0px"}}>OCORRÊNCIA</Typography></div>

        case 6:return <div><Tooltip title={painel}><NotificationsActiveIcon className={'icon-status-coleta'+status}/></Tooltip><Typography style = {{fontSize:"8px", marginTop:"0px"}}>ATENÇÃO</Typography></div>

        case 7:return <div><Tooltip title={painel}><GestureIcon className={'icon-status-coleta'+status}/></Tooltip><Typography style = {{fontSize:"8px", marginTop:"0px"}}>ATOLAMENTO</Typography></div>

        case 8:return <div><Tooltip title={painel}><DoorOpen className={'icon-status-coleta'+status}/></Tooltip><Typography style = {{fontSize:"8px", marginTop:"0px"}}>Porta Aberta</Typography></div>


        default:return <div><HelpIcon className={'icon-status-coleta'}/><Typography style = {{fontSize:"8px", marginTop:"0px"}}>DESCONHECIDO</Typography></div>
    }
        
   }


 

   
    //https://theroadtoenterprise.com/blog/how-to-download-csv-and-json-files-in-react
   const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })
  
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }
  
  const exportToJson = (usersData) => {
   
    downloadFile({
      data: JSON.stringify(usersData),
      fileName: 'users.json',
      fileType: 'text/json',
    })
  }
  
  const exportToCsv = (userData) => {
   
  
    // Headers for each column
    let headers = ['Endereco IP ,Data Coleta, Hora Coleta, Razao Social, Numero de Serie, Marca, Modelo, A4 Mono, A3 Mono, Total Mono, A4 Color, A3 Color, Total Color, Total Geral, Scan 1, Scan 2']
  
    // Convert users data to a csv
    let usersCsv = registrosFiltrados.reduce((acc, user) => {
      const { enderecoip, datacoleta, horacoleta, razaosocial, numeroserie, marca, modelo, contadora4mono, contadora3mono, monototal, contadora4color, contadora3color, colortotal, contadortotal} = user
      acc.push([enderecoip, datacoleta, horacoleta,razaosocial, numeroserie, marca, modelo, contadora4mono, contadora3mono, monototal, contadora4color, contadora3color, colortotal, contadortotal].join(','))
      return acc
    }, [])
  
    downloadFile({
      data: [...headers, ...usersCsv].join('\n'),
      fileName: 'users.csv',
      fileType: 'text/csv',
    })
  }

    
useEffect(()=>{

    if(!showSelection){

        setCheckedAll(false)

    }
    
},[showSelection])


const handleOrderChange = (name, value) => {
    console.log("order by "+name+' direcao '+value)
   setOrderBy(name);
   setOrderDirection(value);
   

};
useEffect(()=>{
    displayPage(registrosFiltrados, 0, rowsPerPage)
}, [orderBy, orderDirection])

const renderOpcaoPaginas = () =>{
    switch(rowsPerPage){
        case 10:
            return <React.Fragment>
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
            </React.Fragment>
        break;
        case 25:
            return <React.Fragment>
                <option>25</option>
                <option>10</option>
                <option>50</option>
                <option>100</option>
            </React.Fragment>
        break;
        case 50:
            return <React.Fragment>
                <option>50</option>
                <option>10</option>
                <option>25</option>
                <option>100</option>
            </React.Fragment>
        break;
    }
}

return <TemplateRevenda atualizar = {loading} full={true} breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">  
        <Typography>Você esta em</Typography>          
            <Link color="inherit" to={'#'} >
                VISÃO GERAL DE PARQUE 
            </Link>                
        </Breadcrumbs>
    }>

        
       <div style={{marginTop : '0px', paddingBottom :'5px', height :'95px'}}>
       <Grid container spacing={1}>
        <Grid item xs = {5}>
        {totalEquipamentosNovos > 0 && <div style={{paddingTop:'5px'}}>
            
            <Alert severity='info'>
                <AlertTitle>Aviso de novas descobertas</AlertTitle>
                <Link  to ={{pathname : '/novosdispositivos/revenda/'+props.match.params.idempresa+'/empresa/'+props.match.params.chaveEmpresa+'/page/1'}}>
                    <Typography variant='caption' style ={{color: '#000'}}>Existe(m) {totalEquipamentosNovos} equipamento(s) novo(s) recém descoberto(s), <strong >click aqui para acessar.</strong></Typography>
                </Link>
            </Alert>           

        </div>}
        </Grid>

       </Grid>

       <Grid container spacing ={1}>
            <Grid item xs ={9}>
                <Switch color='secondary' checked={showSelection} onChange={()=>{
                    setShowSelection(!showSelection)
                }}/>
                <Typography  variant='caption'>Habilitar Seleção</Typography> 
            </Grid>
            <Grid item xs ={2} style={{   textAlign:'right'}}>               
                <Typography  variant='subtitle1' style ={{marginTop : '5px'}}>Linhas por página</Typography> 
            </Grid>
            <Grid item xs ={1}>
                <TextField
                    select
                    SelectProps={{native : true}} 
                    variant='outlined'
                    size='small'
                    fullWidth 
                    value={rowsPerPage}    
                    onChange={(e)=>{
                        handleChangeRowsPerPage(e)
                        
                    }}               
                    >
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                </TextField>
            </Grid>
        </Grid>
        </div>

    
        <Grid container spacing ={1} style={{ paddingTop: '0px',marginTop:'0px'}}>
            <Grid item xs={12}>
                <ModalFltroPapercut 
                    chaveEmpresa ={props.match.params.chaveEmpresa}
                    codigoRevenda ={props.match.params.idempresa}
                    dataType='aoVivo'
                    registrosFiltrados={registrosFiltrados}
                    setRegistrosFiltrados={(data)=>setRegistrosFiltrados(data)}
                    setRegistros={(data)=>setRegistros(data)}
                    setLoading = {(acao)=>{
                        setLoading(acao)
                    }}
                    setShowSelection = {(op)=>setShowSelection(op)}
                    disabled={showSelection}
                    registrosDesativar ={
                        coletas.filter(item =>item.selecionado =='t' && item.ativo == 't').map((item)=>{
                            return item.idcoleta
                        })
                    }
                    updateGrid = {()=>setShowSelection(false)}
                    Conexao ={Conexao}
                    usuario={usuario}
                    />
            </Grid>                    
        </Grid>

       
       
        <div className='div-form-container'>
        <Grid spacing={1}>
            <Table className='table table-striped table-hover table-small table-sm table-container'  >
                <TableHead >      
                    <TableCell align='center' style = {{width:"5%",height : '25px'  }}>
                    <ContainerOrderBy 
                        title='Conexão' 
                        help ='Ordena a lista pelo endereço IP'
                        handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                        direcao = {orderDirection}
                        ativo = {orderBy}
                        campo ='enderecoip'
                        sizeIcon = {'30%'}
                        sizeText ={'70%'}
                        font = {10}
                        />    
                    </TableCell>  
                    <TableCell align='center' style = {{width:"5%", }}>
                    {
                             showSelection ? <input type='checkbox' style={{ marginTop : '15px'}}  onClick={()=>{

                                const temp = coletas;

                                if(checkdAll){

                                    setCheckedAll(false)
                        
                                    for(let i = 0; i < temp.length; i++){
                                        temp[i].selecionado = 'f';
                                    }

                                    setColetas(temp)

                                }else{

                                    setCheckedAll(true)
                        
                                    for(let i = 0; i < temp.length; i++){
                                        temp[i].selecionado = 't';
                                    }

                                    setColetas(temp)
                                }
                        
                        }}/> : <ContainerOrderBy 
                        title='DCA' 
                        help ='Ordena a lista pelo número do DCA'
                        handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                        direcao = {orderDirection}
                        ativo = {orderBy}
                        campo ='iddca'
                        font = {10}
                        />  
                        }
                    </TableCell>  
                    <TableCell align='center' style = {{width:"8%" ,  }}>
                    <ContainerOrderBy 
                        title='Cadastro' 
                        help ='Ordena a lista pela data da 1º leitura'
                        handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                        direcao = {orderDirection}
                        ativo = {orderBy}
                        campo ='datacadastro'
                        font = {10}
                        />     
                    </TableCell> 
                    <TableCell align='center' style = {{width:"8%" ,  }}>
                    <ContainerOrderBy 
                        title='Leitura' 
                        help ='Ordena a lista pela data da última leitura'
                        handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                        direcao = {orderDirection}
                        ativo = {orderBy}
                        campo ='datacoleta'
                        font = {10}
                        /> 
                    </TableCell>
                    <TableCell align='left' style = {{width:"10%" ,  }} >
                    <ContainerOrderBy 
                        title='Número série' 
                        help ='Ordena a lista pelo número de série'
                        handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                        direcao = {orderDirection}
                        ativo = {orderBy}
                        campo ='numeroserie'
                        font = {10}
                        /> 
                    </TableCell>
                    <TableCell align='left' style = {{width:"20%" , }} >
                    <ContainerOrderBy 
                        title='Modelo' 
                        help ='Ordena a lista pelo modelo do equipamento'
                        handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                        direcao = {orderDirection}
                        ativo = {orderBy}
                        campo ='modelo'
                        font = {10}
                        /> 
                    </TableCell>
                    <TableCell align='left' style = {{width:"35%", }} >
                    <ContainerOrderBy 
                        title='Cliente' 
                        help ='Ordena a lista pela Razão Social do Cliente'
                        handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                        direcao = {orderDirection}
                        ativo = {orderBy}
                        campo ='razaosocial'
                        font = {10}
                        /> 
                    </TableCell>
                    <TableCell align='center' style = {{width:"10%"}}>
                    <ContainerOrderBy 
                        title='Medidores' 
                        help ='Ordena a lista pelo contador total do equipamento'
                        handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                        direcao = {orderDirection}
                        ativo = {orderBy}
                        campo ={'contadortotal'}
                        sizeIcon = {'30%'}
                        sizeText ={'70%'}
                        font = {10}
                        /> 
                    </TableCell>
                    <TableCell align='center' style = {{width:"5%"}}>
                    <div style={{display:'flex'}}>
                        <div style ={{width : '25%'}}>
                            <ContainerOrderBy 
                                title='C' 
                                help ='Ordena a lista pelos níveis de suprimento'
                                handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                                direcao = {orderDirection}
                                ativo = {orderBy}
                                campo ={'nivelciano'}
                                sizeIcon = {'30%'}
                                sizeText ={'70%'}
                                font = {10}
                                /> 
                        </div>

                        <div style ={{width : '25%'}}>
                            <ContainerOrderBy 
                                title='M' 
                                help ='Ordena a lista pelos níveis de suprimento'
                                handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                                direcao = {orderDirection}
                                ativo = {orderBy}
                                campo ={'nivelmagenta'}
                                sizeIcon = {'30%'}
                                sizeText ={'70%'}
                                font = {10}
                                /> 
                        </div>

                        <div style ={{width : '25%'}}>
                            <ContainerOrderBy 
                                title='Y' 
                                help ='Ordena a lista pelos níveis de suprimento'
                                handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                                direcao = {orderDirection}
                                ativo = {orderBy}
                                campo ={'nivelamarelo'}
                                sizeIcon = {'30%'}
                                sizeText ={'70%'}
                                font = {10}
                                /> 
                        </div>

                        <div style ={{width : '25%'}}>
                            <ContainerOrderBy 
                                title='K' 
                                help ='Ordena a lista pelos níveis de suprimento'
                                handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                                direcao = {orderDirection}
                                ativo = {orderBy}
                                campo ={'nivelpreto'}
                                sizeIcon = {'30%'}
                                sizeText ={'70%'}
                                font = {10}
                                /> 
                        </div>
                    </div>
                    </TableCell>                   
                </TableHead>                                
                <TableBody >                
                {
                   (registrosFiltrados && registrosFiltrados.length > 0) && coletas && coletas.map(function(coleta, i){
                        return <React.Fragment>
                                    <TableRow  key = {'ROWCOL'+i} className={selecionado && selecionado.idcliente == coleta.idcliente  && selecionado.numeroserie == coleta.numeroserie ? 'selectedRow' : ''}  style ={{
                                    backgroundColor : selecionado && selecionado.idcliente == coleta.idcliente  && selecionado.numeroserie == coleta.numeroserie ? "#ECEFF1" : '', 
                                    /*opacity : selecionado.idcoleta && selecionado.idcoleta != coleta.idcoleta ? 1 : 1 ,*/
                                    borderBottom : selecionado.idcoleta && selecionado.idcoleta != coleta.idcoleta ? '' : 'none!important' ,
                                    cursor :'default'
                                }}>
       
                        <TableCell align='center' style ={{ verticalAlign:'center', }} >
                        <div ><span style ={{fontSize:"9px"}}>{coleta.enderecoip}</span></div>
                        {renderStatus(coleta.statussistema, coleta.enderecoip, coleta.dias, coleta.ativo, coleta.mensagempainel) }
                             
                        </TableCell>                    
                        <TableCell align='center' style ={{ verticalAlign:'center', }}>
                            {
                             showSelection && coleta.ativo =='t' ? <input type='checkbox' className='' style={{ marginTop : '15px'}} id={'chk-select-'+i} checked={coleta.selecionado =='t'}  onChange={(e)=>{
                                 
                                const temp = [...coletas]; // Cria uma cópia do array coletas
                                
                                temp[i] = { ...temp[i], selecionado: temp[i].selecionado == 'f' ? 't' : 'f' }; // Atualiza a propriedade selecionado no objeto correspondente
                                
                                
                                setColetas(()=>temp); // Atualiza o estado coletas com a cópia do array atualizado
                                
                                
                             }}/>   : 
                            <Button variant='contained' style={{marginTop :'15%'}} onClick={()=>{
                               // setChaveDca(coleta.idcoleta)
                            }}>
                            <Typography color='primary'  variant='caption' style ={{fontSize:"14px",}}>{coleta.iddca}</Typography>
                            </Button>
                    }
                            
                          
                        </TableCell>          
                        <TableCell align='center' style ={{ verticalAlign:'center', }}>
                            
                            <Typography color='primary' variant='caption' style ={{fontSize:"9px", verticalAlign:'center'}}>{coleta.datacadastro}</Typography><br/>
                            <Typography color='primary' variant='caption' style ={{fontSize:"9px", verticalAlign:'center'}}>{coleta.horacadastro ? coleta.horacadastro.substr(0, 8) : ''}</Typography>
                        </TableCell>
                        <TableCell align='center' style ={{ verticalAlign:'center',  backgroundColor : colunaFiltro == 'Data de Coleta' ? '#ECEFF1' : ''}}>
                            
                            <Typography color='primary' variant='caption' style ={{fontSize:"9px"}}>{coleta.dia == 0 ? coleta.datacoleta: ' Sem resposta há ' + coleta.dia+' dia(s)'}</Typography><br/>
                            {coleta.dia == 0  ? <Typography color='primary' variant='caption' style ={{fontSize:"9px"}}>{coleta.horacoleta ? coleta.horacoleta.substr(0, 8) : ''}</Typography> : ''}
                        </TableCell>
                        <TableCell align='left' style ={{  verticalAlign:'center', }}>  
                            <Tooltip title='Clique para acessar informações e funções da coleta'>
                                <Button fullWidth variant='contained' style ={{height:'100%'}} onClick={()=>{ 
                                        if(usuario.equipamentosdetalhes === 'f' && usuario.idusuariorevenda !== null){
                                            Swal.fire({
                                                title :'Datacount', 
                                                text : 'Você não term permissão de acessar este recurso!', 
                                                icon :'warning', 
                                                showConfirmButton:false, 
                                                showCancelButton : false, 
                                                timer : 2500
                                            })
                                            return
                                        }
                                        if(selecionado && selecionado.idcliente == coleta.idcliente  && selecionado.numeroserie == coleta.numeroserie){

                                            setSelecionado([])

                                        }else{
                                            
                                            setSelecionado(coleta)

                                        }

                                    }}>
                                      <Typography color='primary' className='texto-limitado'  ><strong style ={{fontSize:"11px"}}>{coleta.serialalternativo ? coleta.serialalternativo : coleta.numeroserie}</strong> </Typography> 
                                        
                           
                                
                                </Button>
                            </Tooltip>      
                            {coleta.placa ? 
                            <div style ={{width :' 100%',  textAlign:'center',  }} onClick={()=>{ 
                                if(usuario.equipamentosdetalhes === 'f' && usuario.idusuariorevenda !== null){
                                    Swal.fire({
                                        title :'Datacount', 
                                        text : 'Você não term permissão de acessar este recurso!', 
                                        icon :'warning', 
                                        showConfirmButton:false, 
                                        showCancelButton : false, 
                                        timer : 2500
                                    })
                                    return
                                }

                                if(selecionado && selecionado.idcliente == coleta.idcliente  && selecionado.numeroserie == coleta.numeroserie){

                                    setSelecionado([])

                                }else{
                                    
                                    setSelecionado(coleta)

                                }

                            }}>
                                <span style ={{borderRadius :'5px', paddingLeft: '5px', paddingRight :'5px', border:'solid 1px #ccc', color : '#fff', backgroundColor:'#444'}}>
                                    <strong style ={{fontSize:"11px"}}>PAT: {coleta.placa}</strong> 
                                </span> 
                            </div>
                            :
                            null}                                                                                                                                         
                        </TableCell>
                       
                        <TableCell align='left' style ={{ verticalAlign:'center' , }}>    
                             <div>
                                <Badge badgeContent={coleta.diasCadastro <= 0 ? 'Novo' : 0 } color='secondary' className='novo'>                                                                             
                                    <Typography color='primary'  ><strong style ={{fontSize:"11px"}}>{coleta.modelo}</strong></Typography>
                                </Badge>  
                            </div>                                             
                            <span style ={{fontSize:"10px"}}>{coleta.marca}</span>                                                           
                        </TableCell>
                        <TableCell align='left' style ={{ verticalAlign:'center', }}>
                            <Typography color='primary'  ><strong style ={{fontSize:"11px"}}>{coleta.razaosocial}</strong></Typography>                          
                            <Typography variant='caption' style={{fontSize:'10px'}}>Setor: <strong>{coleta.setor ? coleta.setor : coleta.syslocation}</strong></Typography>
                                                        
                            {coleta.monitorartoner=='t' && <div style={{color:"#444"}}><NetworkCheckIcon style={{color:"#00897b"}} /><span style ={{fontSize:"9px"}}>Alerta <strong>GLOBAL </strong> 
                                habilitado : C{coleta.monitoramentociano}% M{coleta.monitoramentomagenta}% Y{coleta.monitoramentoamarelo}% K{coleta.monitoramentopreto}% </span> </div>}
                                
                            
                        </TableCell>
                            <TableCell align='center' style ={{ verticalAlign:'center'}}>                            
                            <Typography variant='caption' className='conter-total'>{coleta.contadortotal}</Typography><br/>
                            <Typography variant='caption' className='conter-mono'>PB: {coleta.monototal}</Typography>
                            <Typography variant='caption' className='conter-color'> CL: {coleta.colortotal}</Typography>
                        </TableCell>
                        <TableCell align='center'style ={{ verticalAlign:'center'}} >
                            <DisplayLinearToner value ={coleta.nivelciano ? coleta.nivelciano : 0} rotulo = 'ciano' alerta={coleta.monitorartoner =='t' && coleta.alertaciano == 't' } nivelAlerta={coleta.nivelalertaciano}/>
                            <DisplayLinearToner value ={coleta.nivelmagenta ? coleta.nivelmagenta : 0} rotulo='magenta' alerta={coleta.monitorartoner =='t' && coleta.alertamagenta == 't'} nivelAlerta={coleta.nivelalertamagenta}/> 
                            <DisplayLinearToner value ={coleta.nivelamarelo ? coleta.nivelamarelo : 0} rotulo ='amarelo' alerta={coleta.monitorartoner =='t' && coleta.alertaamarelo == 't'} nivelAlerta={coleta.nivelalertaamarelo}/>
                            <DisplayLinearToner value ={coleta.nivelpreto ? coleta.nivelpreto : 0} rotulo = 'preto' alerta={coleta.monitorartoner =='t' &&  coleta.alertapreto == 't'} nivelAlerta={coleta.nivelalertapreto}/>
                                            
                        </TableCell> 
                        
                    </TableRow>
                    
                    
                    </React.Fragment>
                    })
                    
                    }
                    {
                        coletas.length == 0 && <TableRow>
                        <TableCell colSpan={9} align='left'>
                            <Alert severity='info'>{loading ?'Aguarde... buscando informações.' : '* Não existem contadores para o filtro especificado.'}</Alert>
                        </TableCell>
                       
                        </TableRow>
                    }
                </TableBody>
            </Table>
            
        </Grid>                     
        </div>
        <div className='div-paginator-button' >
            <div style={{display :'flex', alignContent:'center',  alignItems:'center', backgroundColor : '#f0f0f0', borderRadius :'5px', width : '200px', paddingLeft :'5px', paddingRight :'5px'}}>
            <Typography variant='caption'><strong>Total de registros:</strong> {registrosFiltrados.length}</Typography>
            </div>
            <div style ={{ display:'flex', alignItems:'center'}}>
                <TablePagination
                        component="div"
                        count={registrosFiltrados.length}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage='Linhas por página'
                        rowsPerPageOptions={[]}
                        />
            </div>
            <div style={{ backgroundColor : '#f0f0f0', borderRadius :'5px', width : '200px', paddingLeft :'5px', paddingRight :'5px', paddingTop:'0px'}}>
            <Typography variant='caption'><strong>Order by:</strong> {orderBy}</Typography><br/>
            <Typography variant='caption'><strong>Direction:</strong> {orderDirection}</Typography>
            </div>
        </div>
     
      <ModalFullDetalhes 
        showModal={coleta && coleta.idcoleta} 
        marginTop
        setOpen = {()=>{
            setColeta([])
            //setSelecionado([])
        }}
       
        titulo ={coleta.idcoleta && coleta.numeroserie+' - '+coleta.modelo}>
            <DetalhesColeta 
                state = {coleta} 
                showModal={coleta && coleta.idcoleta} 
                setColetaRepositorio={(value)=>substituirColetaEditada(value)}
                />
        </ModalFullDetalhes>

        <SideBarGestaoColetas 
            
            Conexao={Conexao} setColeta = {(value)=>{
            setColeta(selecionado)
            }}  showModal={selecionado && selecionado.idcoleta} coleta= {selecionado} closeModal={()=>{
            setSelecionado([])
            setColeta([])
            }}
            usuario={usuario}
            //getData = {()=>getDataRepository()}
            updateColeta = {(data)=>{
                
                substituirColetaEditada(data).then(resposta=>{
                   alert('teste')
                }).catch(error=>{
                    console.log(error)
                })
            }}
            />
        <SideBarPadrao chaveDca = {chaveDca}/>
    </TemplateRevenda>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(GestaoColetas)