import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {Grid, TextField} from '@material-ui/core'
import ExtensionTwoToneIcon from '@material-ui/icons/ExtensionTwoTone';
import Conexao from '../../providers/Conexao'
import Swal from 'sweetalert2'


let contador = -1;
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalNovoItemColeta(props) {
  const [open, setOpen] = React.useState(false);
  const [camposDisponiveis, setCamposDisponiveis] = React.useState(false);

  const inicialState = [
    'divider'
    ,'marca'
    ,'modelo'
    ,'numeroSerie'
    ,'placa'
    ,'setor'
    ,'statussistema'
    ,'statuscopiadora'
    ,'statusimpressora'
    ,'statusscanner'
    ,'sysuptime'
    ,'mensagempainel'
    ,'divider'
    ,'ethernet'
    ,'macaddress'
    ,'wireless'
    ,'macwifi'
    ,'wifimode'
    ,'ssid'
    ,'wifisecurity'
    ,'wifistatus'
    ,'smbv1v2'
    ,'portasmb'
    ,'statussmb'
    ,'divider'
    ,'memoria'
    ,'documentserver'
    ,'documentserverfree'
    ,'harddisc'
    ,'harddiscfree'
    ,'harddiscserial'
    ,'harddiscsize'
    ,'divider'
    ,'versaofirmware'
    ,'subfirmware'
    ,'xoacommonversion'
    ,'versaoxoa'
    ,'versaoup'
    ,'versaodofirmwareengine'
    ,'versaodofirmwareui'
    ,'versaobootrom'
    ,'versaosistemafich'
    ,'versaodoscanner'
    ,'versaofax'
    ,'versaotabuleiro1'
    ,'versaotabuleiro2'
    ,'versaotabuleiro3'
    ,'versaotabuleiro4'
    ,'firmwarerede'
    ,'firmwarenib'
    ,'firmwareweb'
    ,'firmwarelanguage'
    ,'firmwarepjl'
    ,'firmwarerpcs'
    ,'firmwarepcl5e'
    ,'firmwarepclxl'
    ,'firmwareps3'
    ,'firmwarepdf'
    ,'divider'
    ,'tonercianotype'
    ,'nivelciano'
    ,'numeroserieciano'
    ,'contadorciano'
    ,'capacidadetonerciano'
    ,'pagesremainingcian'
    ,'modelotonerciano'
    ,'datainstalacaocartuchociano'
    ,'divider'
    ,'tonermagentatype'
    ,'nivelmagenta'
    ,'numeroseriemagenta'
    ,'contadormagenta'
    ,'capacidadetonermagenta'
    ,'pagesremainingmagenta'
    ,'modelotonermagenta'
    ,'datainstalacaocartuchomagenta'
    ,'divider'
    ,'toneramarelotype'
    ,'nivelamarelo'
    ,'numeroserieamarelo'
    ,'contadoramarelo'
    ,'capacidadetoneramarelo'
    ,'pagesremainingyellow'
    ,'modelotoneramarelo'
    ,'datainstalacaocartuchoamarelo'
    ,'divider'
    ,'tonerpretotype'
    ,'nivelpreto'
    ,'numeroseriepreto'
    ,'contadorpreto'
    ,'capacidadetonerpreto'
    ,'pagesremainingblack'
    ,'modelotonerpreto'
    ,'datainstalacaocartuchopreto'
    ,'divider'
    ,'modelocilindrociano'
    ,'nivelcilindrociano'
    ,'contadorcilindrociano'
    ,'serialcilindrociano'
    ,'capacidadecilindrociano'
    ,'divider'
    ,'modelocilindromagenta'
    ,'nivelcilindromagenta'
    ,'contadorcilindromagenta'
    ,'serialcilindromagenta'
    ,'capacidadecilindromagenta'
    ,'divider'
    ,'modelocilindroamarelo'
    ,'nivelcilindroamarelo'
    ,'contadorcilindroamarelo'
    ,'serialcilindroamarelo'
    ,'capacidadecilindroamarelo'
    ,'divider'
    ,'modelocilindropreto'
    ,'nivelcilindropreto'
    ,'contadorcilindropreto'
    ,'serialcilindropreto'
    ,'capacidadecilindropreto'
    ,'divider'
    ,'contadorunitlaser'
    ,'capacidadelaser'
    ,'nivellaser'
    ,'contadorunitfusao'
    ,'capacidadefusao'
    ,'nivelfusor'
    ,'contadorunittransferencia'
    ,'capacidadeunidadetransferencia'
    ,'nivelunidadetransferencia'
    ,'nivelreservatoriotonerusado'
    ,'maintenancekitcurrentlevel'
    ,'maintenancekitmaxcapacity'
    ,'contadorroletegavata1'
    ,'capacidaderoletegavata1'
    ,'contadorroletegavata2'
    ,'capacidaderoletegavata2'
    ,'contadorroletegavata3'
    ,'capacidaderoletegavata3'
    ,'contadorroletegavata4'
    ,'capacidaderoletegavata4'
    ,'contadorroletebypass'
    ,'capacidaderoletebypass'
    ,'contadorroleteardf'
    ,'capacidaderoleteardf'
    ,'contadorroletereversoardf'
    ,'capacidaderoletereversoardf'
    ,'contadorreversor1'
    ,'capacidadereversor1'
    ,'contadorreversor2'
    ,'capacidadereversor2'
    ,'contadorreversorbypass'
    ,'capacidadereversorbypass'
    ,'usageinkunit'
    ,'documentfeederkit1'
    ,'divider'
    ,'contadormonoa3'
    ,'contadormonoa3especial'
    ,'contadormonoa4'
    ,'contadormonoa4especial'
    ,'contadormonob5'
    ,'contadormonoa5'
    ,'contadormonolether'
    ,'contadormonoledger'
    ,'contadormonofolio'
    ,'contadormonolegal'
    ,'contadormonostatement'
    ,'contadormonooutros'
    ,'divider'
    ,'contadormonocopia'
    ,'contadorcopiamonoa6'
    ,'contadorcopiamonoa5'
    ,'contadorCopiamonoLether'
    ,'contadorcopiamonoa4'
    ,'contadorcopiamonoa4B'
    ,'contadorcopiamonoa3'
    ,'contadorcopiamonoa3B'
    ,'contadorcopiamonoOutros'
    ,'divider'
    ,'contadormonoprinter'
    ,'contadorprintermonoa6'
    ,'contadorprintermonoa5'
    ,'contadorprintermonoLether'
    ,'contadorprintermonoa4'
    ,'contadorprintermonoa4B'
    ,'contadorprintermonoa3'
    ,'contadorprintermonoa3B'
    ,'contadorprintermonoOutros'
    ,'divider'
    ,'contadormonofax'
    ,'contadormonooutros'
    ,'divider'
    ,'contadorcolora3'
    ,'contadorcolora3Especial'
    ,'contadorcolora4'
    ,'contadorcolora4Especial'
    ,'contadorcolorB5'
    ,'contadorcolora5'
    ,'contadorcolorLether'
    ,'contadorcolorLedger'
    ,'contadorcolorFolio'
    ,'contadorcolorLegal'
    ,'contadorcolorStatement'
    ,'contadorcolorOutros'
    ,'divider'
    ,'contadorcolorcopia'
    ,'contadorcopiacolora6'
    ,'contadorcopiacolora5'
    ,'contadorcopiacolorLether'
    ,'contadorcopiacolora4'
    ,'contadorcopiacolora4B'
    ,'contadorcopiacolora3'
    ,'contadorcopiacolora3B'
    ,'contadorcopiacolorOutros'
    ,'divider'
    ,'contadorcolorprinter'
    ,'contadorprintercolora6'
    ,'contadorprintercolora5'
    ,'contadorprintercolorLether'
    ,'contadorprintercolora4'
    ,'contadorprintercolora4B'
    ,'contadorprintercolora3'
    ,'contadorprintercolora3B'
    ,'contadorprintercolorOutros'
    ,'divider'
    ,'contadorcolorfax'
    ,'contadorcoloroutros'
    ,'contadorcopiacorintegral'
    ,'contadorcopiacorunica'
    ,'contadorcopiaduascores'
    ,'contadorprintercorintegral'
    ,'contadorprintercopiacorunica'
    ,'contadorprintercopiaduascores'
    ,'faxcorunica'
    ,'divider'
    ,'contadormonoscanner'
    ,'contadorcolorscanner'
    ,'divider'
    ,'contadortotalgeral'
    ,'contadortotalscanner'
    ,'contadormonototal'
    ,'contadorcolortotal'
    ,'contadorprinttotal'
    ,'contadorcopiatotal'
    ,'contadorfaxtotal'
    ,'divider'
    ,'contadorrelatorios'
    ,'duplex'
    ,'a3dlt'
    ,'ciclosmecanismo'
    ,'ciclosmecanismocolor'
    ,'divider'
    ,'contadorcentimetros'
    ,'contadorpolegadas'
    ,'contadormetros'
    ,'divider'
    ,'Campo Calculado (Formula)' 
    ,'Campo Virtual (campo não disponivel no DB)'
    ,'divider'
    ,'personalizado1'
    ,'personalizado2'
    ,'personalizado3'
    ,'personalizado4'
    ,'personalizado5'
    ,'personalizado6'
    ,'personalizado7'
    ,'personalizado8'
    ,'personalizado9'
    ,'personalizado10'
    ,'personalizado11'
    ,'personalizado12'
    ,'personalizado13'
    ,'personalizado14'
    ,'personalizado15'
    ,'personalizado16'
    ,'personalizado17'
    ,'personalizado18'
    ,'personalizado19'
    ,'personalizado20'
    ,'texto1'
    ,'texto2'
    ,'texto3'
    ,'texto4'
    ,'texto5'
    ,'texto6'
    ,'texto7'
    ,'texto8'
    ,'texto9'
    ,'texto10'
    ,'texto11'
         ];

         const gruposOption = [
          'PARAMETROS GERAIS'
          ,'marca'
          ,'modelo'
          ,'numeroSerie'
          ,'placa'
          ,'setor'
          ,'statussistema'
          ,'statuscopiadora'
          ,'statusimpressora'
          ,'statusscanner'
          ,'sysuptime'
          ,'mensagempainel'
          ,'REDES E CONEXOES'
          ,'ethernet'
          ,'macaddress'
          ,'wireless'
          ,'macwifi'
          ,'wifimode'
          ,'ssid'
          ,'wifisecurity'
          ,'wifistatus'
          ,'smbv1v2'
          ,'portasmb'
          ,'statussmb'
          ,'MEMORIA E ARMAZENAMENTO'
          ,'memoria'
          ,'documentserver'
          ,'documentserverfree'
          ,'harddisc'
          ,'harddiscfree'
          ,'harddiscserial'
          ,'harddiscsize'
          ,'FIRMWARE'
          ,'versaofirmware'
          ,'subfirmware'
          ,'xoacommonversion'
          ,'versaoxoa'
          ,'versaoup'
          ,'versaodofirmwareengine'
          ,'versaodofirmwareui'
          ,'versaobootrom'
          ,'versaosistemafich'
          ,'versaodoscanner'
          ,'versaofax'
          ,'versaotabuleiro1'
          ,'versaotabuleiro2'
          ,'versaotabuleiro3'
          ,'versaotabuleiro4'
          ,'firmwarerede'
          ,'firmwarenib'
          ,'firmwareweb'
          ,'firmwarelanguage'
          ,'firmwarepjl'
          ,'firmwarerpcs'
          ,'firmwarepcl5e'
          ,'firmwarepclxl'
          ,'firmwareps3'
          ,'firmwarepdf'
          ,'TONER CIANO'
          ,'tonercianotype'
          ,'nivelciano'
          ,'numeroserieciano'
          ,'contadorciano'
          ,'capacidadetonerciano'
          ,'pagesremainingcian'
          ,'modelotonerciano'
          ,'datainstalacaocartuchociano'
          ,'TONVER MAGENTA'
          ,'tonermagentatype'
          ,'nivelmagenta'
          ,'numeroseriemagenta'
          ,'contadormagenta'
          ,'capacidadetonermagenta'
          ,'pagesremainingmagenta'
          ,'modelotonermagenta'
          ,'datainstalacaocartuchomagenta'
          ,'TONER AMARELO'
          ,'toneramarelotype'
          ,'nivelamarelo'
          ,'numeroserieamarelo'
          ,'contadoramarelo'
          ,'capacidadetoneramarelo'
          ,'pagesremainingyellow'
          ,'modelotoneramarelo'
          ,'datainstalacaocartuchoamarelo'
          ,'TONER PRETO'
          ,'tonerpretotype'
          ,'nivelpreto'
          ,'numeroseriepreto'
          ,'contadorpreto'
          ,'capacidadetonerpreto'
          ,'pagesremainingblack'
          ,'modelotonerpreto'
          ,'datainstalacaocartuchopreto'
          ,'CILINDRO CIANO'
          ,'modelocilindrociano'
          ,'nivelcilindrociano'
          ,'contadorcilindrociano'
          ,'serialcilindrociano'
          ,'capacidadecilindrociano'
          ,'CILINDRO MAGENTA'
          ,'modelocilindromagenta'
          ,'nivelcilindromagenta'
          ,'contadorcilindromagenta'
          ,'serialcilindromagenta'
          ,'capacidadecilindromagenta'
          ,'CILINDRO AMARELO'
          ,'modelocilindroamarelo'
          ,'nivelcilindroamarelo'
          ,'contadorcilindroamarelo'
          ,'serialcilindroamarelo'
          ,'capacidadecilindroamarelo'
          ,'CILINDRO PRETO'
          ,'modelocilindropreto'
          ,'nivelcilindropreto'
          ,'contadorcilindropreto'
          ,'serialcilindropreto'
          ,'capacidadecilindropreto'
          ,'SUPRIMENTOS E CONSUMIVEIS'
          ,'contadorunitlaser'
          ,'capacidadelaser'
          ,'nivellaser'
          ,'contadorunitfusao'
          ,'capacidadefusao'
          ,'nivelfusor'
          ,'contadorunittransferencia'
          ,'capacidadeunidadetransferencia'
          ,'nivelunidadetransferencia'
          ,'nivelreservatoriotonerusado'
          ,'maintenancekitcurrentlevel'
          ,'maintenancekitmaxcapacity'
          ,'contadorroletegavata1'
          ,'capacidaderoletegavata1'
          ,'contadorroletegavata2'
          ,'capacidaderoletegavata2'
          ,'contadorroletegavata3'
          ,'capacidaderoletegavata3'
          ,'contadorroletegavata4'
          ,'capacidaderoletegavata4'
          ,'contadorroletebypass'
          ,'capacidaderoletebypass'
          ,'contadorroleteardf'
          ,'capacidaderoleteardf'
          ,'contadorroletereversoardf'
          ,'capacidaderoletereversoardf'
          ,'contadorreversor1'
          ,'capacidadereversor1'
          ,'contadorreversor2'
          ,'capacidadereversor2'
          ,'contadorreversorbypass'
          ,'capacidadereversorbypass'
          ,'usageinkunit'
          ,'documentfeederkit1'
          ,'CONTADORES MONOCROMATICOS'
          ,'contadorMonoA3'
          ,'contadorMonoA3Especial'
          ,'contadorMonoA4'
          ,'contadorMonoA4Especial'
          ,'contadorMonoB5'
          ,'contadorMonoA5'
          ,'contadorMonoLether'
          ,'contadorMonoLedger'
          ,'contadorMonoFolio'
          ,'contadorMonoLegal'
          ,'contadorMonoStatement'
          ,'contadorMonoOutros'
          ,'CONTADORES DE COPIA MONOCROMATICO'
          ,'contadormonocopia'
          ,'contadorCopiaMonoA6'
          ,'contadorCopiaMonoA5'
          ,'contadorColiaMonoLether'
          ,'contadorCopiaMonoA4'
          ,'contadorCopiaMonoA4B'
          ,'contadorCopiaMonoA3'
          ,'contadorCopiaMonoA3B'
          ,'contadorCopiaMonoOutros'
          ,'CONTADORES PRINTER MONOCROMATICO'
          ,'contadormonoprinter'
          ,'contadorPrinterMonoA6'
          ,'contadorPrinterMonoA5'
          ,'contadorPrinterMonoLether'
          ,'contadorPrinterMonoA4'
          ,'contadorPrinterMonoA4B'
          ,'contadorPrinterMonoA3'
          ,'contadorPrinterMonoA3B'
          ,'contadorPrinterMonoOutros'
          ,'OUTROS CONTADORES MONOCROMATICOS'
          ,'contadormonofax'
          ,'contadormonooutros'
          ,'CONTADORES COLORIDOS'
          ,'contadorColorA3'
          ,'contadorColorA3Especial'
          ,'contadorColorA4'
          ,'contadorColorA4Especial'
          ,'contadorColorB5'
          ,'contadorColorA5'
          ,'contadorColorLether'
          ,'contadorColorLedger'
          ,'contadorColorFolio'
          ,'contadorColorLegal'
          ,'contadorColorStatement'
          ,'contadorColorOutros'
          ,'CONTADORES COPIA COLOR'
          ,'contadorcolorcopia'
          ,'contadorCopiaColorA6'
          ,'contadorCopiaColorA5'
          ,'contadorCopiaColorLether'
          ,'contadorCopiaColorA4'
          ,'contadorCopiaColorA4B'
          ,'contadorCopiaColorA3'
          ,'contadorCopiaColorA3B'
          ,'contadorCopiaColorOutros'
          ,'CONTADORES PRINTER COLOR'
          ,'contadorcolorprinter'
          ,'contadorPrinterColorA6'
          ,'contadorPrinterColorA5'
          ,'contadorPrinterColorLether'
          ,'contadorPrinterColorA4'
          ,'contadorPrinterColorA4B'
          ,'contadorPrinterColorA3'
          ,'contadorPrinterColorA3B'
          ,'contadorPrinterColorOutros'
          ,'OUTROS CONTADORES COLOR'
          ,'contadorcolorfax'
          ,'contadorcoloroutros'
          ,'contadorcopiacorintegral'
          ,'contadorcopiacorunica'
          ,'contadorcopiaduascores'
          ,'contadorprintercorintegral'
          ,'contadorprintercopiacorunica'
          ,'contadorprintercopiaduascores'
          ,'faxcorunica'
          ,'CONTADORES SCANNER'
          ,'contadormonoscanner'
          ,'contadorcolorscanner'
          ,'CONTADORES TOTAIS'
          ,'contadortotalgeral'
          ,'contadortotalscanner'
          ,'contadormonototal'
          ,'contadorcolortotal'
          ,'contadorprinttotal'
          ,'contadorcopiatotal'
          ,'contadorfaxtotal'
          ,'OUTROS CONTADORES DIVERSOS'
          ,'contadorrelatorios'
          ,'duplex'
          ,'a3dlt'
          ,'ciclosmecanismo'
          ,'ciclosmecanismocolor'
          ,'CONTADORES LINEARES'
          ,'contadorcentimetros'
          ,'contadorpolegadas'
          ,'contadormetros'
          ,'CAMPOS VIRTUAIS E CALCULAVEIS'
          ,'Campo Calculado (Formula)' 
          ,'Campo Virtual (campo não disponivel no DB)'
          ,'CAMPOS PERSONALIZADOS'
          ,'personalizado1'
          ,'personalizado2'
          ,'personalizado3'
          ,'personalizado4'
          ,'personalizado5'
          ,'personalizado6'
          ,'personalizado7'
          ,'personalizado8'
          ,'personalizado9'
          ,'personalizado10'
          ,'personalizado11'
          ,'personalizado12'
          ,'personalizado13'
          ,'personalizado14'
          ,'personalizado15'
          ,'personalizado16'
          ,'personalizado17'
          ,'personalizado18'
          ,'personalizado19'
          ,'personalizado20'
          ,'texto1'
          ,'texto2'
          ,'texto3'
          ,'texto4'
          ,'texto5'
          ,'texto6'
          ,'texto7'
          ,'texto8'
          ,'texto9'
          ,'texto10'
          ,'texto11'
              ];

  const refNome = React.createRef();
  const refValor = React.createRef();
  const refCalculo = React.createRef();
  const refFormula = React.createRef();
  const refAcentos = React.createRef();
  const refVirtual = React.createRef();
  const refValorVirtual = React.createRef();



  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(()=>{
    setCamposDisponiveis(inicialState);
  },[props.modelo.idmodelo])
  React.useEffect(()=>{
    if(!open){
        props.getData();
    }
  },[open])



  return (
    <div>
    
    <Button color ='primary' onClick = {handleClickOpen}><ExtensionTwoToneIcon color ='primary'/> Lançar novo parametro</Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" fullWidth maxWidth={'md'} open={open} hideBackdrop disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Cadastro de Novo Parametros (OIDs) de Coleta {props.modelo.descricao}
        </DialogTitle>
        <DialogContent dividers style={{height:"400px"}}>
          <Grid container spacing={2}>
              <Grid item xs = {4}>
                  <TextField  
                    label='Rótulo da informação'
                    fullWidth
                    variant='outlined'
                    InputLabelProps={{shrink : true}}
                    select
                    inputRef={refNome}
                    onChange={(e)=>{
                      if(e.target.value == 'statussistema'){
                        refValor.current.value = '.1.3.6.1.2.1.25.3.2.1.5.1'
                        Swal.fire({
                          title : 'Printerconnect', 
                          text : 'O parametro será preenchido com o valor padrão para o status, altere se necessário', 
                          icon:'info', 
                          showConfirmButton: true, 
                          showCancelButton:false
                        })
                      }

                      if(e.target.value == 'numeroSerie'){
                        refValor.current.value = '1.3.6.1.2.1.43.5.1.1.17.1'
                        Swal.fire({
                          title : 'Printerconnect', 
                          text : 'O parametro será preenchido com o valor padrão para o número de série, altere se necessário', 
                          icon:'info', 
                          showConfirmButton: true, 
                          showCancelButton:false
                        })
                      }

                      if(e.target.value == 'statusSistema'){
                        refValor.current.value = '.1.3.6.1.2.1.25.3.2.1.5.1'
                        Swal.fire({
                          title : 'Printerconnect', 
                          text : 'O parametro será preenchido com o valor padrão para o status, altere se necessário', 
                          icon:'info', 
                          showConfirmButton: true, 
                          showCancelButton:false
                        })
                      }


                      


                      
                    }}
                    SelectProps={{native : true}}>
                        <option value = 'F'>Selecione...</option>
                        
                        {
                            camposDisponiveis && camposDisponiveis.map(function(campo, i){
                           
                            return camposDisponiveis[i] =='divider' ? <optgroup label={gruposOption[i]}/>: <option >{camposDisponiveis[i]}</option>
                            })
                        }
                    </TextField>
              </Grid>
              <Grid item xs = {8}>
                  <TextField  
                    label='Parametro (OID) de Coleta'
                    placeholder='OID de coleta para o campo ou rotulo do campo se for Virtual ou Calculado'
                    fullWidth
                    variant='outlined'
                    inputRef={refValor}
                    InputLabelProps={{shrink : true}}
                    />
              </Grid>
          </Grid>
          <br/>
          <Grid container spacing={2}>
            <Grid item xs = {2}>
                <TextField  
                        label='Campo calculado ?'
                        disabled
                        fullWidth
                        variant='outlined'
                        inputRef={refCalculo}
                        InputLabelProps={{shrink : true}}
                        select
                        SelectProps={{native : true}}>
                            <option value='f'>NÃO</option>
                            <option value='t'>SIM</option>
                        </TextField>
            </Grid>
            <Grid item xs = {10}>
                    <TextField  
                        label='Formula do Cálculo'
                        fullWidth
                        placeholder={'Ex : $dados->dados->contadortotalgeral = $dados->dados->contadormonototal + $dados->dados->contadorcolortotal'}
                        variant='outlined'
                        inputRef={refFormula}
                        multiline
                        rows={6}
                        rowsMax={6}
                        InputLabelProps={{shrink : true}}
                        />
              </Grid>
          </Grid>
          <br/>
          <Grid container spacing={2}>
            <Grid item xs = {4}>
                <TextField  
                        label='Remover acentos do retorno ?'
                        fullWidth
                        variant='outlined'
                        inputRef={refAcentos}
                        InputLabelProps={{shrink : true}}
                        select
                        SelectProps={{native : true}}>
                            <option value='f'>NÃO</option>
                            <option value='t'>SIM</option>
                        </TextField>
            </Grid>
            <Grid item xs = {4}>
                <TextField  
                        label='Este é um campo virtual ?'
                        disabled
                        fullWidth
                        variant='outlined'
                        inputRef={refVirtual}
                        InputLabelProps={{shrink : true}}
                        select
                        SelectProps={{native : true}}>
                            <option value='f'>NÃO</option>
                            <option value='t'>SIM</option>
                        </TextField>
            </Grid>
            <Grid item xs = {4}>
                <TextField  
                        label='Valor Campo Virtual'
                        disabled={refVirtual.current && refVirtual.current.value != 't'}
                        placeholder='Valor virtual que possa ser usado em calculos'
                        fullWidth
                        variant='outlined'
                        inputRef={refValorVirtual}
                        InputLabelProps={{shrink : true}}
                       
                        />
            </Grid>
        </Grid>
        <br/>
       
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary" variant='contained'>
            Cancelar
          </Button>
          <Button autoFocus color='primary' variant='contained' onClick={()=>{
              if(refNome.current.value == 'F' || (refValor.current.value == '' && refFormula.current.value == '') || (refCalculo.current.value =='SIM' && refFormula.current.value == '')){
                Swal.fire({
                    title : 'Printercollect', 
                    text : 'Informe todos os parametros necessários !', 
                    icon : 'error', 
                    showCancelButton : false, 
                    showConfirmButton : false, 
                    timer : 1500
                  })
              }else{
              Swal.fire({
                  title : 'Printercollect', 
                  text : 'Deseja gravar as operações ?', 
                  icon : 'question', 
                  showCancelButton : true, 
                  cancelButtonText : 'Cancelar', 
                  showConfirmButton : true, 
                  confirmButtonText : 'Confirmar', 
              }).then(resposta =>{
                  if(resposta.isConfirmed){

                      Conexao.post('parametrosController/insertParametros', {
                          idmodelo : props.modelo.idmodelo, 
                          nome : refNome.current ? refNome.current.value : '', 
                          valor : refValor.current ? refValor.current.value : '', 
                          calculo : refCalculo.current ? refCalculo.current.value : '', 
                          formula : refFormula.current ? refFormula.current.value : '', 
                          acentos : refAcentos.current ? refAcentos.current.value : '', 
                          virtual : refVirtual.current ? refVirtual.current.value : '', 
                          valorvirtual : refValorVirtual.current ? refValorVirtual.current.value : ''
                      }).then(response =>{
                            if(response.data.status == 'OK'){
                                Swal.fire({
                                    title : 'Printercollect', 
                                    text : 'Parametro inserido com sucesso !', 
                                    icon : 'success', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer : 1500
                                  })
                            }else{
                                Swal.fire({
                                    title : 'Printercollect', 
                                    text : 'Não foi possível inserir', 
                                    icon : 'warning', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer : 1500
                                  })
                            }
                            setOpen(false)
                      }).catch(error =>{
                        Swal.fire({
                            title : 'Printercollect', 
                            text : 'Não Foi possível inserir', 
                            icon : 'success', 
                            showCancelButton : false, 
                            showConfirmButton : false, 
                            timer : 1500
                          })
                          setOpen(false)
                      });
                     
                     
                  }
              })
            }
          }} color="primary">
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
