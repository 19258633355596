import React, {Fragment} from 'react'
import {Typography, Divider} from '@material-ui/core'
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';



export default function(props){
    let url = window.location.host;
   
    const [dataBase, setDataBase] = React.useState(url == 'datacount.com.br' || url == 'www.datacount.com.br' ? 'Datacount' : 'printerconnect');
    return <footer id='footer-rodape'>
        <div className='footer-container-flex'>
            <div className='div-footer-logo'>
                <Typography variant='h4'>{dataBase =='Datacount' ? 'Databit Tecnologia e Sistemas LTDA' : 'A3M Soluções Digitais'}</Typography>
                <Typography variant='subtitle1'>Outsourcing de Impressão na era do IoT</Typography>
            
            <div style = {{display:"flex", marginTop:"20px"}}>
            <FacebookIcon style = {{cursor:"pointer"}}/>
            <LinkedInIcon style = {{marginLeft:"10px",cursor:"pointer"}}/>
            <InstagramIcon style = {{marginLeft:"10px",cursor:"pointer"}}/>
            <TwitterIcon style = {{marginLeft:"10px",cursor:"pointer"}}/>
                
            </div>
                
         
            </div>
            <div className='div-footer-texto'> 
            <Typography variant='h5'>Institucional</Typography>
            <Typography variant='subtitle1'>{dataBase =='Datacount' ? 'Databit Tecnologia e Sistemas LTDA.' : 'A3M Soluções Digitais'}</Typography>
            <br/>
            <Typography variant='subtitle2'>{dataBase =='Datacount' ? 'Rua Mário Campos, 197':'Narciso Ribeiro, 319'}</Typography>
            <Typography variant='subtitle2'>{dataBase =='Datacount' ? '30820-280 , Inconfidêncial':'17523230, Jardim Nacional'}</Typography>
            <Typography variant='subtitle2'>{dataBase =='Datacount' ? 'Belo Horizonte - Minas Gerais': 'Marília - São Paulo'} </Typography>

            <Typography variant='subtitle2'>{dataBase =='Datacount' ? '31 3416-8225': '14 99750-7600'}  </Typography>
            <Typography variant='subtitle2'>{dataBase =='Datacount' ? 'comercial@databit.com.br':'alinevillar_moura@hotmail.com'}</Typography>
            </div>
        </div>
    </footer>
}