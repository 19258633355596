
export class RelatorioVisaoTrocasPrematuras {

    constructor(dadosParaImpressao) {
  
      this.dadosParaImpressao = dadosParaImpressao;
      
    }  
  
    async PreparaDocumento() {
      return new Promise((resolve, reject) => {
        try {
          const corpoDocumento = this.CriaCorpoDocumento();
          const documento = this.GerarDocumento(corpoDocumento, this.dadosParaImpressao.title);
          resolve(documento);
        } catch (error) {
          console.log(error)
          reject(error);
        }
      });
    }
  
    CriaCorpoDocumento() {
  
      const header = [   
        { text: 'Data Troca', bold: true, fontSize: 6, alignment : 'center', color: '#fff', style :'lineBlack'},
        { text: 'Cliente', bold: true, fontSize: 6, alignment : 'left', color: '#fff', style :'lineBlack'},
        { text: 'Equipamento', bold: true, fontSize: 6, alignment : 'left', color: '#fff', style :'lineBlack'},
        { text: 'Serial', bold: true, fontSize: 7, alignment : 'left', color: '#fff', style :'lineBlack' },
        { text: 'COR', bold: true, fontSize: 6, alignment : 'center', color: '#fff', style :'lineBlack'},
        { text: 'Nível', bold: true, fontSize: 6, alignment : 'center', color: '#fff', style :'lineBlack'},
        { text: 'Serial', bold: true, fontSize: 6, alignment : 'center', color: '#fff', style :'lineBlack'},
        { text: 'Mono', bold: true, fontSize: 6, alignment : 'center', color: '#fff', style :'lineBlack'},
        { text: 'Color', bold: true, fontSize: 6, alignment : 'center', color: '#fff', style :'lineBlack'},
        { text: 'Total', bold: true, fontSize: 6, alignment : 'center', color: '#fff', style :'lineBlack'},
        { text: 'Nivel', bold: true, fontSize: 6, alignment : 'center', color: '#fff', style :'lineBlack'},
        { text: 'Serial', bold: true, fontSize: 6, alignment : 'center', color: '#fff', style :'lineBlack'},
        { text: 'Mono', bold: true, fontSize: 6, alignment : 'center', color: '#fff', style :'lineBlack'},
        { text: 'Color', bold: true, fontSize: 6, alignment : 'center', color: '#fff', style :'lineBlack'},
        { text: 'Total', bold: true, fontSize: 6, alignment : 'center', color: '#fff', style :'lineBlack'},
      ];
      const body = this.dadosParaImpressao.registros.map((coleta, index) => {
         const corLinha = index % 2 !== 0 ? 'lineSilver' :'lineWhite';

        return [
          { text: coleta.datalog.substr(0,10), fontSize: 6, alignment : 'center', style:corLinha },          
          { text: coleta.razaosocial.length > 30 ? coleta.razaosocial.substr(0,30)+'...':coleta.razaosocial , fontSize: 6, alignment : 'left', style:corLinha },
          { text: coleta.modelo.length > 25 ? coleta.modelo.substr(0,25)+'...':coleta.modelo , fontSize: 6, alignment : 'left', style:corLinha },
          { text: coleta.numeroserie  , fontSize: 6, alignment : 'left', style:corLinha },
          { text: coleta.cor.length > 8 ? coleta.cor.substr(0,8)+'...':coleta.cor , fontSize: 6, alignment : 'center', style:corLinha },
          { text: coleta.nivel+'%', fontSize: 6, alignment : 'center', style:corLinha },
          { text: coleta.serialtoneranterior, fontSize: 6, alignment : 'center' , style:corLinha},
          { text: coleta.contadormonototal, fontSize: 6, alignment : 'center' , style:corLinha},
          { text: coleta.contadorcolortotal, fontSize: 6, alignment : 'center' , style:corLinha},
          { text: coleta.contadortotalgeral, fontSize: 6, alignment : 'center' , style:corLinha},
          { text: coleta.nivelnovo+'%', fontSize: 6, alignment : 'center', style:corLinha },
          { text: coleta.serialtonernovo, fontSize: 6, alignment : 'center' , style:corLinha},
          { text: coleta.novocontadormonototal, fontSize: 6, alignment : 'center' , style:corLinha},
          { text: coleta.novocontadorcolortotal, fontSize: 6, alignment : 'center' , style:corLinha},
          { text: coleta.novocontadortotalgeral, fontSize: 6, alignment : 'center' , style:corLinha},
        ];
      });
     
  
      
  
      let content = [header];
    
      
      content = [...content, ...body];
      return content;
    }
    
  
    GerarDocumento(corpoDocumento, title) {
    console.log(corpoDocumento);
      const documento = { 
        pageSize: 'A4',
        pageMargins: [15, 53, 15, 48],
        header: function () {
          return {
              margin: [14, 12, 14, 0],
              layout: 'noBorders',
              table: {
                widths: ['*'],
                body: [    
                    
                  [
                    { text: 'Relatório de Trocas de Suprimento', style: 'reportName' }
                  ] ,                         
                  [
                    { text: 'Cliente : ' + title, style: 'razaoSocial' }
                  ],
                  [
                    { text: 'Data Relatório : ', style: 'razaoSocial' }
                  ],
                  [
                    {
                      text:
                      '________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                      alignment: 'center',
                      fontSize: 5,
                    },
                  ]   
                               
                ],
              },
            };
        },
        pageOrientation: 'landscape',
      content: [
        {
              layout: 'noBorders',
              table: {              
                headerRows: 1,
                widths: [45,  110, 90, 70, 35, 25, 70, 26,26,26,26, 75, 26,26,26],
                body: corpoDocumento
              }
            },
      ],
      
      footer(currentPage, pageCount) {
            return {
              layout: 'noBorders',
              margin: [14, 0, 14, 22],
              table: {
                widths: ['auto'],
                body: [
                  [
                    {
                      text:
                      '________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                      alignment: 'center',
                      fontSize: 5,
                    },
                  ],
                  [
                    [
                      {
                        text: `Página ${currentPage.toString()} de ${pageCount}`,
                        fontSize: 7,
                        alignment: 'right',
                        /* horizontal, vertical */
                        margin: [3, 0],
                      },
                      {
                        text: '© Datacount collector',
                        fontSize: 7,
                        alignment: 'center',
                      },
                    ],
                  ],
                ],
              },
            };
          },
      styles: {
        razaoSocial: {
          fontSize: 9,
          bold: true,
          alignment: 'left',
          margin: [0, 4, 0, 0],
        
        },
        reportName: {
            fontSize: 9,
            bold: true,
            alignment: 'center',
            margin: [0, 4, 0, 0],
          
          }, 
        lineWhite: {
          fillColor: '#fff', // Header row color
    
        },
        lineSilver: {
          fillColor: '#d9d9d9', // Odd row color
        },
        lineBlack : {
            fillColor: '#444', // Odd row color
        },
        corLinhaheader : {
            color: '#fff', 
            
        }
      },
  
    };
      return documento;
    }
  }
  