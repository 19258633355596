import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import BarChartIcon from '@material-ui/icons/BarChart';
import {TextField, Grid} from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';
import Swal from 'sweetalert2'
import Alert from '@material-ui/lab/Alert';
import Conexao from '../../providers/Conexao'
import DisplayToner from '../../components/DisplayToner'



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalMonitorIndividual(props) {
  const [open, setOpen] = React.useState(false);
  const [statusCiano, setStatusCiano]= React.useState('f');
  const [nivelCiano, setNivelCiano]= React.useState(0);
  const [statusMagenta, setStatusMagenta]= React.useState('f');
  const [nivelMagenta, setNivelMagenta]= React.useState(0);
  const [statusAmarelo, setStatusAmarelo]= React.useState('f');
  const [nivelAmarelo, setNivelAmarelo]= React.useState(0);
  const [statusPreto, setStatusPreto]= React.useState('f');
  const [nivelPreto, setNivelPreto]= React.useState(0);


  const handleClickOpen = () => {
    if(props.coleta.monitorartoner =='f'){
      Swal.fire({
        title : 'Datacount', 
        text : 'Você precisa habilitar o monitoramento de suprimentos na licença de coleta deste cliente antes de continuar!', 
        icon : 'warning', 
        showConfirmButton :true, 
        confirmButtonText :'OK', 
        showCancelButton : false
      })
    }else{
      setOpen(true);
    }
    
    
  };

  React.useEffect(()=>{
    if(open){
        
        setStatusCiano(props.coleta.alertaciano);
        setStatusMagenta(props.coleta.alertamagenta);
        setStatusAmarelo(props.coleta.alertaamarelo);
        setStatusPreto(props.coleta.alertapreto);
    
        setNivelCiano(props.coleta.nivelalertaciano);
        setNivelMagenta(props.coleta.nivelalertamagenta);
        setNivelAmarelo(props.coleta.nivelalertaamarelo);
        setNivelPreto(props.coleta.nivelalertapreto);
    }
  }, [open]);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
 
      <Button disabled={props.disabled} fullWidth onClick={handleClickOpen}  style={{height :'80px', display:'flex'}} variant='contained'>
        <div style ={{width : '20%'}}><BarChartIcon style={{fontSize : '62px', color:"#444"}}/></div>
        <div style ={{width : '80%'}}>Monitorar Suprimentos</div>
      </Button>
      <Dialog  onClose={handleClose} aria-labelledby="customized-dialog-title" hideBackdrop open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Habilitar : {props.coleta.modelo+' ('+props.coleta.numeroserie+')'}
        </DialogTitle>
        <DialogContent dividers >
            <Typography variant='caption' color ='primary'>* Habilitar monitoramento de suprimento personalizado</Typography>
            <br/>
            <Grid container spacing={1} style ={{border:"solid 1px #ccc", borderRadius:"5px", marginTop :'10px'}}>
                <Grid item xs = {2}>
                  <DisplayToner 
                    percentual = {props.coleta.nivelciano ? props.coleta.nivelciano : 0} 
                    height ={'62px'}
                    width ={'62px'}
                    noTop={true}
                    marginTop={'-2px'}
                    rotulo = 'ciano' color='#1976D2' 
                    descricao={props.coleta.numeroserieciano} 
                    contador = {props.coleta.contadorciano}></DisplayToner>
                </Grid>
                <Grid item xs = {6}>
                <Typography variant='caption'>* Monitorar Ciano.</Typography>
                {statusCiano =='t' ? <Button color="primary" fullWidth variant='contained' onClick={()=>setStatusCiano('f')}>Habilitado</Button> : 
                <Button color="secondary" disabled={props.coleta.nivelciano == null } fullWidth variant='contained' onClick={()=>setStatusCiano('t')}>DESABILITADO</Button>}
                </Grid>
                <Grid item xs = {4} style ={{paddingTop:"22px"}}>
                    <TextField
                        label='Nível'
                        fullWidth
                        disabled={props.coleta.nivelciano == null || props.coleta.monitorartoner =='f'}
                        InputLabelProps={{shrink:true}}
                        variant='outlined'                        
                        value={nivelCiano}
                        size='large'
                        onChange={(e)=>setNivelCiano(e.target.value)}
                        type = 'number'/>
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing={1} style ={{border:"solid 1px #ccc", borderRadius:"5px"}}>
            <Grid item xs = {2}>
                  <DisplayToner 
                    percentual = {props.coleta.nivelmagenta ? props.coleta.nivelmagenta : 0} 
                    height ={'62px'}
                    width ={'62px'}
                    noTop={true}
                    marginTop={'-2px'}
                    rotulo = 'magenta' color='#C62828' 
                    descricao={props.coleta.numeroseriemagenta} 
                    contador = {props.coleta.contadormagenta}></DisplayToner>
                </Grid>
                <Grid item xs = {6}>
                <Typography variant='caption'>* Monitorar Magenta.</Typography>
                {statusMagenta =='t' ? <Button color="primary" fullWidth variant='contained' onClick={()=>setStatusMagenta('f')}>Habilitado</Button> : 
                <Button color="secondary" disabled={props.coleta.nivelmagenta == null } fullWidth variant='contained' onClick={()=>setStatusMagenta('t')}>DESABILITADO</Button>}
                </Grid>
                <Grid item xs = {4} style ={{paddingTop:"22px"}}>
                    <TextField
                        label='Nível'
                        fullWidth
                        InputLabelProps={{shrink:true}}
                        variant='outlined'                        
                        disabled={props.coleta.nivelmagenta == null || props.coleta.monitorartoner =='f'}
                        value={nivelMagenta}
                        size='large'
                        onChange={(e)=>setNivelMagenta(e.target.value)}
                        type = 'number'/>
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing={1} style ={{border:"solid 1px #ccc", borderRadius:"5px"}}>
              <Grid item xs = {2}>
                    <DisplayToner 
                      percentual = {props.coleta.nivelamarelo ? props.coleta.nivelamarelo : 0} 
                      height ={'62px'}
                      width ={'62px'}
                      noTop={true}
                      marginTop={'-2px'}
                      rotulo = 'amarelo' color='#FBC02D' 
                      descricao={props.coleta.numeroserieamarelo} 
                      contador = {props.coleta.contadoramarelo}></DisplayToner>
                </Grid>
                <Grid item xs = {6}>
                <Typography variant='caption'>* Monitorar Amarelo.</Typography>
                {statusAmarelo =='t' ? <Button color="primary" fullWidth variant='contained' onClick={()=>setStatusAmarelo('f')}>Habilitado</Button> : 
                <Button color="secondary" disabled={props.coleta.nivelamarelo == null } fullWidth variant='contained' onClick={()=>setStatusAmarelo('t')}>DESABILITADO</Button>}
                </Grid>
                <Grid item xs = {4} style ={{paddingTop:"22px"}}>
                    <TextField
                        label='Nível'
                        fullWidth
                        InputLabelProps={{shrink:true}}
                        variant='outlined'                        
                        value={nivelAmarelo}
                        disabled={props.coleta.nivelamarelo == null || props.coleta.monitorartoner =='f'}
                        size='large'
                        onChange={(e)=>setNivelAmarelo(e.target.value)}
                        type = 'number'/>
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing={1} style ={{border:"solid 1px #ccc", borderRadius:"5px"}}>
              <Grid item xs = {2}>
                    <DisplayToner 
                      percentual = {props.coleta.nivelpreto ? props.coleta.nivelpreto : 0} 
                      height ={'62px'}
                      width ={'62px'}
                      noTop={true}
                      marginTop={'-2px'}
                      rotulo = 'preto' color='#000' 
                      descricao={props.coleta.numeroseriepreto} 
                      contador = {props.coleta.contadorpreto}></DisplayToner>
                </Grid>
                <Grid item xs = {6}>
                <Typography variant='caption'>* Monitorar Preto.</Typography>
                {statusPreto =='t' ? <Button color="primary" fullWidth variant='contained' onClick={()=>setStatusPreto('f')}>Habilitado</Button> : 
                <Button color="secondary" disabled={props.coleta.nivelpreto == null } fullWidth variant='contained' onClick={()=>setStatusPreto('t')}>DESABILITADO</Button>}
                    
                </Grid>
                <Grid item xs = {4} style ={{paddingTop:"22px"}}>
                    <TextField
                        label='Nível'
                        fullWidth
                        InputLabelProps={{shrink:true}}
                        variant='outlined'                        
                        disabled={props.coleta.nivelpreto == null || props.coleta.monitorartoner =='f'}
                        value={nivelPreto}
                        size='large'
                        onChange={(e)=>setNivelPreto(e.target.value)}
                        type = 'number'/>
                </Grid>
                
            </Grid>
            <div style={{marginTop:"10px", paddingBottom:"10px"}}>
            {
                props.children
            }
            </div>
            <Alert severity="warning" >Habilitando o monitoramento individual (por equipamento) será sobrescrito as configurações globais para este equipamento !</Alert>
        </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={handleClose} color="secondary" variant='contained'>
                cancelar
            </Button>
            <Button autoFocus onClick={handleClose} color="primary" variant='contained' onClick ={()=>{
                Swal.fire({
                    title :'Datacount', 
                    text :'Deseja gravar as alterações ?', 
                    icon :'question', 
                    showCancelButton  : true, cancelButtonText : 'Cancelar', 
                    showConfirmButton : true, confirmButtonText : 'Confirmar',                     
                }).then(resposta=>{
                    if(resposta.isConfirmed){
                        
                        Conexao.post('coletasController/habilitarMonitoramentoSuprimentos', {
                            idEmpresa : props.coleta.idempresa, 
                            idCliente : props.coleta.idcliente, 
                            idColeta : props.coleta.idcoleta,
                            alertaCiano : statusCiano ?  statusCiano : 'f', 
                            nivelCiano : nivelCiano ? nivelCiano  : 0, 
                            alertaMagenta : statusMagenta ? statusMagenta : 'f', 
                            nivelMagenta : nivelMagenta ? nivelMagenta : 0, 
                            alertaAmarelo: statusAmarelo ? statusAmarelo : 'f', 
                            nivelAmarelo : nivelAmarelo ? nivelAmarelo : 0, 
                            alertaPreto : statusPreto ? statusPreto : 'f', 
                            nivelPreto : nivelPreto ? nivelPreto : 0
                        }).then(response=>{
                            if(response.data.status =='OK'){
                               const tempColeta = props.coleta;
                               tempColeta.alertaciano = statusCiano ?  statusCiano : 'f'; 
                               tempColeta.nivelalertaciano = nivelCiano ? nivelCiano  : 0; 
                               tempColeta.alertamagenta = statusMagenta ? statusMagenta : 'f'; 
                               tempColeta.nivelalertaamarelo = nivelMagenta ? nivelMagenta : 0; 
                               tempColeta.alertaamarelo = statusAmarelo ? statusAmarelo : 'f'; 
                               tempColeta.nivelalertaamarelo = nivelAmarelo ? nivelAmarelo : 0; 
                               tempColeta.alertapreto = statusPreto ? statusPreto : 'f'; 
                               tempColeta.nivelalertapreto = nivelPreto ? nivelPreto : 0;
                               props.updateColeta(tempColeta);
                                Swal.fire({
                                    title :'Datacount', 
                                    text :'Registro alterado com sucesso !',
                                    icon:'success', 
                                    showConfirmButton : false, 
                                    showCancelButton:false, 
                                    timer : 1500
                                })
                            }else{
                                Swal.fire({
                                    title :'Datacount', 
                                    text :'Não foi possível alterar !',
                                    icon:'warning', 
                                    showConfirmButton : false, 
                                    showCancelButton:false, 
                                    timer : 1500
                                })
                            }
                            setOpen(false)
                        }).catch(error=>{
                            Swal.fire({
                                title :'Datacount', 
                                text :'Não foi possível alterar !',
                                icon:'error', 
                                showConfirmButton : false, 
                                showCancelButton:false, 
                                timer : 1500
                            })
                        })
                    }
                });
            }}>
                Gravar
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
