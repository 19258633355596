import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
//import Conexao from '../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Grid, TextField, Tooltip} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'
import ModalEnderecosColetasDca from './ModalEnderecosColetasDca';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalPadrao = (props) => {
  
    const [open, setOpen] = React.useState(false)
   const [loading, setLoading]= React.useState(false)
    const [comunidades, setComunidades] = useState('public')
    const [intervaloColeta, setIntervaloColeta] = useState(1)
    const [timeOutRede, setTimeOutRede]= useState(300)
    const [timeOutWeb, setTimeOutWeb]= useState( 15)
    const [timeOutSnmp, setTimeOutSnmp]= useState(  300)
    const [monitoramentoRedes, setMonitoramentoRedes]= useState(  'f')
    const [enderecosColeta, setEnderecosColeta] = useState('')
 
  
    useEffect(()=>{
      setComunidades(props.parametrosDCA.comunidades)
      setIntervaloColeta(props.parametrosDCA.intervalocoleta)
      setTimeOutRede(props.parametrosDCA.timeoutrede)
      setTimeOutWeb(props.parametrosDCA.timeoutweb)
      setTimeOutSnmp(props.parametrosDCA.timeoutsnmp)
      setMonitoramentoRedes(props.parametrosDCA.monitarredesconectadas)
      setEnderecosColeta(props.parametrosDCA.enderecoscoleta)
    }, [props.parametrosDCA])

    const handleClickOpen = () =>{
      let versaoDCA = props.parametrosDCA.dcaversao.split('-')

      console.log(versaoDCA)

      if(versaoDCA.length < 2){
        Swal.fire({
          title : 'Datacount', 
          text : 'Essa versão de DCA não suporta configuração remota!', 
          showConfirmButton : false, 
          confirmButtonText : 'OK', 
          showCancelButton : false, 
          cancelButtonText : 'NÃO', 
          icon:'error', 
          timer : 2500
        })
        return
      }else if(versaoDCA[versaoDCA.length - 1] !== 'B'){
        Swal.fire({
          title : 'Datacount', 
          text : 'Essa versão de DCA não suporta configuração remota!', 
          showConfirmButton : false, 
          confirmButtonText : 'OK', 
          showCancelButton : false, 
          cancelButtonText : 'NÃO', 
          icon:'error', 
          timer : 2500
        })
        return
      }




        if(props.disabled){
          Swal.fire({
            title : 'Datacount', 
            text : 'Você não tem permissão para editar os parametros do DCA', 
            showConfirmButton : false, 
            confirmButtonText : 'OK', 
            showCancelButton : false, 
            cancelButtonText : 'NÃO', 
            icon:'error', 
            timer : 2500
          })
          return
        }
        setOpen(true)
        console.log(props.parametrosDCA)
    }

    const handleClose = () =>{
        setOpen(false)
    }
  return (
    <div>
      <Tooltip title='Cadastrar um novo veículo'>
         
        <SettingsApplicationsIcon onClick={handleClickOpen}/>
         
        
      </Tooltip>
      <Dialog 
        disableBackdropClick disableEscapeKeyDown
        hideBackdrop
        fullWidth
        maxWidth='md'
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
         Configuração dos Parametros DCA
        </DialogTitle>
        <DialogContent dividers>
          
            <Grid container spacing={2}>
                <Grid item xs ={4}>
                    <Typography variant='caption' style ={{fontSize : '16px'}}>Chave Instalação</Typography>
                </Grid>
                <Grid item xs ={8}>
                    <TextField
                        value={props.parametrosDCA.chave}
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink:true}}
                        size='small'/>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs ={4}>
                    <Typography variant='caption' style ={{fontSize : '16px'}}>Razão Social</Typography>
                </Grid>
                <Grid item xs ={8}>
                    <TextField
                        value={props.parametrosDCA.razaosocial}
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink:true}}
                        size='small'/>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs ={4}>
                    <Typography variant='caption' style ={{fontSize : '16px'}}>Comunidades</Typography>
                </Grid>
                <Grid item xs ={8}>
                    <TextField
                        value={comunidades}
                        multiline
                        rowsMax={3}
                        rows={3}
                        variant='outlined'
                        fullWidth
                        onChange={(e)=>setComunidades(e.target.value)}
                        InputLabelProps={{shrink:true}}
                        size='small'/>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs ={4}>
                    <Typography variant='caption' style ={{fontSize : '16px'}}>Intervalo de coleta</Typography>
                </Grid>
                <Grid item xs ={2}>
                    <TextField
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink:true}}
                        select
                        SelectProps={{native:true}}
                        onChange={(e)=>setIntervaloColeta(e.target.value)}
                        size='small'>
                            <option value={intervaloColeta}>{intervaloColeta / 60} hora(s)</option>
                            <option value={60}> 1 hora(s)</option>
                            <option value={120}>2 hora(s)</option>
                            <option value={180}>3 hora(s)</option>
                            <option value={240}>4 hora(s)</option>
                            <option value={300}>5 hora(s)</option>
                            <option value={360}>6 hora(s)</option>
                    </TextField>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs ={4}>
                    <Typography variant='caption' style ={{fontSize : '16px'}}>Timeout de Rede</Typography>
                </Grid>
                <Grid item xs ={2}>
                    <TextField
                        value={timeOutRede}
                        multiline
                        variant='outlined'
                        fullWidth
                        type="number"                        
                        onChange={(e) => {
                          const newValue = e.target.value;
                          // Verificar se a nova entrada é numérica e não excede 3 caracteres
                          if (/^[0-9]*$/.test(newValue) && newValue.length <= 3) {
                            setTimeOutRede(newValue);
                          }
                        }}                       
                        InputLabelProps={{shrink:true}}
                        size='small'/>
                </Grid>
                <Grid item xs ={4}>
                    <Typography variant='caption' style ={{fontSize : '16px'}}>milissegundos</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs ={4}>
                    <Typography variant='caption' style ={{fontSize : '16px'}}>Timeout Web</Typography>
                </Grid>
                <Grid item xs ={2}>
                    <TextField
                        value={timeOutWeb}
                        multiline
                        variant='outlined'
                        fullWidth
                        onChange={(e) => {
                          const newValue = e.target.value;
                          // Verificar se a nova entrada é numérica e não excede 3 caracteres
                          if (/^[0-9]*$/.test(newValue) && newValue.length <= 3) {
                            setTimeOutWeb(newValue);
                          }
                        }}   
                        InputLabelProps={{shrink:true}}
                        size='small'/>
                </Grid>
                <Grid item xs ={4}>
                    <Typography variant='caption' style ={{fontSize : '16px'}}>milissegundos</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs ={4}>
                    <Typography variant='caption' style ={{fontSize : '16px'}}>Timeout SNMP</Typography>
                </Grid>
                <Grid item xs ={2}>
                    <TextField
                        value={timeOutSnmp}
                        multiline
                        variant='outlined'
                        fullWidth
                        onChange={(e) => {
                          const newValue = e.target.value;
                          // Verificar se a nova entrada é numérica e não excede 3 caracteres
                          if (/^[0-9]*$/.test(newValue) && newValue.length <= 3) {
                            setTimeOutSnmp(newValue);
                          }
                        }}   
                        InputLabelProps={{shrink:true}}
                        size='small'/>
                </Grid>
                <Grid item xs ={4}>
                    <Typography variant='caption' style ={{fontSize : '16px'}}>milissegundos</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs ={4}>
                    <Typography variant='caption' style ={{fontSize : '16px'}}>Monitoras todas as conexões</Typography>
                </Grid>
                <Grid item xs ={2}>
                    <TextField
                        
                        multiline
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink:true}}
                        select
                        SelectProps={{native:true}}
                        onChange={(e)=>setMonitoramentoRedes(e.target.value)}
                        size='small'>
                            <option value={monitoramentoRedes}>{monitoramentoRedes =='f' ? 'Não' : 'Sim'}</option>
                            <option value='f'>Não</option>
                            <option value='t'>Sim</option>
                    </TextField>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs ={4}>
                    <Typography variant='caption' style ={{fontSize : '16px'}}>Faixa de Endereços</Typography>
                </Grid>
                <Grid item xs ={4}>
                    <ModalEnderecosColetasDca 
                      enderecosColeta = {enderecosColeta}
                      setEnderecosColeta = {(value)=>{
                        setEnderecosColeta(value)
                        
                      }}                    
                      />
                </Grid>
            </Grid>
            
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} disabled={loading} variant='contained' color="secondary">
            Cancelar
          </Button>
          <Button autoFocus variant='contained'  color="primary" disabled={loading} onClick ={()=>{
            if(timeOutWeb === '' || timeOutRede === '' || timeOutSnmp === '' || comunidades === '' || enderecosColeta === ''){
              Swal.fire({
                title : 'Datacount', 
                text : 'Informe corretamente todos os parametros!!',
                icon : 'error', 
                showConfirmButton:false, 
                confirmButtonText : 'Sim', 
                showCancelButton: false,
                cancelButtonText:'Não', 
                timer : 2000
              })

              return;
            }


            Swal.fire({
              title : 'Datacount', 
              text : 'Deseja realmente gravar as alterações feitas nas configurações de coleta?',
              icon : 'question', 
              showConfirmButton:true, 
              confirmButtonText : 'Sim', 
              showCancelButton: true,
              cancelButtonText:'Não'
            }).then(resposta =>{
              if(resposta.isConfirmed){
                if(props.Conexao){
                  setLoading(true)
                  props.Conexao.post('dcaController/updateConfigDca', {                    
                      idusuario : props.parametrosDCA.idusuario, 
                      idempresa : props.parametrosDCA.idempresa, 
                      idcliente : props.parametrosDCA.idcliente,
                      chave : props.parametrosDCA.chave, 
                      monitarredesconectadas : monitoramentoRedes, 
                      timeoutweb : timeOutWeb,
                      timeoutrede : timeOutRede, 
                      timeoutsnmp : timeOutSnmp, 
                      tentativas : 2 , 
                      comunidades : comunidades, 
                      intervalocoleta :intervaloColeta  , 
                      enderecoscoleta : enderecosColeta
 
                  }).then(response=>{
                      if(response.status == 200 && response.data.status =='OK'){
                        Swal.fire({
                          title : 'Datacount', 
                          text : 'Alterações realizadas com sucesso!',
                          icon : 'success',
                          timer : 2000, 
                          showConfirmButton:false, 
                          confirmButtonText : 'Sim', 
                          showCancelButton: false,
                          cancelButtonText:'Não'
                        }) 
                        setOpen(false)
                      }else{
                        Swal.fire({
                          title : 'Datacount', 
                          text : 'Não foi possível concluir as alterações!',
                          icon : 'warning',
                          timer : 2000, 
                          showConfirmButton:false, 
                          confirmButtonText : 'Sim', 
                          showCancelButton: false,
                          cancelButtonText:'Não'
                        }) 
                      }
                      setLoading(false)
                  }).catch(error=>{
                    Swal.fire({
                      title : 'Datacount', 
                      text : 'Não foi possível concluir as alterações!',
                      icon : 'error',
                      timer : 2000, 
                      showConfirmButton:false, 
                      confirmButtonText : 'Sim', 
                      showCancelButton: false,
                      cancelButtonText:'Não'
                    }) 
                    setLoading(false)
                  })

                
                }
              }
            })
          }}>
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalPadrao)