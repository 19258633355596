import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'

import {withRouter} from 'react-router-dom'
import Principal from '../Template/TemplateRevenda';
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import RemoveIcon from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PersonIcon from '@material-ui/icons/Person';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import Build from '@material-ui/icons/Build'
import ComputerOutlined from '@material-ui/icons/ComputerRounded';
import ConexaoInventory from  '../../providers/ConexaoInventory'
import iconePlacaMae2 from './imagens/iconePlacaMae2.png'
import IconeProcessador from './imagens/iconeProcessador.png'
import IconeMemoria from './imagens/iconeMemoria.png'
import IconeHDD from './imagens/iconeHDD.png'
import IconeVideo from './imagens/iconePlacaVideo.png'
import IconeMonitor from './imagens/iconeMonitor.png'
import IconeRede from './imagens/inconePlacaRede.png'
import IconeUSB from './imagens/iconeUSB.png'
import IconeTeclado from './imagens/iconeTeclado.png'
import IconeMousefrom  from './imagens/iconeMouse.png'

const DataInventoryDetails = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [colunaFiltro, setColunaFiltro] = useState('Cliente')
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [filtro, setFiltro] = useState([])
    const [dataTecnico, setDataTecnico] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dcas, setDcas] = useState([])
    const [dcasFiltro, setDcasFiltro] = useState([])
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [ registros, setRegistros] = useState(0)
    const refTipoDataPesquisa = React.createRef();
    const refDataPesquisa = React.createRef();
    const refChaveInstalacao = React.createRef();
    const refRazaoSocial = React.createRef();
    const [inventory, setInventory]= useState([])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

  

    useEffect(()=>{
        getOne();
    }, [])


    const  getOne = () =>{
        setLoading(true)
        ConexaoInventory.get('InventoryController/getOne?id='+props.location.state.inventory._id).then( response =>{
            if(response.data.status == 'OK'){
                setInventory(response.data.payload[0])
            }else{
             
                setInventory([])
            }
            setLoading(false)
        }).catch( error =>{
       
            setInventory([])
            setLoading(false)
        });
    }

   

    const renderUltimoAcesso = (acesso) =>{
        if(acesso == 'printerconnect-app-collector'){
            return <PersonIcon color='primary' />
        }else{
            return <SettingsApplicationsIcon  color='primary'/>
        }
    }

    return <Principal atualizar = {loading} getData={getOne} breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">  
        <Typography>Você esta em:</Typography>          
            <Link color="inherit" to={'#'} >
                Inventory Detalhes
            </Link>                
        </Breadcrumbs>
    }>
        {inventory ?  <div style={{paddingLeft : '10px', paddingTop : '5px', paddingBottom : '5px',marginTop : '10px', }}>
           
           <div style={{display:'flex', }} id='div-display-inventory'>
                <div style={{width : '250px', textAlign:'center', paddingLeft : '10px', paddingRight : '10px'}}>
                    <Typography variant='subtitle1' style ={{color:"#263238!important"}}><strong>{props.location.state.inventory.computador.Modelo}</strong></Typography>
                    <ComputerOutlined className='computadorLigado' style={{width : '150px', height : '150px', color: '#444'}} />
                    <Button variant='contained' color='secondary' fullWidth onClick={()=>{
                        props.history.push({pathname : 'CadastroComputadoresInventory', state : {
                            empresa : props.location.state.empresa,
                            usuario : props.location.state.usuario,   

                          }})
                    }}>
                        <Typography variant='caption'>Voltar</Typography>
                    </Button>
                </div>
                <div style ={{width : '100%'}}>
                    
                
                <Typography variant='caption'>Fabricante: <strong>{props.location.state.inventory.computador.Marca}</strong></Typography><br/>
                <Typography variant='caption'>SO: <strong>{props.location.state.inventory.SistemaOperacional.sistemaOperacional +" ( "+props.location.state.inventory.SistemaOperacional.Plataforma+" )"}</strong></Typography><br/>
                <Typography variant='caption'>Arquitetura: <strong>{props.location.state.inventory.SistemaOperacional.arquitetura[0] }</strong></Typography><br/>
                <Typography variant='caption'>Build: <strong>{props.location.state.inventory.SistemaOperacional.build }</strong></Typography><br/>
                <a href ={'https://maps.google.com/?q='+inventory.latitude+','+inventory.longitude} target='_blank'><Typography variant='caption'>Localização: <strong>{inventory.latitude+','+inventory.longitude}</strong></Typography></a><br/>
               
              
         
                    {inventory.placaMae ? <div style={{display : 'flex', marginTop : '10px', backgroundColor : '#fff', padding : '10px', borderRadius : '5px', }}>
                        <div style={{width : '100px', textAlign:'center'}}>
                            <img src={iconePlacaMae2} width={'60px'}/>
                        </div>
                        <div style ={{width : '100%'}}>
    
                            <Grid container spacing={2}>
                           
                                <Grid item xs={1} >
                                    <Typography variant='caption'>Fabricante:</Typography>
                                    <Typography variant='subtitle2'><strong>{inventory.placaMae.fabricante}</strong></Typography>
                                </Grid> 

                                 <Grid item xs={2} >
                                    <Typography variant='caption'>Modelo:</Typography>
                                    <Typography variant='subtitle2'><strong>{inventory.placaMae.modelo}</strong></Typography>
                                </Grid> 

                                <Grid item xs={3} >
                                    <Typography variant='caption'>Número de Série:</Typography>
                                    <Typography variant='subtitle2'><strong>{inventory.placaMae.serial}</strong></Typography>
                                </Grid> 
                                <Grid item xs={2} >
                                    <Typography variant='caption'>Firmware:</Typography>
                                    <Typography variant='subtitle2'><strong>{inventory.firmware.fabricante}</strong></Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <Typography variant='caption'>Versão:</Typography>
                                    <Typography variant='subtitle2'><strong>{inventory.firmware.versao}</strong></Typography>
                                </Grid>   
                            </Grid>   

                             <Grid container spacing={2}>
                           
                                <Grid item xs={2} >
                                    <Typography variant='caption'>Bios:</Typography>
                                    <Typography variant='subtitle2'><strong>{inventory.bios.fabricante}</strong></Typography>
                                </Grid>
                                <Grid item xs={4} >
                                    <Typography variant='caption'>Versão:</Typography>
                                    <Typography variant='subtitle2'><strong>{inventory.bios.versao}</strong></Typography>
                                </Grid>

                            </Grid>                         
                            
                           
                           
                        </div>
                    </div> : null }
                    <Divider/>
                    {
                        inventory.processadores ? inventory.processadores.map(function(item, i){
                            return <div style={{display : 'flex', marginTop : '10px', backgroundColor : '#fff', padding : '10px', borderRadius : '5px', }}>
                            <div style={{width : '100px', textAlign:'center'}}>
                                <img src={IconeProcessador} width={'60px'}/>
                            </div>
                            <div style ={{width : '100%'}}>
          
                            <Grid container spacing={2}>
                            
                                <Grid item xs={2} >
                                    <Typography variant='caption'>Fabricante:</Typography>
                                    <Typography variant='subtitle2'><strong>{item.processador[0].fabricante}</strong></Typography>
                                </Grid>
                                <Grid item xs={4} >
                                    <Typography variant='caption'>Modelo:</Typography>
                                    <Typography variant='subtitle2'><strong>{item.processador[0].nome}</strong></Typography>
                                </Grid>

                                <Grid item xs={1} >
                                    <Typography variant='caption'>Arquitetura:</Typography>
                                    <Typography variant='subtitle2'><strong>{item.processador[0].arquitetura}</strong></Typography>
                                </Grid>

                                <Grid item xs={1} >
                                    <Typography variant='caption'>Nucleos:</Typography>
                                    <Typography variant='subtitle2'><strong>{item.processador[0].nucleos}</strong></Typography>
                                </Grid>

                                <Grid item xs={1} >
                                    <Typography variant='caption'>Nucleos:</Typography>
                                    <Typography variant='subtitle2'><strong>{item.processador[0].nucleos}</strong></Typography>
                                </Grid>

                                <Grid item xs={1} >
                                    <Typography variant='caption'>Logicos:</Typography>
                                    <Typography variant='subtitle2'><strong>{item.processador[0].processadoresLogicos}</strong></Typography>
                                </Grid>

                                <Grid item xs={1} >
                                    <Typography variant='caption'>Velocidade:</Typography>
                                    <Typography variant='subtitle2'><strong>{item.processador[0].velocidade/1000}Ghz</strong></Typography>
                                </Grid>

                            </Grid>                           
                               
                                
                            </div>
                        </div>
                        }) : null
                    }
<Divider/>

{
                        inventory.memoria ? <div style={{display : 'flex', marginTop : '10px', backgroundColor : '#fff', padding : '10px', borderRadius : '5px', }}>
                        <div style={{width : '100px', textAlign:'center'}}>
                            <img src={IconeMemoria} width={'60px'}/>
                        </div>
                        <div style ={{width : '100%'}}>  
                        <Grid container spacing={2}>
                                
                                <Grid item xs={2} >
                                    <Typography variant='caption'>Total:</Typography>
                                    <Typography variant='subtitle2'><strong>{inventory.memoria.total}</strong></Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <Typography variant='caption'>Em Uso:</Typography>
                                    <Typography variant='subtitle2'><strong>{inventory.memoria.total}</strong></Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <Typography variant='caption'>% Uso:</Typography>
                                    <Typography variant='subtitle2'><strong>{inventory.memoria.porcentagemUso}%</strong></Typography>
                                </Grid>                            
                        </Grid>                                              
                       
        
                            <Typography variant='caption'>Módulos Instalados:</Typography>
                            {
                                inventory.memoria.modulos ? inventory.memoria.modulos.map(function(modulo, i){
                                    return <div style={{display : 'flex', marginTop : '10px', backgroundColor : '#fff', padding : '10px', borderRadius : '5px', }}>
                                    <div style={{width : '100px', textAlign:'center'}}>
                                        <img src={IconeMemoria} width={'30px'}/>
                                    </div>
                                    <div style ={{width : '100%'}}>    
                                        <Grid container spacing={2}>
                                            <Grid container spacing={1}>                                                                        
                                                </Grid>
                                                <Grid item xs={2} >
                                                    <Typography variant='caption'>Módulo  {i + 1}</Typography>
                                                    <Typography variant='subtitle2'><strong>{modulo.fabricante}</strong></Typography>
                                                </Grid>
                                                <Grid item xs={2} >
                                                    <Typography variant='caption'>Capacidade:</Typography>
                                                    <Typography variant='subtitle2'><strong>{modulo.capacidade/1024/1024}GB</strong></Typography>
                                                </Grid>
                                                <Grid item xs={4} >
                                                    <Typography variant='caption'>Velocidade:</Typography>
                                                    <Typography variant='subtitle2'><strong>{modulo.velocidade}</strong></Typography>
                                                </Grid>
                                        </Grid>                                                             
                                        
                                    </div>
                                </div>
                                }) : null
                            }
                           
                            
                        </div>
                    </div> : null
                    }
<Divider/>
                    


{
                        inventory.discos ? inventory.discos.map(function(disco, i){
                            return <div style={{display : 'flex', marginTop : '10px', backgroundColor : '#fff', padding : '10px', borderRadius : '5px', }}>
                            <div style={{width : '100px', textAlign:'center'}}>
                                <img src={IconeHDD} width={'60px'}/>
                            </div>
                            <div style ={{width : '100%'}}>  
            
                                <Grid container spacing={2}>
                                    <Grid item xs={4} >
                                        <Typography variant='caption'>Modelo:</Typography>
                                        <Typography variant='subtitle2'><strong>{disco.nome}</strong></Typography>
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Typography variant='caption'>Interface:</Typography>
                                        <Typography variant='subtitle2'><strong>{disco.interface}</strong></Typography>
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Typography variant='caption'>Type:</Typography>
                                        <Typography variant='subtitle2'><strong>{disco.tipo}</strong></Typography>
                                    </Grid> 
                                    <Grid item xs={2} >
                                        <Typography variant='caption'>Capacidade:</Typography>
                                        <Typography variant='subtitle2'><strong>{disco.tamanho}</strong></Typography>
                                    </Grid>                            
                                </Grid>                            

                                {
                                    disco.particoes ? disco.particoes.map(function(particao, i){
                                        return <div style={{display : 'flex', marginTop : '10px', backgroundColor : '#fff', padding : '10px', borderRadius : '5px', }}>
                                        <div style={{width : '40px', textAlign:'center'}}>
                                            <img src={IconeHDD} width={'30px'}/>
                                        </div>
                                        <div style ={{width : '100%'}}>

    
                                            <Grid container spacing={1}>                                                                        
           
                                                <Grid item xs={2} >
                                                    <Typography variant='caption'># Partição {i + 1}</Typography>
                                                    <Typography variant='subtitle2'><strong>{particao.nome}</strong></Typography>
                                                </Grid>
                                                <Grid item xs={2} >
                                                    <Typography variant='caption'>Tipo:</Typography>
                                                    <Typography variant='subtitle2'><strong>{particao.tipo}</strong></Typography>
                                                </Grid>
                                                <Grid item xs={2} >
                                                    <Typography variant='caption'>Tamanho:</Typography>
                                                    <Typography variant='subtitle2'><strong>{particao.tamanho}</strong></Typography>
                                                </Grid>
                                                <Grid item xs={1} >
                                                    <Typography variant='caption'>Volume:</Typography>
                                                    <Typography variant='subtitle2'><strong>{particao.volume}</strong></Typography>
                                                </Grid>
                                                <Grid item xs={1} >
                                                    <Typography variant='caption'>Arquivos:</Typography>
                                                    <Typography variant='subtitle2'><strong>{particao.sistema_arquivo}</strong></Typography>
                                                </Grid>
                                                <Grid item xs={2} >
                                                    <Typography variant='caption'>Total:</Typography>
                                                    <Typography variant='subtitle2'><strong>{particao.espaco_total}</strong></Typography>
                                                </Grid>
                                                <Grid item xs={2} >
                                                    <Typography variant='caption'>Livre:</Typography>
                                                    <Typography variant='subtitle2'><strong>{particao.espaco_livre}</strong></Typography>
                                                </Grid>
                                            </Grid>                             
                                         
                                        </div>
                                    </div>
                                    }) : null
                                }
                                
                                
                            </div>
                        </div>
                        }) : null
                    }
<Divider/>


                    {
                        inventory.video ? inventory.video.map(function(video, i){
                            return <div style={{display : 'flex', marginTop : '10px', backgroundColor : '#fff', padding : '10px', borderRadius : '5px', }}>
                            <div style={{width : '100px', textAlign:'center'}}>
                                <img src={IconeVideo} width={'60px'}/>
                            </div>
                            <div style ={{width : '100%'}}>  

                  
                                <Grid container spacing={2}>
                                    <Grid item xs={4} >
                                        <Typography variant='caption'>Modelo:</Typography>
                                        <Typography variant='subtitle2'><strong>{video.nome}</strong></Typography>
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Typography variant='caption'>Modelo:</Typography>
                                        <Typography variant='subtitle2'><strong>{video.fabricante}</strong></Typography>
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Typography variant='caption'>Memória:</Typography>
                                        <Typography variant='subtitle2'><strong>{video.memoria}</strong></Typography>
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Typography variant='caption'>Driver:</Typography>
                                        <Typography variant='subtitle2'><strong>{video.driver}</strong></Typography>
                                    </Grid>
                                </Grid>                          
                             
                                
                            </div>
                        </div>
                        }) : null
                    }
<Divider/>

                    {
                        inventory.monitores ? inventory.monitores.map(function(monitor, i){
                            return <div style={{display : 'flex', marginTop : '10px', backgroundColor : '#fff', padding : '10px', borderRadius : '5px', }}>
                            <div style={{width : '100px', textAlign:'center'}}>
                                <img src={IconeMonitor} width={'60px'}/>
                            </div>
                            <div style ={{width : '100%'}}> 
                                <Grid container spacing={1}>                                                            
                                    <Grid item xs={2} >
                                        <Typography variant='subtitle1' style ={{color:"#263238!important"}}>Monitores</Typography>
                                    </Grid>                                    
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} >
                                        <Typography variant='caption'>Modelo:</Typography>
                                        <Typography variant='subtitle2'><strong>{monitor.nome}</strong></Typography>
                                    </Grid>
                                    <Grid item xs={4} >
                                        <Typography variant='caption'>Fabricante:</Typography>
                                        <Typography variant='subtitle2'><strong>{monitor.fabricante}</strong></Typography>
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Typography variant='caption'>Resolução:</Typography>
                                        <Typography variant='subtitle2'><strong>{monitor.resolucao}</strong></Typography>
                                    </Grid>
                                   
                                </Grid>                             
                                
                                
                                
                            </div>
                        </div>
                        }) : null
                    }
<Divider/>

                {
                        inventory.rede ? inventory.rede.map(function(rede, i){
                            return <div style={{display : 'flex', marginTop : '10px', backgroundColor : '#fff', padding : '10px', borderRadius : '5px', }}>
                            <div style={{width : '100px', textAlign:'center'}}>
                                <img src={IconeRede} width={'60px'}/>
                            </div>
                            <div style ={{width : '100%'}}>                            
               
                                <Grid container spacing={2}>
                                    <Grid item xs={6} >
                                        <Typography variant='caption'>Modelo:</Typography>
                                        <Typography variant='subtitle2'><strong>{rede.descricao}</strong></Typography>
                                    </Grid>
                                    <Grid item xs={4} >
                                        <Typography variant='caption'>Endereço Físico:</Typography>
                                        <Typography variant='subtitle2'><strong>{rede.endereco_mac}</strong></Typography>
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Typography variant='caption'>Endereço IP:</Typography>
                                        <Typography variant='subtitle2'><strong>{rede.endereco_ip}</strong></Typography>
                                    </Grid>
                                   
                                </Grid> 
                                
                                
                            </div>
                        </div>
                        }) : null
                    }
<Divider/>
                    {
                        inventory.mouseTeclado ? inventory.mouseTeclado.Teclados.map(function(teclado, i){
                            return <div style={{display : 'flex', marginTop : '10px', backgroundColor : '#fff', padding : '10px', borderRadius : '5px', }}>
                            <div style={{width : '100px', textAlign:'center'}}>
                                <img src={IconeTeclado} width={'60px'}/>
                            </div>
                            <div style ={{width : '100%'}}>                            
                                
                                <Typography variant='subtitle1' style ={{color:"#263238!important"}}># {teclado.Nome}</Typography>
                                <Typography variant='caption'>Descrição: <strong>{teclado.Descricao}</strong></Typography><br/>
                                <Typography variant='caption'>Fabricante: <strong>{teclado.Fabricante ? teclado.Fabricante : '* desconhecido'}</strong></Typography><br/>
                                
                                
                            </div>
                        </div>
                        }) : null
                    }
<Divider/>
{
                        inventory.mouseTeclado ? inventory.mouseTeclado.Mouses.map(function(mouse, i){
                            return <div style={{display : 'flex', marginTop : '10px', backgroundColor : '#fff', padding : '10px', borderRadius : '5px', }}>
                            <div style={{width : '100px', textAlign:'center'}}>
                                <img src={IconeMousefrom} width={'60px'}/>
                            </div>
                            <div style ={{width : '100%'}}>                            
                                
                                <Typography variant='subtitle1' style ={{color:"#263238!important"}}># {mouse.Nome}</Typography>
                                <Typography variant='caption'>Descrição: <strong>{mouse.Descricao}</strong></Typography><br/>
                                <Typography variant='caption'>Fabricante: <strong>{mouse.Fabricante ? mouse.Fabricante : '* desconhecido'}</strong></Typography><br/>
                                
                                
                            </div>
                        </div>
                        }) : null
                    }
<Divider/>
    {
                        inventory.usb ? inventory.usb.map(function(usb, i){
                            return <div style={{display : 'flex', marginTop : '10px', backgroundColor : '#fff', padding : '10px', borderRadius : '5px', }}>
                            <div style={{width : '100px', textAlign:'center'}}>
                                <img src={IconeUSB} width={'30px'}/>
                            </div>
                            <div style ={{width : '100%'}}>                            
                                
                                <Typography variant='subtitle1' style ={{color:"#263238!important"}}># {usb}</Typography>
                               
                                
                            </div>
                        </div>
                        }) : null
                    }


                    



                    
                </div>

           </div>
        </div>: <CircularProgress/>}
        
      
        
         
                         
    
        <div className='fabIncluir'>
        {/*<ModalNovoDca idusuario={props.match.params.idempresa} idempresa={props.match.params.chaveEmpresa} getData = {()=>getDcas()}/>*/}
        </div>
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(DataInventoryDetails)