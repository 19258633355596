import React, {Fragment, useState, useEffect, useRef} from 'react'
import './Index.css'
import NavPrincipal from './Componentes/Navbar'
import Carrossel from './Componentes/Carrossel'
import CardsTop from './Componentes/CardsTop'
import Sobrenos from './Componentes/Sobrenos'
import Faleconosco from './Componentes/Formcontato'
import Rodape from './Componentes/Rodape'

const Index =(props) =>{
    const url = new URL(window.location.href);
    return <div id='div-main'>
        
        <NavPrincipal url={url} dataBase={url == 'datacount.com.br' || url == 'www.datacount.com.br' ? 'Datacount' : 'Printerconnect'}   {...props}/>
        <Carrossel  dataBase={url == 'datacount.com.br' || url == 'www.datacount.com.br' ? 'Datacount' : 'Printerconnect'} />        
        <Sobrenos   dataBase={url == 'datacount.com.br' || url == 'www.datacount.com.br' ? 'Datacount' : 'Printerconnect'} />
        <Faleconosco   dataBase={url == 'datacount.com.br' || url == 'www.datacount.com.br' ? 'Datacount' : 'Printerconnect'} />
        <Rodape   dataBase={url == 'datacount.com.br' || url == 'www.datacount.com.br' ? 'Datacount' : 'Printerconnect'}  />
        
    </div>
}


export default Index;