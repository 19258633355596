import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
//import Conexao from '../providers/Conexao'
import Swal from 'sweetalert2'
import { withRouter } from 'react-router-dom'
import { Divider, Grid, TextField, Tooltip, Checkbox, InputAdornment } from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';

import Iconpatrametros from '@material-ui/icons/Apps'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalParametrosDataclassic = (props) => {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [codigoEmpresa, setCodigoEmpresa] = React.useState('')
  const [operacaoRequisicao, setOperacaoRequisicao] = React.useState('')
  const [tipoDescRequisicao, setTipoDescRequisicao] = React.useState('')
  const [statusRequisicao, setStatusRequisicao] = React.useState('')
  const [situacaoRequisicao, setSituacaoRequisicao] = React.useState('')
  const [condicaoPagamentoRequisicao, setCondicaoPagamentoRequisicao] = React.useState('')
  const [vendedorRequisicao, setVendedorRequisicao] = React.useState('')

  const [tecnicoChamado, setTecnicoChamado] = React.useState('')
  const [motivoChamado, setMotivoChamado] = React.useState('')
  const [tipoIntervencaoChamado, setTipoIntervencaoChamado] = React.useState('')
  const [statusChamado, setStatusChamado] = React.useState('')
  const [operadorChamado, setOperadorChamado] = React.useState('')
  const [habilitarIntegracao, setHabilitarIntegracao] = React.useState('f')
  const [habilitarIntegracaoEmailRequisicao, setHabilitarIntegracaoEmailRequisicao] = React.useState('f')
  const [habilitarIntegracaoEmailOrdemServico, setHabilitarIntegracaoEmailOrdemServico] = React.useState('f')
  const [habilitarIntegracaoContadores, setHabilitarIntegracaoContadores] = React.useState('f')
  const [habilitarIntegrarRequisicoes, setHabilitarIntegrarRequisicoes] = React.useState('f')
  const [habilitarIntegrarOrdensServico, setHabilitarIntegrarOrdensServico] = React.useState('f')

  const [importarChamadasServico, setImportarChamadasServico] = React.useState('f')
  const [importarAlertasConsumiveis, setImportarAlertasConsumiveis] = React.useState('f')
  const [importarAtolamentos, setImportarAtolamentos] = React.useState('f')
  const [diasAtolamentos, setDiasAtolamentos] = React.useState('3')
  const [importarAlertasDiversos, setImportarAlertasDiversos] = React.useState('f')

  const [tonerEmpty, setTonerEmpty] = React.useState('f')
  const [tonerAlmostEmpty, setTonerAlmostEmpty] = React.useState('f')
  const [wasteTonerReceptacleAlmostFull, setWasteTonerReceptacleAlmostFull] = React.useState('f')
  const [wasteInkReceptacleFull, setWasteInkReceptacleFull] = React.useState('f')
  const [developerAlmostEmpty, setDeveloperAlmostEmpty] = React.useState('f')
  const [inkEmpty, setInkEmpty] = React.useState('f')
  const [inkAlmostEmpty, setInkAlmostEmpty] = React.useState('f')
  const [wasteInkReceptacleAlmostFull, setWasteInkReceptacleAlmostFull] = React.useState('f')
  const [opcLifeAlmostOver, setOpcLifeAlmostOver] = React.useState('f')
  const [developerEmpty, setDeveloperEmpty] = React.useState('f')
  const [printRibbonEmpty, setPrintRibbonEmpty] = React.useState('f')
  const [printRibbonAlmostEmpty, setPrintRibbonAlmostEmpty] = React.useState('f')
  const [wasteTonerReceptacleFull, setWasteTonerReceptacleFull] = React.useState('f')
  const [opcLifeOver, setOpcLifeOver] = React.useState('f')
  const [markerSupplies, setMarkerSupplies] = React.useState('f')

  const [emailNotificacaoToner, setEmailNotificacaoToner] = React.useState('')
  const [emailNotificacaoChamado, setEmailNotificacaoChamado] = React.useState('')

 
  
    const handleClickOpen = () => {
    setCodigoEmpresa(props.empresa.codemp)
    setOperacaoRequisicao(props.empresa.operacao ? props.empresa.operacao : 1)

    setTipoDescRequisicao(props.empresa.tipodesc)

    setStatusRequisicao(props.empresa.status )
    setSituacaoRequisicao(props.empresa.situacao ? props.empresa.situacao : 'A')
    setCondicaoPagamentoRequisicao(props.empresa.condpag )
    setVendedorRequisicao(props.empresa.vend )


    setTecnicoChamado(props.empresa.codtec )
    setMotivoChamado(props.empresa.motivo )
    setTipoIntervencaoChamado(props.empresa.tipointerv )
    setStatusChamado(props.empresa.statusos )
    setOperadorChamado(props.empresa.operador)

    setHabilitarIntegracao(props.empresa.habilitarintegracao)
    setHabilitarIntegracaoEmailRequisicao(props.empresa.habilitarintegracaoemailrequisicao)
    setHabilitarIntegracaoEmailOrdemServico(props.empresa.habilitarintegracaoemailordemservico)
    setHabilitarIntegracaoContadores(props.empresa.habilitarintegracaocontadores)
    setHabilitarIntegrarRequisicoes(props.empresa.habilitarintegrarrequisicoes)
    setHabilitarIntegrarOrdensServico(props.empresa.habilitarintegrarordensservico)
    setImportarChamadasServico(props.empresa.importarchamadasservico)
    setImportarAlertasConsumiveis(props.empresa.habilitarintegracao)
    setImportarAlertasConsumiveis(props.empresa.importaralertasconsumiveis)
    setImportarAtolamentos(props.empresa.importaratolamentos)
    setDiasAtolamentos(props.empresa.diasatolamentos)
    setImportarAlertasDiversos(props.empresa.importaralertasdiversos)


    setTonerEmpty(props.empresa.tonerempty)
    setTonerAlmostEmpty(props.empresa.toneralmostempty)
    setWasteTonerReceptacleAlmostFull(props.empresa.wastetonerreceptaclealmostfull)
    setWasteInkReceptacleFull(props.empresa.wasteinkreceptaclefull)
    setDeveloperAlmostEmpty(props.empresa.developeralmostempty)
    setInkEmpty(props.empresa.inkempty)
    setInkAlmostEmpty(props.empresa.inkalmostempty)
    setWasteInkReceptacleAlmostFull(props.empresa.wasteinkreceptaclealmostfull)
    setOpcLifeAlmostOver(props.empresa.opclifealmostover)
    setDeveloperEmpty(props.empresa.developerempty)
    setPrintRibbonEmpty(props.empresa.printribbonempty)
    setPrintRibbonAlmostEmpty(props.empresa.printribbonalmostempty)
    setWasteTonerReceptacleFull(props.empresa.wastetonerreceptaclefull)
    setOpcLifeOver(props.empresa.opclifeover)
    setMarkerSupplies(props.empresa.markersupplies)
    setEmailNotificacaoToner(props.empresa.emailnotificacaorequisicao)
    setEmailNotificacaoChamado(props.empresa.emailnotificaocaochamado)

    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const disableAllFunction = (operacao) =>{
    if(operacao){
      setHabilitarIntegracao('f') 
      setHabilitarIntegracao('f')
      setHabilitarIntegracaoEmailRequisicao('f')
      setHabilitarIntegracaoEmailOrdemServico('f')
      setHabilitarIntegracaoContadores('f')
      setHabilitarIntegrarRequisicoes('f')
      setHabilitarIntegrarOrdensServico('f')
      setImportarChamadasServico('f')
      setImportarAlertasConsumiveis('f')
      setImportarAlertasConsumiveis('f')
      setImportarAtolamentos('f')
      setDiasAtolamentos('0')
      setImportarAlertasDiversos('f')


      setTonerEmpty('f')
      setTonerAlmostEmpty('f')
      setWasteTonerReceptacleAlmostFull('f')
      setWasteInkReceptacleFull('f')
      setDeveloperAlmostEmpty('f')
      setInkEmpty('f')
      setInkAlmostEmpty('f')
      setWasteInkReceptacleAlmostFull('f')
      setOpcLifeAlmostOver('f')
      setDeveloperEmpty('f')
      setPrintRibbonEmpty('f')
      setPrintRibbonAlmostEmpty('f')
      setWasteTonerReceptacleFull('f')
      setOpcLifeOver('f')
      setMarkerSupplies('f')
      setEmailNotificacaoToner('')
      setEmailNotificacaoChamado('')
    }else{
      setHabilitarIntegracao('t')
    }


    
  }

  const disableOptionSuprimentos = (operacao) =>{
    if(operacao){
    
      setHabilitarIntegrarRequisicoes('f') 

      setOperacaoRequisicao('-1')

      setTipoDescRequisicao('')
  
      setStatusRequisicao('-1')

      setSituacaoRequisicao('')

      setCondicaoPagamentoRequisicao('')

      setVendedorRequisicao('') 
  
      

      setEmailNotificacaoToner('')

      setHabilitarIntegracaoEmailRequisicao('f')

    }else{

      setHabilitarIntegrarRequisicoes('t')
      
    }
  }

  const habilitarOpcoesOrdemServido = (operacao) =>{
    habilitarIntegracaoEmailOrdemServico == 't' ? setHabilitarIntegracaoEmailOrdemServico('f') : setHabilitarIntegracaoEmailOrdemServico('t')

    if(operacao){
      setHabilitarIntegracaoEmailOrdemServico('f')
      setHabilitarIntegrarOrdensServico('f')
      setImportarChamadasServico('f')
      setImportarAlertasConsumiveis('f')
      setImportarAlertasConsumiveis('f')
      setImportarAtolamentos('f')
      setImportarAlertasDiversos('f')


      setTonerEmpty('f')
      setTonerAlmostEmpty('f')
      setWasteTonerReceptacleAlmostFull('f')
      setWasteInkReceptacleFull('f')
      setDeveloperAlmostEmpty('f')
      setInkEmpty('f')
      setInkAlmostEmpty('f')
      setWasteInkReceptacleAlmostFull('f')
      setOpcLifeAlmostOver('f')
      setDeveloperEmpty('f')
      setPrintRibbonEmpty('f')
      setPrintRibbonAlmostEmpty('f')
      setWasteTonerReceptacleFull('f')
      setOpcLifeOver('f')
      setMarkerSupplies('f')
      setEmailNotificacaoChamado('')
      setTecnicoChamado('')

      setMotivoChamado('')

      setTipoIntervencaoChamado('')

      setStatusChamado('')

      setOperadorChamado('')
    }else{
      setHabilitarIntegracao('t')
    }
  }
  
  return (
    <div style={{ height: '100%' }}>
      <Tooltip title='Cadastrar parametros para integração Dataclassic'>
        <Button disabled={props.disabled} variant='outlined' style={{ height: '100%', backgroundColor: "#fff", fontSize: '10px' }} onClick={handleClickOpen}>
          <Iconpatrametros /> Dataclassic
        </Button>

      </Tooltip>
      <Dialog
        disableBackdropClick disableEscapeKeyDown
        hideBackdrop
        fullWidth
        maxWidth='lg'
        scroll='body'
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        
        <DialogContent  >

          <Tooltip title='habilita a integração com o ERP Dataclassic'>
            <Checkbox checked={habilitarIntegracao == 't'} onClick={() => {
              disableAllFunction(habilitarIntegracao == 't')
              
            }} />
          </Tooltip><Typography variant='caption' style={{ fontSize: '16px' }}><strong>Habilitar Integração com o Dataclassic?</strong></Typography>



          <Grid container spacing={1} style={{ marginTop: '5px' }}>
            <Grid item xs={2} style={{ paddingLeft: '48px' }}>
              <TextField
                label='Código Empresa'
                fullWidth
                disabled={habilitarIntegracao == 'f'}
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                value={codigoEmpresa}
                size='small'
                onChange={(e) => setCodigoEmpresa(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                disabled={habilitarIntegracao == 'f'}
                label='Operador'
                fullWidth
                size='small'
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                value={operadorChamado}
                onChange={(e) => setOperadorChamado(e.target.value)}
              />
            </Grid>
            <Grid item xs={2} >
              <TextField
                label='Código Integração'
                fullWidth
                disabled
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                value={props.idusuario}
                size='small'
                helperText={"* Use este código no serviço de integração"}
              />
              
            </Grid>
            <Grid item xs={5}>
              <TextField
                disabled
                label='Razão Social'
                fullWidth
                value={props.empresa.razaosocial}
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                size='small'
              />
            </Grid>
          </Grid>
          <Tooltip title='Atualizar contadores e níveis de suprimento no Dataclassic'>
            <Checkbox disabled={habilitarIntegracao == 'f'} checked={habilitarIntegracaoContadores == 't'} onClick={() => {
              habilitarIntegracaoContadores == 't' ? setHabilitarIntegracaoContadores('f') : setHabilitarIntegracaoContadores('t')
            }} />
          </Tooltip><Typography variant='caption' style={{ fontSize: '16px' }}>Habilitar atualização de contadores e níveis de suprimentos?</Typography>
         <br/>
          <Tooltip title='Habilita a importação e abertura automática de Requisições de Suprimento'>
            <Checkbox disabled={habilitarIntegracao == 'f'} checked={habilitarIntegrarRequisicoes == 't'} onClick={() => {
              disableOptionSuprimentos(habilitarIntegrarRequisicoes == 't')
              
            }} /></Tooltip><Typography variant='caption' style={{ fontSize: '16px' }}>Habilitar Integração de Requisições de Suprimento?</Typography>
          <Grid item xs={5} style={{ paddingLeft: '31px' }}>
            <Tooltip title='Notificar via email e gerar Requisição'>
              <Checkbox disabled={habilitarIntegracao == 'f' || habilitarIntegrarRequisicoes == 'f'} checked={habilitarIntegracaoEmailRequisicao == 't'} onClick={() => {
                habilitarIntegracaoEmailRequisicao == 't' ? setHabilitarIntegracaoEmailRequisicao('f') : setHabilitarIntegracaoEmailRequisicao('t')
              }} />
            </Tooltip>
            <Typography variant='caption' style={{ fontSize: '12px' }}>Apenas enviar email (não será gerado Requisição)?</Typography>

          </Grid>
          <Grid container spacing={1} style={{ marginTop: '5px' }}>
            <Grid item xs={2} style={{ paddingLeft: '48px' }}>
              <TextField
                label='Operação'
                fullWidth
                disabled={habilitarIntegracao == 'f' || habilitarIntegrarRequisicoes == 'f' || habilitarIntegracaoEmailRequisicao == 't'}
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                value={operacaoRequisicao}
                onChange={(e) => setOperacaoRequisicao(e.target.value)}
                size='small' />
            </Grid>
            <Grid item xs={1}>
              <TextField
                label='TipoDesc'
                fullWidth
                disabled={habilitarIntegracao == 'f' || habilitarIntegrarRequisicoes == 'f' || habilitarIntegracaoEmailRequisicao == 't'}
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                value={tipoDescRequisicao}
                onChange={(e) => setTipoDescRequisicao(e.target.value)}
                size='small' />
            </Grid>
            <Grid item xs={1}>
              <TextField
                label='Status'
                disabled={habilitarIntegracao == 'f' || habilitarIntegrarRequisicoes == 'f' || habilitarIntegracaoEmailRequisicao == 't'}
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                value={statusRequisicao}
                onChange={(e) => setStatusRequisicao(e.target.value)}
                size='small' />
            </Grid>
            <Grid item xs={1}>
              <TextField
                label='Situação'
                disabled={habilitarIntegracao == 'f' || habilitarIntegrarRequisicoes == 'f' || habilitarIntegracaoEmailRequisicao == 't'}
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                value={situacaoRequisicao}
                onChange={(e) => setSituacaoRequisicao(e.target.value)}
                size='small' />
            </Grid>
            <Grid item xs={1}>
              <TextField
                label='Cond. Pag'
                disabled={habilitarIntegracao == 'f' || habilitarIntegrarRequisicoes == 'f' || habilitarIntegracaoEmailRequisicao == 't'}
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                value={condicaoPagamentoRequisicao}
                onChange={(e) => setCondicaoPagamentoRequisicao(e.target.value)}
                size='small' />
            </Grid>
            <Grid item xs={1}>
              <TextField
                label='Vendedor'
                disabled={habilitarIntegracao == 'f' || habilitarIntegrarRequisicoes == 'f' || habilitarIntegracaoEmailRequisicao == 't'}
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                value={vendedorRequisicao}
                onChange={(e) => setVendedorRequisicao(e.target.value)}
                size='small' />
            </Grid>
            <Grid item xs={5} >
              <TextField
                label='Email(s) de destino para notificações de Abertura de Requisição'
                fullWidth
                disabled={habilitarIntegracao == 'f' || habilitarIntegrarRequisicoes == 'f'}
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                value={emailNotificacaoToner}
                onChange={(e) => setEmailNotificacaoToner(e.target.value)}
                size='small' />
            </Grid>
          </Grid>


       
          <Tooltip title='Habilita a importação e abertura automática de Ordens de Serviço'>
            <Checkbox disabled={habilitarIntegracao == 'f'}
              checked={habilitarIntegrarOrdensServico == 't'} onClick={() => {
              
                setHabilitarIntegrarOrdensServico(habilitarIntegrarOrdensServico == 't' ? 'f' : 't')
              }} /></Tooltip><Typography variant='caption' style={{ fontSize: '16px' }}>Habilitar Integração de Ordens de Serviço?</Typography>
          <Grid item xs={5} style={{ paddingLeft: '31px' }}>
            <Tooltip title='Notificar via email e gerar Ordem de Serviço'>
              <Checkbox disabled={habilitarIntegracao == 'f' || habilitarIntegrarOrdensServico == 'f'} checked={habilitarIntegracaoEmailOrdemServico == 't'} onClick={() => {
                habilitarIntegracaoEmailOrdemServico == 't'  ? setHabilitarIntegracaoEmailOrdemServico('f') :setHabilitarIntegracaoEmailOrdemServico('t')

                
              
              }} />
            </Tooltip>
            <Typography variant='caption' style={{ fontSize: '12px' }}>Apenas enviar email (não será gerado Ordem de Serviço)?</Typography>

          </Grid>
          <Grid container spacing={1} style={{ marginTop: '5px' }}>
            <Grid item xs={2} style={{ paddingLeft: '48px' }}>
              <TextField
                label='Técnico'
                disabled={habilitarIntegracao == 'f' || habilitarIntegracaoEmailOrdemServico == 't' || habilitarIntegrarOrdensServico == 'f'}
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                value={tecnicoChamado}
                onChange={(e) => setTecnicoChamado(e.target.value)}
                size='small' />
            </Grid>
            <Grid item xs={1}>
              <TextField
                label='Motivo'
                disabled={props.revenda || habilitarIntegracao == 'f' || habilitarIntegracaoEmailOrdemServico == 't' || habilitarIntegrarOrdensServico == 'f'}
           
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                value={motivoChamado}
                onChange={(e) => setMotivoChamado(e.target.value)}
                size='small' />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label='Tipo de Intervenção'
                disabled={props.revenda || habilitarIntegracao == 'f' || habilitarIntegracaoEmailOrdemServico == 't' || habilitarIntegrarOrdensServico == 'f'}

                fullWidth
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                value={tipoIntervencaoChamado}
                onChange={(e) => setTipoIntervencaoChamado(e.target.value)}
                size='small' />
            </Grid>
            <Grid item xs={1}>
              <TextField
                label='Status OS'
                fullWidth
                disabled={habilitarIntegracao == 'f' || habilitarIntegracaoEmailOrdemServico == 't' || habilitarIntegrarOrdensServico == 'f'}
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                value={statusChamado}
                onChange={(e) => setStatusChamado(e.target.value)}
                size='small' />
            </Grid>
            <Grid item xs={6} >
              <TextField
                label='Email(s) de destino para notificações de Abertura de Ordens de Serviço'
                fullWidth
                disabled={habilitarIntegracao == 'f' || habilitarIntegrarRequisicoes == 'f'}
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                value={emailNotificacaoChamado}
                onChange={(e) => setEmailNotificacaoChamado(e.target.value)}
                size='small' />
            </Grid>
          </Grid>
         
          <Grid container spacing={1} >
            <Grid item xs={12} style={{ paddingLeft: '35px' }}>
              <Tooltip title='Habilita a abertura de Ordens de Serviço para Alertas de Serviço'>
                <Checkbox disabled={habilitarIntegracao == 'f'  || habilitarIntegrarOrdensServico == 'f'}
                  checked={importarChamadasServico == 't'} onClick={() => {
                    importarChamadasServico == 't' ? setImportarChamadasServico('f') : setImportarChamadasServico('t')
                  }} />
              </Tooltip><Typography variant='caption' style={{ fontSize: '12px' }}>Importar Códigos de Erro e Chamadas de Serviço?</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={1}  >
            <Grid item xs={12} style={{ paddingLeft: '35px' }}>
              <Tooltip title='Habilita a abertura de Ordens de Serviço para Alertas de Peças (Cilindros, Fusores, Kits, Laser)'>
                <Checkbox disabled={habilitarIntegracao == 'f' || habilitarIntegrarOrdensServico == 'f'}
                  checked={importarAlertasConsumiveis == 't'} onClick={() => {
                    importarAlertasConsumiveis == 't' ? setImportarAlertasConsumiveis('f') : setImportarAlertasConsumiveis('t')
                  }} />
              </Tooltip><Typography variant='caption' style={{ fontSize: '12px' }}>Importar Alertas de Consumíveis (Cilindros, fusores, laser, kit manutenção)?</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={0} style={{ paddingLeft: '65px' }} >
            <Grid item xs={3}>
              <Tooltip title='Toner quase vazio'>
                <Checkbox disabled={habilitarIntegracao == 'f' || habilitarIntegrarOrdensServico == 'f' || importarAlertasConsumiveis == 'f'}
                  checked={tonerEmpty == 't'} onClick={() => {
                    tonerEmpty == 't' ? setTonerEmpty('f') : setTonerEmpty('t')
                  }} />
              </Tooltip><Typography variant='caption' style={{ fontSize: '12px' }}>TonerEmpty(1101)</Typography>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title='Cartucho de tinta quase vazio'>
                <Checkbox disabled={habilitarIntegracao == 'f' || habilitarIntegrarOrdensServico == 'f' || importarAlertasConsumiveis == 'f'}
                  checked={inkEmpty == 't'} onClick={() => {
                    inkEmpty == 't' ? setInkEmpty('f') : setInkEmpty('t')
                  }} />
              </Tooltip><Typography variant='caption' style={{ fontSize: '12px' }}>InkEmpty(1102)</Typography>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title='Ribbon quase no fim'>
                <Checkbox disabled={habilitarIntegracao == 'f' || habilitarIntegrarOrdensServico == 'f' || importarAlertasConsumiveis == 'f'}
                  checked={printRibbonEmpty == 't'} onClick={() => {
                    printRibbonEmpty == 't' ? setPrintRibbonEmpty('f') : setPrintRibbonEmpty('t')
                  }} />
              </Tooltip><Typography variant='caption' style={{ fontSize: '12px' }}>PrintRibbonEmpty(1103)</Typography>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title='Sem toner'>
                <Checkbox disabled={habilitarIntegracao == 'f' || habilitarIntegrarOrdensServico == 'f' || importarAlertasConsumiveis == 'f'}
                  checked={tonerAlmostEmpty == 't'} onClick={() => {
                    tonerAlmostEmpty == 't' ? setTonerAlmostEmpty('f') : setTonerAlmostEmpty('t')
                  }} />
              </Tooltip><Typography variant='caption' style={{ fontSize: '12px' }}>TonerAlmostEmpty(1104)</Typography>
            </Grid>

            <Grid item xs={3}>
              <Tooltip title='Sem tinta'>
                <Checkbox disabled={habilitarIntegracao == 'f' || habilitarIntegrarOrdensServico == 'f' || importarAlertasConsumiveis == 'f'}
                  checked={inkAlmostEmpty == 't'} onClick={() => {
                    inkAlmostEmpty == 't' ? setInkAlmostEmpty('f') : setInkAlmostEmpty('t')
                  }} />
              </Tooltip><Typography variant='caption' style={{ fontSize: '12px' }}>InkAlmostEmpty(1105)</Typography>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title='Sem ribbon'>
                <Checkbox disabled={habilitarIntegracao == 'f' || habilitarIntegrarOrdensServico == 'f' || importarAlertasConsumiveis == 'f'}
                  checked={printRibbonAlmostEmpty == 't'} onClick={() => {
                    printRibbonAlmostEmpty == 't' ? setPrintRibbonAlmostEmpty('f') : setPrintRibbonAlmostEmpty('t')
                  }} />
              </Tooltip><Typography variant='caption' style={{ fontSize: '12px' }}>PrintRibbonAlmostEmpty(1106)</Typography>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title='Reservatório de toner usado quase cheio'>
                <Checkbox disabled={habilitarIntegracao == 'f' || habilitarIntegrarOrdensServico == 'f' || importarAlertasConsumiveis == 'f'}
                  checked={wasteTonerReceptacleAlmostFull == 't'} onClick={() => {
                    wasteTonerReceptacleAlmostFull == 't' ? setWasteTonerReceptacleAlmostFull('f') : setWasteTonerReceptacleAlmostFull('t')
                  }} />
              </Tooltip><Typography variant='caption' style={{ fontSize: '12px' }}>WasteTonerReceptacleAlmostFull(1107)</Typography>
            </Grid>

            <Grid item xs={3}>
              <Tooltip title='Reservatório de tinta usada quase cheio'>
                <Checkbox disabled={habilitarIntegracao == 'f' || habilitarIntegrarOrdensServico == 'f' || importarAlertasConsumiveis == 'f'}
                  checked={wasteInkReceptacleAlmostFull == 't'} onClick={() => {
                    wasteInkReceptacleAlmostFull == 't' ? setWasteInkReceptacleAlmostFull('f') : setWasteInkReceptacleAlmostFull('t')
                  }} />
              </Tooltip><Typography variant='caption' style={{ fontSize: '12px' }}>WasteInkReceptacleAlmostFull(1108)</Typography>
            </Grid>

            <Grid item xs={3}>
              <Tooltip title='Reservatório de toner usado cheio'>
                <Checkbox disabled={habilitarIntegracao == 'f' || habilitarIntegrarOrdensServico == 'f' || importarAlertasConsumiveis == 'f'}
                  checked={wasteTonerReceptacleFull == 't'} onClick={() => {
                    wasteTonerReceptacleFull == 't' ? setWasteTonerReceptacleFull('f') : setWasteTonerReceptacleFull('t')
                  }} />
              </Tooltip><Typography variant='caption' style={{ fontSize: '12px' }}>WasteTonerReceptacleFull(1109)</Typography>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title='Reservatório de tinta usada cheio'>
                <Checkbox disabled={habilitarIntegracao == 'f' || habilitarIntegrarOrdensServico == 'f' || importarAlertasConsumiveis == 'f'}
                  checked={wasteInkReceptacleFull == 't'} onClick={() => {
                    wasteInkReceptacleFull == 't' ? setWasteInkReceptacleFull('f') : setWasteInkReceptacleFull('t')
                  }} />
              </Tooltip><Typography variant='caption' style={{ fontSize: '12px' }}>WasteInkReceptacleFull(1110)</Typography>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title='Vida útil do cilindro quase no fim'>
                <Checkbox disabled={habilitarIntegracao == 'f' || habilitarIntegrarOrdensServico == 'f' || importarAlertasConsumiveis == 'f'}
                  checked={opcLifeAlmostOver == 't'} onClick={() => {
                    opcLifeAlmostOver == 't' ? setOpcLifeAlmostOver('f') : setOpcLifeAlmostOver('t')
                  }} />
              </Tooltip><Typography variant='caption' style={{ fontSize: '12px' }}>OpcLifeAlmostOver(1111)</Typography>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title='Vida útil do cilindro terminou'>
                <Checkbox disabled={habilitarIntegracao == 'f' || habilitarIntegrarOrdensServico == 'f' || importarAlertasConsumiveis == 'f'}
                  checked={opcLifeOver == 't'} onClick={() => {
                    opcLifeOver == 't' ? setOpcLifeOver('f') : setOpcLifeOver('t')
                  }} />
              </Tooltip><Typography variant='caption' style={{ fontSize: '12px' }}>OpcLifeOver(1112)</Typography>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title='Revelador quase no fim'>
                <Checkbox disabled={habilitarIntegracao == 'f' || habilitarIntegrarOrdensServico == 'f' || importarAlertasConsumiveis == 'f'}
                  checked={developerAlmostEmpty == 't'} onClick={() => {
                    developerAlmostEmpty == 't' ? setDeveloperAlmostEmpty('f') : setDeveloperAlmostEmpty('t')
                  }} />
              </Tooltip><Typography variant='caption' style={{ fontSize: '12px' }}>DeveloperAlmostEmpty(1113)</Typography>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title='Revelador vazio'>
                <Checkbox disabled={habilitarIntegracao == 'f' || habilitarIntegrarOrdensServico == 'f' || importarAlertasConsumiveis == 'f'}
                  checked={developerEmpty == 't'} onClick={() => {
                    developerEmpty == 't' ? setDeveloperEmpty('f') : setDeveloperEmpty('t')
                  }} />
              </Tooltip><Typography variant='caption' style={{ fontSize: '12px' }}>DeveloperEmpty(1114)</Typography>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title='Kits de manutenção, cilindros, laser, fusores'>
                <Checkbox disabled={habilitarIntegracao == 'f' || habilitarIntegrarOrdensServico == 'f' || importarAlertasConsumiveis == 'f'}
                  checked={markerSupplies == 't'} onClick={() => {
                    markerSupplies == 't' ? setMarkerSupplies('f') : setMarkerSupplies('t')
                  }} />
              </Tooltip><Typography variant='caption' style={{ fontSize: '12px' }}>markerSupplies(13)</Typography>
            </Grid>
          </Grid>



          <Grid container spacing={1} style={{ paddingLeft: '35px' }}>


            <Grid item xs={12}>
              <Tooltip title='Quantas vezes por dia considerar atolamentos para abertura de uma ordem de serviço'>
                <TextField
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>
                      <Checkbox disabled={1 == 1 || habilitarIntegracao == 'f' || habilitarIntegracaoEmailOrdemServico == 't' || habilitarIntegrarOrdensServico == 'f'}
                        checked={importarAtolamentos == 't'} onClick={() => {
                          importarAtolamentos == 't' ? setImportarAtolamentos('f') : setImportarAtolamentos('t')
                        }} />
                    </InputAdornment>
                  }}
                  disabled={1 == 1 || habilitarIntegracao == 'f' || habilitarIntegracaoEmailOrdemServico == 't' || habilitarIntegrarOrdensServico == 'f'}
                  value={diasAtolamentos}
                  onChange={(e) => setDiasAtolamentos(e.target.value)}
                  size='small'
                  select
                  fullWidth
                  SelectProps={{ native: true }}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value='3'>Gerar Ordem de Serviço após 3 atolamentos no mesmo dia</option>
                  <option value='5'>Gerar Ordem de Serviço após 5 atolamentos no mesmo dia</option>
                  <option value='10'>Gerar Ordem de Serviço após 10 atolamentos no mesmo dia</option>
                  <option value='15'>Gerar Ordem de Serviço após 15 atolamentos no mesmo dia</option>
                  <option value='20'>Gerar Ordem de Serviço após 20 atolamentos no mesmo dia</option>
                </TextField>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ paddingLeft: '35px' }}>
            <Grid item xs={12}>
              <Tooltip title='Habilita a abertura de Ordens de Serviço para Alertas de unidades de imagens, sem papel'>
                <Checkbox disabled={habilitarIntegracao == 'f'  || habilitarIntegrarOrdensServico == 'f'}
                  checked={importarAlertasDiversos == 't'} onClick={() => {
                    importarAlertasDiversos == 't' ? setImportarAlertasDiversos('f') : setImportarAlertasDiversos('t')
                  }} />
              </Tooltip><Typography variant='caption' style={{ fontSize: '12px' }}>Importar Alertas diversos (unidade de toner, Gavetas sem papel)?</Typography>
            </Grid>
          </Grid>
         
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} disabled={loading} variant='outlined' color="secondary">
            Cancelar
          </Button>
          <Button autoFocus disabled={loading}  onClick={() => {
            Swal.fire({
              title: 'Datacount',
              text: 'Deseja gravar os paramêtros informados?',
              icon: 'question',
              showConfirmButton: true,
              confirmButtonText: 'Sim',
              showCancelButton: true,
              cancelButtonText: 'Não'
            }).then(resposta => {
              if (resposta.isConfirmed) {
                const parametros = {
                  idempresa: props.empresa.idempresa,
                  idusuario: props.empresa.idusuario,
                  codigoEmpresa: codigoEmpresa,
                  operacaoRequisicao: operacaoRequisicao,
                  tipoDescRequisicao: tipoDescRequisicao,
                  statusRequisicao: statusRequisicao,
                  situacaoRequisicao: situacaoRequisicao,
                  condicaoPagamentoRequisicao: condicaoPagamentoRequisicao,
                  vendedorRequisicao: vendedorRequisicao,
                  tecnicoChamado: tecnicoChamado, 
                  motivoChamado: motivoChamado,
                  tipoIntervencaoChamado: tipoIntervencaoChamado,
                  statusChamado: statusChamado,
                  operadorChamado: operadorChamado,
                  habilitarIntegracao: habilitarIntegracao,
                  habilitarIntegracaoEmailRequisicao: habilitarIntegracaoEmailRequisicao,
                  habilitarIntegracaoEmailOrdemServico: habilitarIntegracaoEmailOrdemServico,
                  habilitarIntegracaoContadores: habilitarIntegracaoContadores,
                  habilitarIntegrarRequisicoes: habilitarIntegrarRequisicoes, 
                  habilitarIntegrarOrdensServico: habilitarIntegrarOrdensServico,
                  importarChamadasServico: importarChamadasServico,
                  importarAlertasConsumiveis: importarAlertasConsumiveis,
                  importarAtolamentos: importarAtolamentos,
                  diasAtolamentos: diasAtolamentos,
                  importarAlertasDiversos: importarAlertasDiversos, 
                  tonerEmpty : tonerEmpty, 
                  tonerAlmostEmpty : tonerAlmostEmpty,
                  wasteTonerReceptacleAlmostFull : wasteTonerReceptacleAlmostFull, 
                  wasteInkReceptacleFull : wasteInkReceptacleFull, 
                  developerAlmostEmpty : developerAlmostEmpty, 
                  inkEmpty : inkEmpty, 
                  inkAlmostEmpty : inkAlmostEmpty,
                  wasteInkReceptacleAlmostFull :wasteInkReceptacleAlmostFull, 
                  opcLifeAlmostOver : opcLifeAlmostOver, 
                  developerEmpty : developerEmpty, 
                  printRibbonEmpty : printRibbonEmpty, 
                  printRibbonAlmostEmpty : printRibbonAlmostEmpty, 
                  wasteTonerReceptacleFull : wasteTonerReceptacleFull,
                  opcLifeOver : opcLifeOver,
                  markerSupplies : markerSupplies,
                  emailNotificacaoChamado : emailNotificacaoChamado, 
                  emailNotificacaoToner : emailNotificacaoToner

                }
                

                if(habilitarIntegracao == 't'){
                  if(!codigoEmpresa || codigoEmpresa ==''){
                    Swal.fire({
                      title : 'Datacount', 
                      text : 'Você deve informar o Código da Empresa!', 
                      icon : 'question', 
                      showCancelButton : true, 
                      showCancelButton : false
                    })
                    return
                  }
                }

                if(habilitarIntegracao == 't' && habilitarIntegrarRequisicoes == 't'){
                  if(!operacaoRequisicao || operacaoRequisicao ==''){
                    Swal.fire({
                      title : 'Datacount', 
                      text : 'Você deve informar o Tipo da Requisição!', 
                      icon : 'question', 
                      showCancelButton : true, 
                      showCancelButton : false
                    })
                    return
                  }

                  if(!tipoDescRequisicao || tipoDescRequisicao.toString().trim() ===''){
                    Swal.fire({
                      title : 'Datacount', 
                      text : 'Você deve informar o Código da Operação de Saída!', 
                      icon : 'question', 
                      showCancelButton : true, 
                      showCancelButton : false
                    })
                    return
                  }

                  if(!statusRequisicao || statusRequisicao ==''){
                    Swal.fire({
                      title : 'Datacount', 
                      text : 'Você deve informar o Código do Status!', 
                      icon : 'question', 
                      showCancelButton : true, 
                      showCancelButton : false
                    })
                    return
                  }

                  if(!condicaoPagamentoRequisicao || condicaoPagamentoRequisicao ==''){
                    Swal.fire({
                      title : 'Datacount', 
                      text : 'Você deve informar a Condição de Pagamento para abertura da Requisição!', 
                      icon : 'question', 
                      showCancelButton : true, 
                      showCancelButton : false
                    })
                    return
                  }

                  if(!vendedorRequisicao || vendedorRequisicao ==''){
                    Swal.fire({
                      title : 'Datacount', 
                      text : 'Você deve informar o código do Vendedor!', 
                      icon : 'question', 
                      showCancelButton : true, 
                      showCancelButton : false
                    })
                    return
                  }

                
                }
                 

                if(habilitarIntegrarOrdensServico == 't' && habilitarIntegracaoEmailOrdemServico == 'f'){
                  if(!tecnicoChamado || tecnicoChamado ==''){
                    Swal.fire({
                      title : 'Datacount', 
                      text : 'Você deve informar o código do Técnico para abertura do Chamado Técnico!', 
                      icon : 'question', 
                      showCancelButton : true, 
                      showCancelButton : false
                    })
                    return
                  }

                  if(!statusChamado || statusChamado ==''){
                    Swal.fire({
                      title : 'Datacount', 
                      text : 'Você deve informar o código do Status para abertura do Chamado Técnico!', 
                      icon : 'question', 
                      showCancelButton : true, 
                      showCancelButton : false
                    })
                    return
                  }
                }


                setLoading(true)
                props.Conexao.post('empresasController/updateParametrosIntegracaoEmpresa', {
                  ...parametros
                }).then(response => {
                  setLoading(false)
                  if (response.status == 200 && response.data.status == 'OK') {
                    if (props.updateData) {
                      props.updateData();
                    }
                    setOpen(false)
                    Swal.fire({
                      title: 'Datacount',
                      text: 'Registro atualizado com sucesso',
                      showConfirmButton: false,
                      showCancelButton: false,
                      icon: 'success',
                      timer: 1500
                    });
                  } else {
                    Swal.fire({
                      title: 'Datacount',
                      text: 'Não foi possível atualizar',
                      showConfirmButton: false,
                      showCancelButton: false,
                      icon: 'warning',
                      timer: 1500
                    });
                    
                    setOpen(false);
                  }
                }).catch(error => {
                  setLoading(false)
                  setOpen(false);
                  Swal.fire({
                    title: 'Datacount',
                    text: 'Não foi possível atualizar',
                    showConfirmButton: false,
                    showCancelButton: false,
                    icon: 'error',
                    timer: 1500
                  });
                })
              }
            })
          }} variant='contained' color="primary" >
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalParametrosDataclassic)