import * as React from 'react';
import { styled } from '@material-ui/styles';
import {TextField,   Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
    ,Switch, TableRow, Badge, Menu, Tooltip} from '@material-ui/core'

import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import IconeVisaoAtual from '@material-ui/icons/Search'
import ArchiveIcon from '@material-ui/icons/Archive';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconeOnLine from '@material-ui/icons/PowerSettingsNew'
import IconeOffLine from '@material-ui/icons/LinkOffOutlined'
import IconeDesabilitado from '@material-ui/icons/BlockOutlined'
import IconeRelatorio from '@material-ui/icons/ArrowDownward'
import IconeDescobertas from '@material-ui/icons/Add'
import IconeParqueTotal from '@material-ui/icons/Apps'
import OpcoesIcon from '@material-ui/icons/Build'
import AtivarIcon from '@material-ui/icons/Check'
import RemoverIcon from '@material-ui/icons/Close'
import {getDataRepository, substituirColetaEditada, listarClientesEquipamentos} from '../../providers/Repository'


const StyledMenu = styled((props) => (
  <Menu

    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: '#444',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: '#444'
      },
    },
  },
}));

export default function ModalBotoesAcaoDescobertas(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title = 'Exportar dados em formato .csv'>
      <Button
        id="demo-customized-button"
        aria-controls="demo-customized-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        disableElevation
        fullWidth
        onClick={handleClick} 
        endIcon={<KeyboardArrowDownIcon />}
        style={{marginLeft : '5px', backgroundColor : '#fff', border :'none'}}
      >
       <OpcoesIcon />
      </Button>
      </Tooltip>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={()=>{
            props.habilitarMonitoramento()
            handleClose();
        }} disableRipple>
          <AtivarIcon />
          Ativar Monitoramento
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem disabled ={props.visao} onClick={()=>{
            props.removerDefinitivamente()
            handleClose();
        }} disableRipple>
          <RemoverIcon />
          Desativar Coleta
        </MenuItem>
        
      </StyledMenu>
    </div>
  );
}
