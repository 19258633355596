import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import BallotIcon from '@material-ui/icons/Ballot';
import { TableHead, Table, TableCell, TableBody, TableRow, Tooltip } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import LinearProgress from '@material-ui/core/LinearProgress';
import copy from "copy-to-clipboard";
 import IconfCopy from '@material-ui/icons/FileCopyOutlined'


import Swal from 'sweetalert2'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalDcas(props) {
  const [open, setOpen] = React.useState(false);
  const {usuario} = props
  const [registros, setRegistros] = React.useState([]);
  const [atualizando, setAtualizando] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
    getListaDca();
  };
  const handleClose = () => {
    setOpen(false);
  };


  const getListaDca = (copiar = false) =>{
    setAtualizando(true)
    props.Conexao.post('dcaController/getDca', {
      idusuario : props.idusuario , 
      idempresa : props.idempresa, 
      idcliente :props.idcliente 
    }).then(response =>{
      if(response.data.status =='OK'){
        setRegistros(response.data.payload)
        
        if(copiar){
          copy(response.data.payload[response.data.payload.length - 1].chave);
          Swal.fire({
            title : 'Datacount', 
            text : 'Chave copiada com sucesso!', 
            icon:'success', 
            showConfirmButton: false, 
            showCancelButton : false, 
            timer  : 1000
          })
        }
        
        
      }else{
        setRegistros([])
      }
      setAtualizando(false)
    }).catch(error=>{
      setRegistros([])
      setAtualizando(false)
    });
  }

  return (
    <div>
      <Tooltip title='Clique para adicionar uma chave de coleta (DCA)'>
        <BallotIcon color="primary" onClick={handleClickOpen} style ={{cursor:"pointer"}}/>

      </Tooltip>
      <Dialog onClose={handleClose} fullWidth maxWidth='md' aria-labelledby="customized-dialog-title" open={open} hideBackdrop disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Lista de DCA`s
        </DialogTitle>
        <DialogContent dividers>
          
          <Table className='table-small' style ={{fontSize:"10px"}}>
            <TableHead>
              <TableCell style ={{fontSize:"10px", width:"5%"}}>#</TableCell>
              <TableCell align='center' style ={{fontSize:"10px", width:"15%"}}>CHAVE</TableCell>
              <TableCell align='center' style ={{fontSize:"10px", width:"10%"}}>CRIAÇÃO</TableCell>
              <TableCell align='center' style ={{fontSize:"10px", width:"10%"}}>ATIVAÇÃO</TableCell>
              <TableCell align='center' style ={{fontSize:"10px", width:"10%"}}>ATUALIZAÇÃO</TableCell>
              <TableCell align='center' style ={{fontSize:"10px", width:"10%"}}>COPIAR</TableCell>
              <TableCell align='center' style ={{fontSize:"10px", width:"10%"}}>EXCLUIR</TableCell>
            </TableHead>

            <TableBody>
              
            {
            registros && registros.map(function(registro, i){
              return <TableRow>
              <TableCell style ={{fontSize:"10px", width:"5%"}}>{registro.iddca}</TableCell>
              <TableCell align='center' style ={{fontSize:"10px", width:"15%"}}>{registro.chave}</TableCell>
              <TableCell align='center' style ={{fontSize:"10px", width:"10%"}}>{registro.datacadastro}</TableCell>
              <TableCell align='center' style ={{fontSize:"10px", width:"10%"}}>{registro.dataativacao}</TableCell>
              <TableCell align='center' style ={{fontSize:"10px", width:"10%"}}>{registro.dataatualizacao}</TableCell>
              <TableCell align='center' style ={{fontSize:"10px", width:"10%"}}>
                <IconfCopy style ={{cursor:'pointer'}} onClick={()=>{
                copy(registro.chave)

                Swal.fire({
                  title : 'Datacount', 
                  text : 'Chave copiada com sucesso!', 
                  icon:'success', 
                  showConfirmButton: false, 
                  showCancelButton : false, 
                  timer  : 1000
                })
              }}/>
                </TableCell>
              <TableCell align='center' style ={{fontSize:"10px", width:"10%"}}>
                <DeleteForeverIcon style ={{cursor:'pointer', color:'#f00'}} onClick={()=>{
                  if(usuario.dcasexclusao =='f'){
                    Swal.fire({
                      title : 'Datacount', 
                      text : 'Você não tem permissão para remover DCA´s', 
                      icon:'error', 
                      showConfirmButton: false, 
                      showCancelButton : false, 
                      timer  : 1000
                    })
                    return;
                  }
                Swal.fire({
                  title : 'Datacount', 
                  text : 'Deseja remover este DCA agora ?', 
                  showConfirmButton : true, 
                  confirmButtonText : 'SIM', 
                  showCancelButton : true, 
                  cancelButtonText : 'NÃO', 
                  icon:'question'
                }).then(resposta=>{
                  if(resposta.isConfirmed){
                    props.Conexao.post('dcaController/removeDca', {
                      chave : props.chave, 
                      idusuario : props.idusuario , 
                      idempresa : props.idempresa, 
                      idcliente :props.idcliente, 
                      iddca : registro.iddca
      
                    }).then(response=>{
                      if(response.data.status =='OK'){
                       
                        Swal.fire({
                          title : 'Datacount', 
                          text : 'Registro removido com sucesso !', 
                          showConfirmButton : false, 
                          confirmButtonText : 'OK', 
                          showCancelButton : false, 
                          cancelButtonText : 'NÃO', 
                          icon:'success', 
                          timer : 2500
                        })
                        getListaDca(false)
                      }else{
                       
                          Swal.fire({
                            title : 'Datacount', 
                            text : 'Não foi possível remove !', 
                            showConfirmButton : false, 
                            confirmButtonText : 'OK', 
                            showCancelButton : false, 
                            cancelButtonText : 'NÃO', 
                            icon:'warning', 
                            timer : 2500
                          })
                      }
                    }).catch(error=>{
                      Swal.fire({
                        title : 'Datacount', 
                        text : 'Não foi possível remover!', 
                        showConfirmButton : false, 
                        confirmButtonText : 'OK', 
                        showCancelButton : false, 
                        cancelButtonText : 'NÃO', 
                        icon:'error', 
                        timer : 2500
                      })
                    })
                  }
                })
              }}/></TableCell>
            </TableRow>
            })
          }
          {
            registros && registros.length == 0 && <TableRow>
            <TableCell style ={{fontSize:"10px", width:"5%"}} colSpan={5}>* Não há informações para serem exibidas.</TableCell>

          </TableRow>
          }
            </TableBody>

          </Table>
          {
            atualizando && <LinearProgress color="secondary" />
          }
        </DialogContent>
        <DialogActions>
        <Button autoFocus  color="primary" variant='contained' onClick={()=>{
          if(usuario.dcasinclusao =='f'){
            Swal.fire({
              title : 'Datacount', 
              text : 'Você não tem permissão para criar novos DCA´s', 
              icon:'error', 
              showConfirmButton: false, 
              showCancelButton : false, 
              timer  : 1000
            })
            return;
          }
          Swal.fire({
            title : 'Datacount', 
            text : 'Deseja cadastrar um novo DCA agora ?', 
            showConfirmButton : true, 
            confirmButtonText : 'SIM', 
            showCancelButton : true, 
            cancelButtonText : 'NÃO', 
            icon:'question'
          }).then(resposta=>{
            if(resposta.isConfirmed){
              props.Conexao.post('dcaController/insertDca', {
                chave : props.chave, 
                idusuario : props.idusuario , 
                idempresa : props.idempresa, 
                idcliente :props.idcliente, 
                explode : 'T'

              }).then(response=>{
                if(response.data.status =='OK'){
                  
                  
                  getListaDca(true);
                }else{
                 
                    Swal.fire({
                      title : 'Datacount', 
                      text : 'Não foi possível cadastrar !', 
                      showConfirmButton : false, 
                      confirmButtonText : 'OK', 
                      showCancelButton : false, 
                      cancelButtonText : 'NÃO', 
                      icon:'warning', 
                      timer : 2500
                    })
                }
              }).catch(error=>{
                Swal.fire({
                  title : 'Datacount', 
                  text : 'Não foi possível cadastrar !', 
                  showConfirmButton : false, 
                  confirmButtonText : 'OK', 
                  showCancelButton : false, 
                  cancelButtonText : 'NÃO', 
                  icon:'error', 
                  timer : 2500
                })
              })
            }
          })
        }}>
            novo dca
          </Button>
          <Button variant='contained' autoFocus onClick={handleClose} color="secondary">
            fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
