import React, {Fragment} from 'react'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Tooltip from '@material-ui/core/Tooltip'
import WarningIcon from '@material-ui/icons/Warning';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {Typography} from '@material-ui/core'

import NotificationsIcon from '@material-ui/icons/Notifications';

const DisplayLinearTonerRotuled = (props) =>{

    const renderSigla = (sigla)=>{
        switch(sigla){
            case 'C':return <div 
                                style={{fontSize:'0.7vw', 
                                        backgroundColor : '#0D47A1', 
                                        borderRadius :'3px', 
                                        color: '#fff'
                                     }}> 
                                {props.sigla}
                            </div>
            case 'M':return <div 
                                style={{fontSize:'0.7vw', 
                                        backgroundColor : '#D32F2F', 
                                        borderRadius :'3px', 
                                        color: '#fff'
                                    }}> 
                                {props.sigla}
                            </div>

            case 'Y':return <div 
                                style={{fontSize:'0.7vw', 
                                        backgroundColor : '#F57F17', 
                                        borderRadius :'3px', 
                                        color: '#fff'
                                    }}> 
                                {props.sigla}
                                </div>

            case 'K':return <div 
                                style={{fontSize:'0.7vw', 
                                        backgroundColor : '#000', 
                                        borderRadius :'3px', 
                                        color: '#fff'
                                    }}> 
                                {props.sigla}
                                </div>
        }
    }

    return props.small ? 
            <Tooltip title ={props.value+'%'}>
                <div style={{width:'100%', padding:'1px', backgroundColor:"#d9d9d9", height:'10px', borderRadius:'2px', }} >
                    <div  style = {{width:props.value +"%", height:'100%', backgroundColor:props.color, borderRadius:'2px'}}></div>
                </div>
            </Tooltip> : <div className='display-linear-toner' >
            <div className='display-toner-container' >    
                <div className='display-toner-progress-nivel'>{props.value ? <div className={'display-toner-progress-'+props.rotulo} style = {{width:(props.value >= 0 && props.value <= 100) ? props.value : 0 +"%"}}></div> : <div></div>}</div>
                <div className='display-toner-span'><Typography variant='caption' style ={{fontSize:"9px"}}>{props.value >= 0 && props.value <= 100 ? props.value+'%' : 'n/d'}</Typography></div>
            </div>
        {(props.sigla) ?
        <div className='display-toner-container-alert' > 
            {
                renderSigla(props.sigla)
            }    
        </div> : null}

        </div>
}


export default DisplayLinearTonerRotuled;
