import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
//import Conexao from '../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip, Grid, TextField, Checkbox, Divider, CircularProgress} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';

var md5 = require('md5');



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalNovoUsuario = (props) => {
    const [open, setOpen] = React.useState(false)
    const {idempresa, idrevenda, razaosocial} = props.empresa
    const {Conexao} = props

    const [codigoRevenda, setCodigoRevenda] = useState(-1);
    const [codigoEmpresa, setCodigoEmpresa] = useState(-1);
    const [nomeCompleto, setNomeCompleto] = useState('');
    const [email, setEmail]= useState('');
    const [senha, setSenha]= useState('');
    const [confirmacaoSenha, setConfirmacaoSenha] = useState('')
    const [acessoClientes, setAcessoClientes]= useState(false);
    const [inclusaoClientes, setInclusaoClientes]= useState(false);
    const [edicaoClientes, setEdicaoClientes]= useState(false);
    const [exclusaoClientes, setExclusaoClientes]= useState(false);
    const [acessoCollector, setAcessoCollector]= useState(false);
    const [inclusaoCollector, setInclusaoCollector]= useState(false);
    const [edicaoCollector, setEdicaoCollector]= useState(false);
    const [exclusaoCollector, setExclusaoColletor] = useState(false);
    const [collectorMonitorToner, setCollectorMonitorToner]= useState(false);
    const [acessoDcas, setAcessoDcas]= useState(false);
    const [inclusaoDcas, setInclusaoDcas]= useState(false);
    const [edicaoDcas, setEdicaoDcas]= useState(false);
    const [exclusaoDcas, setExclusaoDcas]= useState(false);
    const [acessoEquipamentos, setAcessoEquipamentos]= useState(false);
    const [equipamentosDetalhes, setEquipamentosDetalhes]= useState(false);
    const [equipamentosAtivacao, setEquipamentosAtivacao]= useState(false);
    const [equipamentosDesativacao, setEquipamentosDesativacao] = useState(false);
    const [equipamentosHabilitarToner, setEquipamentosHabilitarToner] = useState(false);
    const [relatorios, setRelatorios]= useState(false);

    const [loading, setLoading] = useState(false);
    


    const handleClickOpen = () =>{
        setOpen(true)
    }

    const handleClose = () =>{
        setOpen(false)
    }
    useEffect(()=>{
        if(!acessoClientes){
            setInclusaoClientes(false)
            setEdicaoClientes(false)
            setExclusaoClientes(false)
        }
    },[acessoClientes])

    useEffect(()=>{
        if(!acessoCollector){
            setInclusaoCollector(false)
            setEdicaoCollector(false)
            setExclusaoColletor(false)
            setCollectorMonitorToner(false)
        }
    },[acessoCollector])

    useEffect(()=>{
        if(!acessoDcas){
            setInclusaoDcas(false)
            setEdicaoDcas(false)
            setExclusaoDcas(false)
            setEdicaoDcas(false)
        }
    },[acessoDcas])

    useEffect(()=>{
        if(!acessoEquipamentos){
            setEquipamentosDetalhes(false)
            setEquipamentosAtivacao(false)
            setEquipamentosDesativacao(false)
            setEquipamentosHabilitarToner(false)
        }
    },[acessoEquipamentos])

    useEffect(()=>{
        if(open){
            setNomeCompleto('')
            setEmail('')
            setSenha('')
            setConfirmacaoSenha('')

            setAcessoClientes(false)
            setInclusaoClientes(false)
            setEdicaoClientes(false)
            setExclusaoClientes(false)

            setAcessoCollector(false)
            setInclusaoCollector(false)
            setEdicaoCollector(false)
            setExclusaoColletor(false)
            setCollectorMonitorToner(false)

            setAcessoDcas(false)
            setInclusaoDcas(false)
            setEdicaoDcas(false)
            setExclusaoDcas(false)
            setEdicaoDcas(false)

            setAcessoEquipamentos(false)
            setEquipamentosDetalhes(false)
            setEquipamentosAtivacao(false)
            setEquipamentosDesativacao(false)
            setEquipamentosHabilitarToner(false)

            setRelatorios(false)


        }
    }, [open])

  const insertUsuario = () =>{
    if(nomeCompleto === ''){
        Swal.fire({
            title : 'Datacount', 
            text : 'Informe o nome do usuário!', 
            icon : 'warning', 
            showCancelButton : false, 
            showConfirmButton : false, 
            timer : 1500
        })
        return
    }
    if(email === ''){
        Swal.fire({
            title : 'Datacount', 
            text : 'Informe o email do usuário!', 
            icon : 'warning', 
            showCancelButton : false, 
            showConfirmButton : false, 
            timer : 1500
        })
        return
    }
    if(senha === ''){
        Swal.fire({
            title : 'Datacount', 
            text : 'Informe a senha do usuário!', 
            icon : 'warning', 
            showCancelButton : false, 
            showConfirmButton : false, 
            timer : 1500
        })
        return
    }

    if(senha.length < 4){
        Swal.fire({
            title : 'Datacount', 
            text : 'Sua senha deve ter no minimo 4 caracteres', 
            icon : 'warning', 
            showCancelButton : false, 
            showConfirmButton : false, 
            timer : 1500
        })
        return  
    }

    if(senha !== confirmacaoSenha){
        Swal.fire({
            title : 'Datacount', 
            text : 'A senha e a confirmação são diferentes!', 
            icon : 'warning', 
            showCancelButton : false, 
            showConfirmButton : false, 
            timer : 1500
        })
        return
    }
    if(acessoClientes === false && acessoCollector === false && acessoDcas === false && acessoEquipamentos === false){
        Swal.fire({
            title : 'Datacount', 
            text : 'Você deve habilitar a permissão de pelo menos um módulo!', 
            icon : 'warning', 
            showCancelButton : false, 
            showConfirmButton : false, 
            timer : 1500
        })
        return  
    }
    /*
    public function insertUsuarioRevenda($parametros){
        if(!isset($parametros->idrevenda) || empty($parametros->idrevenda)){
            $this->responseData(null, 'Revenda não informada');  
            exit();
        }
        echo ($parametros);
    }
    */
    setLoading(true)
    Conexao.post('usuariosController/insertUsuarioRevenda', {
        idrevenda : idrevenda, 
        idempresa : idempresa,
        nomeCompleto : nomeCompleto, 
        email : email, 
        senha : md5(senha), 
        acessoClientes : acessoClientes ? 't' : 'f',
        edicaoClientes : edicaoClientes ? 't' : 'f',
        inclusaoClientes : inclusaoClientes ? 't' : 'f',
        exclusaoClientes : exclusaoClientes ? 't' : 'f',

        acessoCollector : acessoCollector ? 't' : 'f',
        edicaoCollector : edicaoCollector ? 't' : 'f',
        inclusaoCollector : inclusaoCollector ? 't' : 'f',
        exclusaoCollector : exclusaoCollector ? 't' : 'f',
        collectorMonitorToner : collectorMonitorToner ? 't' : 'f',

        acessoDcas : acessoDcas ? 't' : 'f',
        inclusaoDcas : inclusaoDcas ? 't' : 'f',
        edicaoDcas : edicaoDcas ? 't' : 'f',
        exclusaoDcas : exclusaoDcas ? 't' : 'f',

        acessoEquipamentos : acessoEquipamentos ? 't' : 'f',
        equipamentosDetalhes : equipamentosDetalhes ? 't' : 'f',
        equipamentosAtivacao : equipamentosAtivacao ? 't' : 'f',
        equipamentosDesativacao : equipamentosDesativacao ? 't' : 'f',
        equipamentosHabilitarToner : equipamentosHabilitarToner ? 't' : 'f',
        relatorios : relatorios ? 't' : 'f'

    }).then(response=>{
        if(response.status == 200 && response.data.status == 'OK'){
            const temp = response.data.payload;
            props.setNovoUsuario(temp);
            setOpen(false)
            Swal.fire({
                title : 'Datacount', 
                text : 'Usuário cadastrado com sucesso!', 
                icon : 'success', 
                showCancelButton : false, 
                showConfirmButton : false, 
                timer : 1500
            })
        }else{
            setOpen(false)
            Swal.fire({
                title : 'Datacount', 
                text : 'Não foi possível cadastrar!', 
                icon : 'warning', 
                showCancelButton : false, 
                showConfirmButton : false, 
                timer : 1500
            })
        }
        setLoading(false)
    }).catch(error =>{
        setOpen(false)
        Swal.fire({
            title : 'Datacount', 
            text : 'Não foi possível cadastrar!', 
            icon : 'error', 
            showCancelButton : false, 
            showConfirmButton : false, 
            timer : 1500
        })
        setLoading(false)
    })
    
  }

  return (
    <div>
      <Tooltip title='Cadastrar um novo usuário'>
          <Button color='primary' onClick={handleClickOpen} variant='contained' style={{cursor:'pointer'}}>
              <AddIcon/>
              <Typography variant='caption'>Novo Usuário</Typography>
          </Button>
        
      </Tooltip>
      <Dialog 
        disableBackdropClick disableEscapeKeyDown
        hideBackdrop
        fullWidth
        maxWidth='sm'
        scroll='body'
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
         Cadatro de Novo Usuário
        </DialogTitle>
        <DialogContent dividers >
            <Grid container spacing={2}>
                <Grid item xs ={2}>
                    <Typography variant='caption'>Revenda</Typography>
                </Grid>
                <Grid item xs = {10}>
                    <TextField 
                        fullWidth
                        size='small'
                        variant="outlined"
                        value={razaosocial} />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs ={2}>
                    <Typography variant='caption'>Nome Completo</Typography>
                </Grid>
                <Grid item xs = {10}>
                    <TextField 
                        fullWidth
                        size='small'
                        variant="outlined"
                        value={nomeCompleto}
                        onChange={(e)=>setNomeCompleto(e.target.value)} />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs ={2}>
                    <Typography variant='caption'>Email</Typography>
                </Grid>
                <Grid item xs = {10}>
                    <TextField 
                        fullWidth
                        size='small'
                        variant="outlined"
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)} />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs ={2}>
                    <Typography variant='caption'>Senha</Typography>
                </Grid>
                <Grid item xs = {4}>
                    <TextField 
                        fullWidth
                        size='small'
                        variant="outlined"
                        value={senha}
                        type='password'
                        onChange={(e)=>setSenha(e.target.value)} />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs ={2}>
                    <Typography variant='caption'>Confirmação</Typography>
                </Grid>
                <Grid item xs = {4}>
                    <TextField 
                        fullWidth
                        size='small'
                        variant="outlined"
                        value={confirmacaoSenha}
                        type='password'
                        onChange={(e)=>setConfirmacaoSenha(e.target.value)} />
                </Grid>
            </Grid>
            <br/>
            <fieldset style ={{padding :'10px'}}>
            <legend style={{fontSize :'14px', textAlign:'center'}}>Permissões de Usuário</legend>
           
            <Grid container spacing={1}>
                <Grid item xs ={2}>
                    
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                   <Typography variant='caption'>Consultar</Typography>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Typography variant='caption'>Incluir</Typography>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Typography variant='caption'>Alterar</Typography>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Typography variant='caption'>Excluir</Typography>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Typography variant='caption'>Monitorar</Typography>
                </Grid>
            </Grid>

            <Grid container spacing={1} style={{backgroundColor : '#f3f3f3', paddingLeft:'10px', marginTop :'5px'}}>
                <Grid item xs ={2}>
                    <Typography variant='caption'>Clientes</Typography>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title ='Permite que o usuário acesse a aba Clientes'>
                    <Checkbox checked={acessoClientes} onChange={(e)=>setAcessoClientes(!acessoClientes)}/>
                    </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip  title ='Permite que o usuário faça incluão de novos clientes'>
                    <Checkbox checked={inclusaoClientes} disabled={!acessoClientes} onChange={(e)=>setInclusaoClientes(!inclusaoClientes)}/>
                    </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                  <Tooltip title ='Permite que o usuário faça alterações no cliente'>
                   <Checkbox checked={edicaoClientes} disabled={!acessoClientes}  onChange={(e)=>setEdicaoClientes(!edicaoClientes)}/>
                   </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title ='Permite que o usuário exclua clientes'>
                   <Checkbox checked={exclusaoClientes} disabled={!acessoClientes}  onChange={(e)=>setExclusaoClientes(!exclusaoClientes)}/>
                   </Tooltip>
                </Grid>
            </Grid>
            
            <Grid container spacing={1} style={{backgroundColor : '#f3f3f3', paddingLeft:'10px', marginTop :'5px'}}>
                <Grid item xs ={2}>
                    <Typography variant='caption'>Licenças</Typography>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title ='Permite que o usuario acesse a aba Collector'>
                    <Checkbox checked={acessoCollector} onChange={(e)=>setAcessoCollector(!acessoCollector)}/>
                    </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title='Permite que o usuário inclua novas licenças de coleta'>
                    <Checkbox checked={inclusaoCollector} disabled={!acessoCollector} onChange={(e)=>setInclusaoCollector(!inclusaoCollector)}/>
                    </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title ='Permite que o usuário faça alterações na licença de coleta'>
                   <Checkbox checked={edicaoCollector} disabled={!acessoCollector} onChange={(e)=>setEdicaoCollector(!edicaoCollector)}/>
                   </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title = 'Permite que o usuário exclua licenças de coleta'>
                   <Checkbox checked={exclusaoCollector} disabled={!acessoCollector} onChange={(e)=>setExclusaoColletor(!exclusaoCollector)}/>
                   </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                <Tooltip title ='Habilita o monitoramento e geração de alertas de toner a nível global, ou seja este valor se aplicará a todos os equipamentos dessa licença a menos que o equipamento nao esteja
                    configurado de forma individual (para que o modo individual funcione esta opção precisa estar marcada)'>
                    <Checkbox checked={collectorMonitorToner} disabled={!acessoCollector} onChange={(e)=>setCollectorMonitorToner(!collectorMonitorToner)}/>
                    </Tooltip>
                 </Grid>
            </Grid>
           
            <Grid container spacing={1} style={{backgroundColor : '#f3f3f3', paddingLeft:'10px', marginTop :'5px'}}>
                <Grid item xs ={2}>
                    <Typography variant='caption'>DCA</Typography>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title =' Permite que o usuário tenha acesso a aba DCAs'>
                    <Checkbox checked={acessoDcas} onChange={(e)=>setAcessoDcas(!acessoDcas)}/>
                    </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title ='Permite que o usuário inclua novos DCAs'>
                    <Checkbox checked={inclusaoDcas} disabled={!acessoDcas} onChange={(e)=>setInclusaoDcas(!inclusaoDcas)}/>
                    </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title ='Permite que o usuário faça alterações no parametros do DCA'>
                        <Checkbox checked={edicaoDcas} disabled={!acessoDcas}  onChange={(e)=>setEdicaoDcas(!edicaoDcas)}/>
                    </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title ='Permite que o usuário exclua DCA cadastrados e sem uso'>
                        <Checkbox checked={exclusaoDcas} disabled={!acessoDcas}  onChange={(e)=>setExclusaoDcas(!exclusaoDcas)}/>
                    </Tooltip>
                </Grid>
            </Grid>
          
            <Grid container spacing={1} style={{backgroundColor : '#f3f3f3', paddingLeft:'10px', marginTop :'5px'}}>
                <Grid item xs ={2}>
                    <Typography variant='caption'>Coletas</Typography>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title ='Permite que o usuário acesse a aba Coletas, Descobertas, Inativos e Desabilitados'>
                    <Checkbox checked={acessoEquipamentos} onChange={(e)=>setAcessoEquipamentos(!acessoEquipamentos)}/>
                    </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                  <Tooltip title ='Permite que o usuário tenha acesso aos detalhes de uma coleta'>
                    <Checkbox checked={equipamentosDetalhes} disabled={!acessoEquipamentos} onChange={(e)=>setEquipamentosDetalhes(!equipamentosDetalhes)}/>
                    </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title = 'Permite que o usuário habilite (ative) o monitoramento do equipamento recem descoberto'>
                   <Checkbox checked={equipamentosAtivacao} disabled={!acessoEquipamentos} onChange={(e)=>setEquipamentosAtivacao(!equipamentosAtivacao)}/>
                   </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                <Tooltip title = 'Permite que o usuário habilite/desabilite um equipamento monitorado'>
                   <Checkbox checked={equipamentosDesativacao} disabled={!acessoEquipamentos} onChange={(e)=>setEquipamentosDesativacao(!equipamentosDesativacao)}/>
                   </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title ='Habilita o monitoramento e geração de alertas com base em níveis estabelecidos individualmente nos equipamentos coletados, sobrepondo as configurações
                     globais, mas para que isso funcione o monitoramento de toner precisa estar ativo na licença de coleta.'>
                    <Checkbox checked={equipamentosHabilitarToner} disabled={!acessoEquipamentos} onChange={(e)=>setEquipamentosHabilitarToner(!equipamentosHabilitarToner)}/>
                    </Tooltip>
                 </Grid>
            </Grid>
 

            <Grid container spacing={1} style={{backgroundColor : '#f3f3f3', paddingLeft:'10px', marginTop :'5px'}}>
                <Grid item xs ={2}>
                    <Typography variant='caption'>Relatórios</Typography>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                   <Tooltip title='Permite que o usuário acesse a aba Relatórios'>
                    <Checkbox checked={relatorios} onChange={(e)=>setRelatorios(!relatorios)}/>
                    </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                  
                    
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                 
                   
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    
                 
                </Grid>
            </Grid>
            </fieldset>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} autoFocus onClick={handleClose} variant='contained' color="secondary">
            Cancelar
          </Button>
          <Button disabled={loading} autoFocus onClick={handleClose} variant='contained' color="primary" onClick={()=>{
            Swal.fire({
                title : 'Datacount', 
                text : 'Deseja realmente incluir o registro?', 
                icon:'question', 
                showConfirmButton : true, 
                confirmButtonText : 'SIM', 
                showCancelButton : true, 
                cancelButtonText : 'Não'
            }).then(resposta=>{
                if(resposta.isConfirmed){
                    insertUsuario();
                }
            })
          }}>
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalNovoUsuario)