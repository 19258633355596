import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
//import Conexao from '../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Table, TableCell, TableHead, Tooltip, Grid, TextField, TableRow} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'
import Add from '@material-ui/icons/Add';
import DelIcon from '@material-ui/icons/DeleteForever'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalEnderecosColetasDca = (props) => {
    const [open, setOpen] = React.useState(false)
    const [endereco, setEndereco] = React.useState('')
    const [faixaEnderecos1, setFaixaEnderecos1] = React.useState('')
    const [faixaEnderecos2, setFaixaEnderecos2] = React.useState('')
    const [faixaEnderecos, setFaixaEnderecos] = React.useState([])



    useEffect(()=>{
      if(open){
        parseEnderecosColeta();
      }
    }, [open])


    const parseEnderecosColeta = () =>{
      
      if(props.enderecosColeta && props.enderecosColeta !== null){
        const lista = props.enderecosColeta.split(',');
        setFaixaEnderecos(lista)
      }
      

    }



    const handleClickOpen = () =>{
        setOpen(true)
    }

    const handleClose = () =>{
        setOpen(false)
    }
    const validateIPv4 = (ip) => {
      const ipv4Regex = /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]{1,2})(\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]{1,2})){3}$/;
      return ipv4Regex.test(ip);
    }
  
  return (
    <div>
      <Tooltip title='Cadastrar um novo veículo'>                              
        <Button onClick={handleClickOpen} variant='outlined' color='primary'>
            <SettingsApplicationsIcon /> 
            <Typography>Endereços Coleta</Typography>
        </Button>
      </Tooltip>
      
      <Dialog 
        disableBackdropClick disableEscapeKeyDown
        hideBackdrop
        fullWidth
        maxWidth='sm'
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
         <Typography>Lista de Endereços</Typography>
        </DialogTitle>
        <DialogContent dividers style = {{height:"500px"}}>
            <Grid container spacing={1}>
                <Grid item xs ={2}>
                    <Typography variant='subtitle1'>IP/Host</Typography>
                </Grid>
                <Grid item xs ={8}>
                    <TextField                        
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink:true}}
                        value={endereco}
                        onChange={(e)=>setEndereco(e.target.value)}
                        size='small'/>
                </Grid>
                <Grid item xs ={2}>
                    <Button fullWidth variant='contained' disabled ={endereco === ''} color='primary' onClick={()=>{
                      if(endereco.length < 4){
                        Swal.fire({
                          title : 'Datacount', 
                          text : 'O endereço IP/Hostname informado não é válido !', 
                          icon : 'error', 
                          showCancelButton:false, 
                          showConfirmButton : false, 
                          timer : 2000
                        })
                        return
                      }
                      Swal.fire({
                        title : 'Datacount', 
                        text : 'Deseja incluir este endereço na lista de coletas agora?',
                        icon : 'question', 
                        showConfirmButton:true, 
                        confirmButtonText : 'Sim', 
                        showCancelButton: true,
                        cancelButtonText:'Não'
                      }).then(resposta =>{
                        if(resposta.isConfirmed){
                          setFaixaEnderecos([...faixaEnderecos, endereco]);
                          setEndereco('')
                          setFaixaEnderecos1('')
                          setFaixaEnderecos2('')
                        }
                      })
                    }}><Add/></Button>
                </Grid>
            </Grid>

            <Grid container spacing={1}>
                <Grid item xs ={2}>
                    <Typography variant='subtitle1'>Faixa</Typography>
                </Grid>
                <Grid item xs ={4}>
                    <TextField                        
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink:true}}
                        value={faixaEnderecos1}
                        onChange={(e)=>setFaixaEnderecos1(e.target.value)}
                        size='small'/>
                </Grid>



                <Grid item xs ={4}>
                    <TextField                        
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink:true}}
                        value={faixaEnderecos2}
                        onChange={(e)=>setFaixaEnderecos2(e.target.value)}
                        size='small'/>
                </Grid>
                
                <Grid item xs ={2}>
                    <Button fullWidth variant='contained' disabled ={faixaEnderecos1 === '' || faixaEnderecos2 === ''} color='primary' onClick={()=>{
                      if(!validateIPv4(faixaEnderecos1)){
                        Swal.fire({
                          title : 'Datacount', 
                          text : 'O inicio da faixa informada não é válida !', 
                          icon : 'error', 
                          showCancelButton:false, 
                          showConfirmButton : false, 
                          timer : 2000
                        })
                        return
                      }

                      if(!validateIPv4(faixaEnderecos2)){
                        Swal.fire({
                          title : 'Datacount', 
                          text : 'O final da faixa informada não é válida !', 
                          icon : 'error', 
                          showCancelButton:false, 
                          showConfirmButton : false, 
                          timer : 2000
                        })
                        return
                      }
                      Swal.fire({
                        title : 'Datacount', 
                        text : 'Deseja incluir a faixa de endereços informada a lista de coleta?',
                        icon : 'question', 
                        showConfirmButton:true, 
                        confirmButtonText : 'Sim', 
                        showCancelButton: true,
                        cancelButtonText:'Não'
                      }).then(resposta =>{
                        if(resposta.isConfirmed){
                          
                          setFaixaEnderecos([...faixaEnderecos, '192.168.88.1']);
                          
                          setEndereco('')
                          setFaixaEnderecos1('')
                          setFaixaEnderecos2('')
                        }
                      })
                    }}><Add/></Button>
                </Grid>
            </Grid>
            <br/>
            <Table className='table table-striped table-hover table-small table-sm table-dark'>
                <TableHead>
                    <TableCell style ={{width :'90%'}} align='left'><Typography style={{fontSize:'11px'}}>ENDEREÇO / HOSTNAME</Typography></TableCell>

                    <TableCell style ={{width :'10%'}} align='center'><Typography style={{fontSize:'11px'}}>EXCLUIR</Typography></TableCell>
                </TableHead>
                {
                  faixaEnderecos && faixaEnderecos.length > 0 ? faixaEnderecos.filter(item => item.trim() !== '').map(function(faixa, i){
                    return <tr>
                    <td  style={{paddingLeft:'14px'}}><Typography style={{fontSize:'11px'}}>{faixa}</Typography></td>

                    <td align='center'><DelIcon style={{color: '#f00',fontSize:'18px', cursor :'pointer'}} onClick ={()=>{

                      setFaixaEnderecos(faixaEnderecos.filter((item, index) => index !== i));
                    }} /></td>
                  </tr>
                  }) : <tr><td colSpan={2} style={{fontSize :'12px'}}>* Não existe(m) registro(s).</td></tr>
                }
            </Table>
         
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1}>
            <Grid item xs ={6} align='rigth'>
              <Button autoFocus  color="secondary" variant='contained' onClick ={()=>{
                    Swal.fire({
                      title : 'Datacount', 
                      text : 'Deseja limpar toda a lista de endereços agora?',
                      icon : 'question', 
                      showConfirmButton:true, 
                      confirmButtonText : 'Sim', 
                      showCancelButton: true,
                      cancelButtonText:'Não'
                    }).then(resposta =>{
                      if(resposta.isConfirmed){
                        
                        setFaixaEnderecos([]);
                        
                        setEndereco('')
                        setFaixaEnderecos1('')
                        setFaixaEnderecos2('')
                      }
                    })
                  }}>
                <DelIcon /> Limpar lista
            </Button>
            </Grid>
            <Grid item xs = {3}>
                <Button autoFocus fullWidth onClick={handleClose} color="secondary" variant='contained'>
                  cancelar
                </Button>
            </Grid>
            <Grid item xs = {3}>
              <Button autoFocus fullWidth onClick={()=>{
                Swal.fire({
                  title : 'Datacount', 
                  text : 'Deseja realmente gravar as alterações feitas na lista de endereços de coleta?',
                  icon : 'question', 
                  showConfirmButton:true, 
                  confirmButtonText : 'Sim', 
                  showCancelButton: true,
                  cancelButtonText:'Não'
                }).then(resposta =>{
                  if(resposta.isConfirmed){
                    if(props.setEnderecosColeta){

                      props.setEnderecosColeta(faixaEnderecos.join(','))
                      
                    }
                    setOpen(false)
                  }
                })
              }} color="primary" variant='contained' >
                confirmar
              </Button>
            </Grid>
          </Grid>
        
          
          
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalEnderecosColetasDca)