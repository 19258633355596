import Conexao from './Conexao';


// Assume these helper functions are defined somewhere in your code
function calcularDiferencaDias(data, tipo = "dias") {
    // Implementation of calcularDiferencaDias
}

function filtrarDataAtual(dataAtual) {
    const currentDate = new Date();
    const datacoleta = new Date(dataAtual);
    console.log("dataColeta " + datacoleta.getTime() + ' Data Atual ' + currentDate.getTime())

    return dataAtual == currentDate 
}

function calcularDiferencaDiasCadastro(data, tipo = "dias") {
     
    var dataAtual = new Date();

    // Definir uma segunda data (substitua com a data desejada)
    var segundaData = new Date(data);
    
    // Calcular a diferença em milissegundos
    var diferencaEmMilissegundos =  dataAtual - segundaData;
    
    // Converter a diferença para dias
    var diferencaEmDias = Math.floor(diferencaEmMilissegundos / (1000 * 60 * 60 * 24));

    return diferencaEmDias
}

function calcularDiferencaEmMinutos(dataInicio) {
   // Calcule a diferença em milissegundos

   var dataFim = new Date();  // Isso usa a data e hora atuais
       
   var diferencaEmMilissegundos = Math.abs(dataFim - dataInicio);
 
   // Converta a diferença de milissegundos para minutos
   var diferencaEmMinutos = Math.floor(diferencaEmMilissegundos / 60000);

   console.log(diferencaEmMilissegundos)

   return diferencaEmMinutos;
}



export function getDataRepository(idempresa, dataType = 'aoVivo', forced = false) {
    
   
    return new Promise((resolve, reject) => {
        const horaInicial = sessionStorage.getItem('timeUltimaLeitura');

        if (horaInicial) {

            const diferencaEmMinutos = calcularDiferencaEmMinutos(new Date(horaInicial));

            if (diferencaEmMinutos >= 30) {

                sessionStorage.setItem('timeUltimaLeitura', new Date());

                buscarDadosRepository(idempresa)
                    .then(data => resolve(data))
                    .catch(error => reject(error));

            } else {

                const dataTemp = JSON.parse(sessionStorage.getItem('equipamentos'));

                resolve(dataTemp);

            }
        } else {

           

            buscarDadosRepository(idempresa)
                .then(data => resolve(data))
                .catch(error => reject(error));
        }
    });
}
export function getDataRepositoryForced(idempresa, dataType = 'aoVivo') {
    return new Promise((resolve, reject) => {
        buscarDadosRepository(idempresa)
        .then(data => resolve(data))
        .catch(error => reject(error))
    });
}

function buscarDadosRepository(idempresa, dias = 0) {

    return new Promise((resolve, reject) => {

        Conexao.post('coletasController/getContadoresData?action=todos', {
            idempresa: idempresa,
            idcliente: null,
            dias: dias,
        }).then(response => {

            if (response.data.status === 'OK') {

                const temp = response.data.payload;

                for (let i = 0; i < temp.length; i++) {


                    temp[i].dia = calcularDiferencaDiasCadastro(temp[i].datacoleta) ;  

                    temp[i].dias =  temp[i].dia <= 0 ? true : false  

                    temp[i].diasCadastro = calcularDiferencaDiasCadastro(temp[i].datacadastro + " 00:00:00", "horas");
                    
                    temp[i].selecionado = 'f';

                    
                }
                sessionStorage.setItem('timeUltimaLeitura', new Date());
                sessionStorage.setItem('equipamentos', JSON.stringify(temp));

                resolve(temp);

            } else {

                reject(new Error("Failed to fetch data from the repository"));
            }

        }).catch(error => {

            reject(error);

        });

    });

}

export function atualizarEquipamentoNovo(idsColeta, campo,  novoValor) {
    return new Promise((resolve, reject) => {
        // Obter os dados do sessionStorage
        const dadosSessionStorage = JSON.parse(sessionStorage.getItem('equipamentos'));

        // Verificar se os dados existem e se a lista de idcoleta não está vazia
        if (dadosSessionStorage && idsColeta.length > 0) {
            // Atualizar os valores para cada idcoleta na lista
            dadosSessionStorage.forEach(dado => {

                if (idsColeta.includes(dado.idcoleta)) {

                    switch(campo){
                        case 'ativarEquipamentoNovo':
                            dado.equipamentonovo = novoValor;
                        break;
                        case 'desativarEquipamento':
                            dado.equipamentonovo = novoValor;
                            dado.ativo = novoValor;
                        break;

                        case 'reativarEquipamento':                            
                            dado.ativo = novoValor;
                        break;
                    }
                    
                    
                }
            });

            // Salvar os dados atualizados no sessionStorage
            sessionStorage.setItem('equipamentos', JSON.stringify(dadosSessionStorage));

            // Resolvendo a Promise com os dados atualizados
            const temp = dadosSessionStorage.filter(item => item.equipamentonovo == 't' && item.ativo =='t')
            console.log(temp)
            resolve(temp);
        } else {
            // Se os dados ou a lista de idcoleta estiverem vazios, rejeitar a Promise
            reject(new Error('Dados inválidos ou lista de idcoleta vazia.'));
        }
    });
}

export function atualizarEquipamentos(idsColeta, campo,  novoValor) {
    return new Promise((resolve, reject) => {
        // Obter os dados do sessionStorage
        const dadosSessionStorage = JSON.parse(sessionStorage.getItem('equipamentos'));

        // Verificar se os dados existem e se a lista de idcoleta não está vazia
        if (dadosSessionStorage && idsColeta.length > 0) {
            // Atualizar os valores para cada idcoleta na lista
            dadosSessionStorage.forEach(dado => {

                if (idsColeta.includes(dado.idcoleta)) {

                    dado.ativo = novoValor;
                    
                    
                }
            });

            // Salvar os dados atualizados no sessionStorage
            sessionStorage.setItem('equipamentos', JSON.stringify(dadosSessionStorage));

            // Resolvendo a Promise com os dados atualizados
            const temp = dadosSessionStorage.filter(item => item.equipamentonovo == 'f' && item.ativo =='t')
           
            resolve(temp);
        } else {
            // Se os dados ou a lista de idcoleta estiverem vazios, rejeitar a Promise
            reject(new Error('Dados inválidos ou lista de idcoleta vazia.'));
        }
    });
}

export function atualizarEquipamentoDesabilitado(idsColeta, campo,  novoValor) {
    return new Promise((resolve, reject) => {
        // Obter os dados do sessionStorage
        const dadosSessionStorage = JSON.parse(sessionStorage.getItem('equipamentos'));

        // Verificar se os dados existem e se a lista de idcoleta não está vazia
        if (dadosSessionStorage && idsColeta.length > 0) {
            // Atualizar os valores para cada idcoleta na lista
            dadosSessionStorage.forEach(dado => {

                if (idsColeta.includes(dado.idcoleta)) {

                    switch(campo){
                        case 'desativarEquipamento':
                            dado.ativo = novoValor;
                        break;

                        case 'reativarEquipamento':                            
                            dado.ativo = novoValor;
                        break;
                    }
                    
                    
                }
            });

            // Salvar os dados atualizados no sessionStorage
            sessionStorage.setItem('equipamentos', JSON.stringify(dadosSessionStorage));

            // Resolvendo a Promise com os dados atualizados
            const temp = dadosSessionStorage.filter(item => item.ativo =='f')
            console.log(temp)
            resolve(temp);
        } else {
            // Se os dados ou a lista de idcoleta estiverem vazios, rejeitar a Promise
            reject(new Error('Dados inválidos ou lista de idcoleta vazia.'));
        }
    });
}

export function substituirColetaEditada(coleta) {
  
    return new Promise((resolve, reject) => {
        try {
            let dataTemp = null
            getStorageData().then(resp=>{
                dataTemp = resp;
                console.log(dataTemp)
                for( let i = 0 ; i < dataTemp.length; i++){

                    if(dataTemp[i].idcoleta ==  coleta.idcoleta){
                        console.log('existe')
                        dataTemp[i] = coleta;  
                    }
        
                   }
                   sessionStorage.setItem('equipamentos', JSON.stringify(dataTemp));
                   resolve(dataTemp);
                   
            }).catch(error=>{
                console.log('Erro ao atualizar coleta armazenada ' + error)
                reject(error);
            })
            
            resolve(dataTemp);
           
           

        } catch (error) {
            console.log(error)
            
        }
    });
}

export function getStorageData(coletas) {
    return new Promise((resolve, reject) => {
        try {
           
            resolve(JSON.parse(sessionStorage.getItem('equipamentos')));

        } catch (error) {
            // Handle potential JSON parsing errors
            reject(error);
        }
    });
}

export function updateStatusColeta(coletas, status) {
    return new Promise((resolve, reject) => {
        try {
            const dataTemp = JSON.parse(sessionStorage.getItem('equipamentos'));

           dataTemp.forEach(entry => {
            if (coletas.includes(entry.idcoleta)) {
                entry.ativo = status;
            }
        });
           sessionStorage.setItem('equipamentos', JSON.stringify(dataTemp));
           resolve(dataTemp);

        } catch (error) {
            // Handle potential JSON parsing errors
            reject(error);
        }
    });
}

export function inativarMonitoramento(coletas) {
    return new Promise((resolve, reject) => {
        try {
            console.log(coletas)
            const dataTemp = JSON.parse(sessionStorage.getItem('equipamentos'));

           dataTemp.forEach(entry => {
            if (coletas.includes(entry.idcoleta)) {
                entry.equipamentonovo = 'f';
                entry.monitoramentoativo = 'f';
                entry.ativo = 'f';
               
            }
        });

           sessionStorage.setItem('equipamentos', JSON.stringify(dataTemp));
            
           resolve(dataTemp);

        } catch (error) {
            // Handle potential JSON parsing errors
            reject(error);
        }
    });
}


export function listarClientesEquipamentos(array) {
    const conjuntoDeIdClientes = new Set();
    const resultado = array.reduce((result, objeto) => {
        const idCliente = objeto.idcliente;
        if (!conjuntoDeIdClientes.has(idCliente)) {
        conjuntoDeIdClientes.add(idCliente);
        result.push({ idcliente: idCliente, razaosocial: objeto.razaosocial });
        }
        return result;
    }, []);

    // Ordenar por razaosocial
    resultado.sort((a, b) => a.razaosocial.localeCompare(b.razaosocial));

    return resultado;
  }


// Example of using the getDataRepository as a Promise

