import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
//import Conexao from '../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip, Grid, TextField,
         Checkbox, Divider, CircularProgress} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import {  Brush as Pencil} from 'react-ionicons';
var md5 = require('md5');



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalEdicaoUsuario = (props) => {
    const [open, setOpen] = React.useState(false)
    const {idempresa, idrevenda, razaosocial} = props.empresa
    const {Conexao, Usuario, index} = props

    const [codigoRevenda, setCodigoRevenda] = useState(-1);
    const [codigoEmpresa, setCodigoEmpresa] = useState(-1);
    const [nomeCompleto, setNomeCompleto] = useState(Usuario.nomecompleto);
    const [email, setEmail]= useState(Usuario.email);
    const [senha, setSenha]= useState('');
    const [confirmacaoSenha, setConfirmacaoSenha] = useState('')
    const [acessoClientes, setAcessoClientes]= useState(Usuario.clientes =='t');
    const [inclusaoClientes, setInclusaoClientes]= useState(Usuario.clientesinclusao =='t');
    const [edicaoClientes, setEdicaoClientes]= useState(Usuario.clientesedicao =='t');
    const [exclusaoClientes, setExclusaoClientes]= useState(Usuario.clientesexclusao =='t');
    const [acessoCollector, setAcessoCollector]= useState(Usuario.collector =='t');
    const [inclusaoCollector, setInclusaoCollector]= useState(Usuario.collectorinclusao =='t');
    const [edicaoCollector, setEdicaoCollector]= useState(Usuario.collectoredicao =='t');
    const [exclusaoCollector, setExclusaoColletor] = useState(Usuario.collectorexcluxao =='t');
    const [collectorMonitorToner, setCollectorMonitorToner]= useState(Usuario.collectormonitorartoner =='t');
    const [acessoDcas, setAcessoDcas]= useState(Usuario.dcas =='t');
    const [inclusaoDcas, setInclusaoDcas]= useState(Usuario.dcasinclusao =='t');
    const [edicaoDcas, setEdicaoDcas]= useState(Usuario.dcasedicao =='t');
    const [exclusaoDcas, setExclusaoDcas]= useState(Usuario.dcasexclusao =='t');
    const [acessoEquipamentos, setAcessoEquipamentos]= useState(Usuario.equipametos =='t');
    const [equipamentosDetalhes, setEquipamentosDetalhes]= useState(Usuario.equipamentosdetalhes =='t');
    const [equipamentosAtivacao, setEquipamentosAtivacao]= useState(Usuario.equipamentosativacao =='t');
    const [equipamentosDesativacao, setEquipamentosDesativacao] = useState(Usuario.equipamentosdesativacao =='t');
    const [equipamentosHabilitarToner, setEquipamentosHabilitarToner] = useState(Usuario.equipamentoshabilitartoner =='t');
    const [relatorios, setRelatorios]= useState(Usuario.relatorios =='t');
    const [editarSenhaUsuario, setEditarSenhaUsuario] = useState(false)

    const [loading, setLoading] = useState(false);
    


    const handleClickOpen = () =>{
        if(props.disabled ){
            Swal.fire({
                title : 'Datacount', 
                text : 'Não é possível editar um usuário desabilitado!', 
                icon : 'warning', 
                showCancelButton : false, 
                showConfirmButton : false, 
                timer : 1500
            })
            return
        }
        setOpen(true)
    }

    const handleClose = () =>{
        setOpen(false)
    }
 

  

  const updateUsuarioRevenda = (index) =>{
    if(nomeCompleto === ''){
        Swal.fire({
            title : 'Datacount', 
            text : 'Informe o nome do usuário!', 
            icon : 'warning', 
            showCancelButton : false, 
            showConfirmButton : false, 
            timer : 1500
        })
        return
    }
    if(email === ''){
        Swal.fire({
            title : 'Datacount', 
            text : 'Informe o email do usuário!', 
            icon : 'warning', 
            showCancelButton : false, 
            showConfirmButton : false, 
            timer : 1500
        })
        return
    }
    if(editarSenhaUsuario){

        if(senha === ''){
            Swal.fire({
                title : 'Datacount', 
                text : 'Informe a senha do usuário!', 
                icon : 'warning', 
                showCancelButton : false, 
                showConfirmButton : false, 
                timer : 1500
            })
            return
        }
        if(senha.length < 4 ){
            Swal.fire({
                title : 'Datacount', 
                text : 'Sua senha deve ter no minimo 4 caracteres', 
                icon : 'warning', 
                showCancelButton : false, 
                showConfirmButton : false, 
                timer : 1500
            })
            return  
        }

        if(senha !== confirmacaoSenha){
            Swal.fire({
                title : 'Datacount', 
                text : 'A senha e a confirmação são diferentes!', 
                icon : 'warning', 
                showCancelButton : false, 
                showConfirmButton : false, 
                timer : 1500
            })
            return
        }
    }
    

    

    
    if(acessoClientes === false && acessoCollector === false && acessoDcas === false && acessoEquipamentos === false){
        Swal.fire({
            title : 'Datacount', 
            text : 'Você deve habilitar a permissão de pelo menos um módulo!', 
            icon : 'warning', 
            showCancelButton : false, 
            showConfirmButton : false, 
            timer : 1500
        })
        return  
    }
   
    /*
    public function updateUsuarioRevendaRevenda($parametros){
        if(!isset($parametros->idrevenda) || empty($parametros->idrevenda)){
            $this->responseData(null, 'Revenda não informada');  
            exit();
        }
        echo ($parametros);
    }
    */
    setLoading(true)
    Conexao.post('usuariosController/updateUsuarioRevenda', {
        idrevenda : idrevenda, 
        idempresa : idempresa,
        idusuario: Usuario.idusuario,
        nomeCompleto : nomeCompleto, 
        email : email, 
        senha : md5(senha), 
        acessoClientes : acessoClientes ? 't' : 'f',
        edicaoClientes : edicaoClientes ? 't' : 'f',
        inclusaoClientes : inclusaoClientes ? 't' : 'f',
        exclusaoClientes : exclusaoClientes ? 't' : 'f',

        acessoCollector : acessoCollector ? 't' : 'f',
        edicaoCollector : edicaoCollector ? 't' : 'f',
        inclusaoCollector : inclusaoCollector ? 't' : 'f',
        exclusaoCollector : exclusaoCollector ? 't' : 'f',
        collectorMonitorToner : collectorMonitorToner ? 't' : 'f',

        acessoDcas : acessoDcas ? 't' : 'f',
        inclusaoDcas : inclusaoDcas ? 't' : 'f',
        edicaoDcas : edicaoDcas ? 't' : 'f',
        exclusaoDcas : exclusaoDcas ? 't' : 'f',

        acessoEquipamentos : acessoEquipamentos ? 't' : 'f',
        equipamentosDetalhes : equipamentosDetalhes ? 't' : 'f',
        equipamentosAtivacao : equipamentosAtivacao ? 't' : 'f',
        equipamentosDesativacao : equipamentosDesativacao ? 't' : 'f',
        equipamentosHabilitarToner : equipamentosHabilitarToner ? 't' : 'f',
        relatorios : relatorios ? 't' : 'f',
        editarSenhaUsuario : editarSenhaUsuario ? 't' : 'f'

    }).then(response=>{
        if(response.status == 200 && response.data.status == 'OK'){
            Usuario.nomecompleto = nomeCompleto
            Usuario.email = email
            Usuario.senha = ""
            Usuario.clientes = acessoClientes
            Usuario.clientesinclusao = inclusaoClientes
            Usuario.clientesedicao = edicaoClientes
            Usuario.clientesexclusao = exclusaoClientes
            Usuario.collector = acessoCollector
            Usuario.collectorinclusao = inclusaoCollector
            Usuario.collectoredicao = edicaoCollector
            Usuario.collectorexcluxao = exclusaoCollector
            Usuario.collectormonitorartoner = collectorMonitorToner
            Usuario.dcas = acessoDcas
            Usuario.dcasinclusao = inclusaoDcas
            Usuario.dcasedicao = edicaoDcas
            Usuario.dcasexclusao = exclusaoDcas
            Usuario.equipametos = acessoEquipamentos
            Usuario.equipamentosdetalhes = equipamentosDetalhes
            Usuario.equipamentosativacao = equipamentosAtivacao
            Usuario.equipamentosdesativacao = equipamentosDesativacao
            Usuario.equipamentoshabilitartoner = equipamentosHabilitarToner
            Usuario.relatorios = relatorios
            props.updateUsuarioLista(Usuario)


            setOpen(false)
            Swal.fire({
                title : 'Datacount', 
                text : 'Usuário Alterado com sucesso!', 
                icon : 'success', 
                showCancelButton : false, 
                showConfirmButton : false, 
                timer : 1500
            })
        }else{
            setOpen(false)
            Swal.fire({
                title : 'Datacount', 
                text : 'Não foi possível alterar!', 
                icon : 'warning', 
                showCancelButton : false, 
                showConfirmButton : false, 
                timer : 1500
            })
        }
        setLoading(false)
    }).catch(error =>{
        setOpen(false)
        Swal.fire({
            title : 'Datacount', 
            text : 'Não foi possível alterar!', 
            icon : 'error', 
            showCancelButton : false, 
            showConfirmButton : false, 
            timer : 1500
        })
        setLoading(false)
    })
    
  }

  return (
    <div>

          <Button  color='primary' variant='outlined' onClick={handleClickOpen} style={{cursor:'pointer'}}>
              <Pencil/>
          </Button>
        
   
      <Dialog 
        disableBackdropClick disableEscapeKeyDown
        hideBackdrop
        fullWidth
        maxWidth='sm'
        scroll='body'
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
         Edição de Usuário
        </DialogTitle>
        <DialogContent dividers >
            <Grid container spacing={2}>
                <Grid item xs ={2}>
                    <Typography variant='subtitle1'>Revenda</Typography>
                </Grid>
                <Grid item xs = {10}>
                    <TextField
                        size='small' 
                        fullWidth
                        variant="outlined"
                        value={razaosocial} />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs ={2}>
                    <Typography variant='subtitle1'>Nome</Typography>
                </Grid>
                <Grid item xs = {10}>
                    <TextField
                        size='small' 
                        fullWidth
                        variant="outlined"
                        value={nomeCompleto}
                        onChange={(e)=>setNomeCompleto(e.target.value)} />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs ={2}>
                    <Typography variant='subtitle1'>Email</Typography>
                </Grid>
                <Grid item xs = {10}>
                    <TextField
                        size='small' 
                        fullWidth
                        variant="outlined"
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)} />
                </Grid>
            </Grid>

            

            <Grid container spacing={2}>
                <Grid item xs ={2}>
                    <Typography variant='subtitle1'>Senha</Typography>
                </Grid>
                <Grid item xs = {4}>
                    <TextField
                        size='small' 
                        fullWidth
                        variant="outlined"
                        value={senha}
                        type='password'
                        disabled={!editarSenhaUsuario}
                        onChange={(e)=>setSenha(e.target.value)} />
                </Grid>
                <Grid item xs ={4}>
                    
                    <Checkbox checked={editarSenhaUsuario} onChange={(e)=>setEditarSenhaUsuario(!editarSenhaUsuario)}/>
                    <Typography variant='caption'>Editar senha</Typography>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs ={2}>
                    <Typography variant='subtitle1'>Confirmação</Typography>
                </Grid>
                <Grid item xs = {4}>
                    <TextField
                        size='small' 
                        fullWidth
                        variant="outlined"
                        value={confirmacaoSenha}
                        type='password'
                        disabled={!editarSenhaUsuario}
                        onChange={(e)=>setConfirmacaoSenha(e.target.value)} />
                </Grid>
            </Grid>
            <br/>
           
            <fieldset style ={{padding :'10px'}}>
            <legend style={{fontSize :'14px', textAlign:'center'}}>Permissões de Usuário</legend>
           
            <Grid container spacing={1}>
                <Grid item xs ={2}>
                    
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                   <Typography variant='caption'>Consultar</Typography>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Typography variant='caption'>Incluir</Typography>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Typography variant='caption'>Alterar</Typography>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Typography variant='caption'>Excluir</Typography>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Typography variant='caption'>Monitorar</Typography>
                </Grid>
            </Grid>

            <Grid container spacing={1} style={{backgroundColor : '#f3f3f3', paddingLeft:'10px', marginTop :'5px'}}>
                <Grid item xs ={2}>
                    <Typography variant='caption'>Clientes</Typography>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title ='Permite que o usuário acesse a aba Clientes'>
                    <Checkbox checked={acessoClientes} onChange={(e)=>setAcessoClientes(!acessoClientes)}/>
                    </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip  title ='Permite que o usuário faça incluão de novos clientes'>
                    <Checkbox checked={inclusaoClientes} disabled={!acessoClientes} onChange={(e)=>setInclusaoClientes(!inclusaoClientes)}/>
                    </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                  <Tooltip title ='Permite que o usuário faça alterações no cliente'>
                   <Checkbox checked={edicaoClientes} disabled={!acessoClientes}  onChange={(e)=>setEdicaoClientes(!edicaoClientes)}/>
                   </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title ='Permite que o usuário exclua clientes'>
                   <Checkbox checked={exclusaoClientes} disabled={!acessoClientes}  onChange={(e)=>setExclusaoClientes(!exclusaoClientes)}/>
                   </Tooltip>
                </Grid>
            </Grid>
            
            <Grid container spacing={1} style={{backgroundColor : '#f3f3f3', paddingLeft:'10px', marginTop :'5px'}}>
                <Grid item xs ={2}>
                    <Typography variant='caption'>Licenças</Typography>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title ='Permite que o usuario acesse a aba Collector'>
                    <Checkbox checked={acessoCollector} onChange={(e)=>setAcessoCollector(!acessoCollector)}/>
                    </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title='Permite que o usuário inclua novas licenças de coleta'>
                    <Checkbox checked={inclusaoCollector} disabled={!acessoCollector} onChange={(e)=>setInclusaoCollector(!inclusaoCollector)}/>
                    </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title ='Permite que o usuário faça alterações na licença de coleta'>
                   <Checkbox checked={edicaoCollector} disabled={!acessoCollector} onChange={(e)=>setEdicaoCollector(!edicaoCollector)}/>
                   </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title = 'Permite que o usuário exclua licenças de coleta'>
                   <Checkbox checked={exclusaoCollector} disabled={!acessoCollector} onChange={(e)=>setExclusaoColletor(!exclusaoCollector)}/>
                   </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                <Tooltip title ='Habilita o monitoramento e geração de alertas de toner a nível global, ou seja este valor se aplicará a todos os equipamentos dessa licença a menos que o equipamento nao esteja
                    configurado de forma individual (para que o modo individual funcione esta opção precisa estar marcada)'>
                    <Checkbox checked={collectorMonitorToner} disabled={!acessoCollector} onChange={(e)=>setCollectorMonitorToner(!collectorMonitorToner)}/>
                    </Tooltip>
                 </Grid>
            </Grid>
           
            <Grid container spacing={1} style={{backgroundColor : '#f3f3f3', paddingLeft:'10px', marginTop :'5px'}}>
                <Grid item xs ={2}>
                    <Typography variant='caption'>DCA</Typography>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title =' Permite que o usuário tenha acesso a aba DCAs'>
                    <Checkbox checked={acessoDcas} onChange={(e)=>setAcessoDcas(!acessoDcas)}/>
                    </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title ='Permite que o usuário inclua novos DCAs'>
                    <Checkbox checked={inclusaoDcas} disabled={!acessoDcas} onChange={(e)=>setInclusaoDcas(!inclusaoDcas)}/>
                    </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title ='Permite que o usuário faça alterações no parametros do DCA'>
                        <Checkbox checked={edicaoDcas} disabled={!acessoDcas}  onChange={(e)=>setEdicaoDcas(!edicaoDcas)}/>
                    </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title ='Permite que o usuário exclua DCA cadastrados e sem uso'>
                        <Checkbox checked={exclusaoDcas} disabled={!acessoDcas}  onChange={(e)=>setExclusaoDcas(!exclusaoDcas)}/>
                    </Tooltip>
                </Grid>
            </Grid>

            <Grid container spacing={1} style={{backgroundColor : '#f3f3f3', paddingLeft:'10px', marginTop :'5px'}}>
                <Grid item xs ={2}>
                    <Typography variant='caption'>Coletas</Typography>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title ='Permite que o usuário acesse a aba Coletas, Descobertas, Inativos e Desabilitados'>
                    <Checkbox checked={acessoEquipamentos} onChange={(e)=>setAcessoEquipamentos(!acessoEquipamentos)}/>
                    </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                  <Tooltip title ='Permite que o usuário tenha acesso aos detalhes de uma coleta'>
                    <Checkbox checked={equipamentosDetalhes} disabled={!acessoEquipamentos} onChange={(e)=>setEquipamentosDetalhes(!equipamentosDetalhes)}/>
                    </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title = 'Permite que o usuário habilite (ative) o monitoramento do equipamento recem descoberto'>
                   <Checkbox checked={equipamentosAtivacao} disabled={!acessoEquipamentos} onChange={(e)=>setEquipamentosAtivacao(!equipamentosAtivacao)}/>
                   </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                <Tooltip title = 'Permite que o usuário habilite/desabilite um equipamento monitorado'>
                   <Checkbox checked={equipamentosDesativacao} disabled={!acessoEquipamentos} onChange={(e)=>setEquipamentosDesativacao(!equipamentosDesativacao)}/>
                   </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    <Tooltip title ='Habilita o monitoramento e geração de alertas com base em níveis estabelecidos individualmente nos equipamentos coletados, sobrepondo as configurações
                     globais, mas para que isso funcione o monitoramento de toner precisa estar ativo na licença de coleta.'>
                    <Checkbox checked={equipamentosHabilitarToner} disabled={!acessoEquipamentos} onChange={(e)=>setEquipamentosHabilitarToner(!equipamentosHabilitarToner)}/>
                    </Tooltip>
                 </Grid>
            </Grid>
       
     

            <Grid container spacing={1} style={{backgroundColor : '#f3f3f3', paddingLeft:'10px', marginTop :'5px'}}>
                <Grid item xs ={2}>
                    <Typography variant='caption'>Relatórios</Typography>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                   <Tooltip title='Permite que o usuário acesse a aba Relatórios'>
                    <Checkbox checked={relatorios} onChange={(e)=>setRelatorios(!relatorios)}/>
                    </Tooltip>
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                  
                    
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                 
                   
                </Grid>
                <Grid item xs = {2}  style={{textAlign:"center"}}>
                    
                 
                </Grid>
            </Grid>
            </fieldset>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} autoFocus onClick={handleClose} variant='contained' color="secondary">
            Cancelar
          </Button>
          <Button disabled={loading} autoFocus onClick={handleClose} variant='contained' color="primary" onClick={()=>{
            Swal.fire({
                title : 'Datacount', 
                text : 'Deseja realmente incluir o registro?', 
                icon:'question', 
                showConfirmButton : true, 
                confirmButtonText : 'SIM', 
                showCancelButton : true, 
                cancelButtonText : 'Não'
            }).then(resposta=>{
                if(resposta.isConfirmed){
                    updateUsuarioRevenda(index);
                }
            })
          }}>
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalEdicaoUsuario)