import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow, Badge, Checkbox} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/DeleteForever';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Template/TemplateRevenda';
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import IconeLocalizarClienteModal from '../../components/IconeLocalizarClienteModal'
import AddIcon from '@material-ui/icons/Apps';
import Fab from '@material-ui/core/Fab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Pagination from '@material-ui/lab/Pagination';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonAtivoInativo from '../../components/Comuns/ButtonAtivoInativo'
import ClienteAtivo from '@material-ui/icons/Domain';
import ClienteInativo from '@material-ui/icons/DomainDisabled';
import SearchIcon from '@material-ui/icons/Search';


import TablePagination from '@material-ui/core/TablePagination';
import CachedIcon from '@material-ui/icons/Cached'
import DisplayStatus from '../../components/DisplayStatus'

import TextFieldEmail from '../../components/TextFieldEmail'
import IconeNovoLocal from '@material-ui/icons/AddLocation'
import Iconfilter from '@material-ui/icons/FilterList'
import DesativarIcon from '@material-ui/icons/Block'
import AtivarIcon from '@material-ui/icons/Done'
import BuildIcon from '@material-ui/icons/Build'
import RemoveIcon from '@material-ui/icons/DeleteForever'
import DetalhesIcon from '@material-ui/icons/Assignment'



const NovasColetasDisponiveis = (props)=>{
    const controller ='clientesController';
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [descobertas, setDescobertas] = useState([])
    const [cliente, setCliente]= useState([])
    const [empresa, setEmpresa] = useState([])
    const [edicao, setEdicao] = useState([])
    const [selecionado, setSelecionado] = useState([])
    const [parametroFiltro, setParametroFiltro] = useState('ativos')
    const [filtro, setFiltro] = useState([])
    const [colunaFiltro, setColunaFiltro] = useState('Cliente')
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [paginas, setPaginas]= React.useState(1);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [checkdAll, setCheckedAll]= React.useState(false);
   const [descobertasFitro, setDescobertasFiltro]= useState([])
    const filtroClientes = [];

    




    const refRazaoSocial = useRef();

    const refStatus = useRef();


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    useEffect(()=>{
        getDataDescobertas();
    }, [page])
    useEffect(()=>{
        getDataDescobertas();
    }, [rowsPerPage])


    useEffect(()=>{
       console.log(props)
    }, [])

    useEffect(()=>{
        if(!openModal){
            setEdicao([])
        }
    }, [openModal])


    const getDataDescobertas = () =>{
        setLoading(true)
        Conexao.post('coletasController/getContadoresDescoberta', {
            idempresa : props.match.params.chaveEmpresa, 
            tipoFiltro :colunaFiltro, 
             filtro : '', 
            limit: rowsPerPage,
            offSet :  page
        }).then(response =>{
            
            if(response.status == 200){
               
                if(response.data.status == 'OK'){
                   setDescobertas(response.data.payload.dados);
                   setDescobertasFiltro(response.data.payload.dados)
                   
                   setPaginas(response.data.payload.registros)
                   
                }else{
                   setDescobertas([])
                   setDescobertasFiltro([])
                }
            }else{
            setDescobertas([])
            setDescobertasFiltro([])
            }
            setLoading(false)
        }).catch(error =>{
            setDescobertasFiltro([])
            setLoading(false)
            setDescobertas([])
        })
    }

    const getDataFilter = () =>{
        const palavraChave = refRazaoSocial.current.value;
        if(descobertas.length == 0){
            return
        }
        switch(colunaFiltro){
            case 'Serial':
                setDescobertasFiltro(descobertas.filter(function(equipamento) {
                    return equipamento.numeroserie.toLowerCase().includes(palavraChave.toLowerCase());
                    })
                    )

            break;
            case 'Marca':
                setDescobertasFiltro(descobertas.filter(function(equipamento) {
                    return equipamento.marca.toLowerCase().includes(palavraChave.toLowerCase());
                    })
                    )

            break;
            case 'Marca':
                setDescobertasFiltro(descobertas.filter(function(equipamento) {
                    return equipamento.marca.toLowerCase().includes(palavraChave.toLowerCase());
                    })
                    )

            break;
            case 'Modelo':
                setDescobertasFiltro(descobertas.filter(function(equipamento) {
                    return equipamento.modelo.toLowerCase().includes(palavraChave.toLowerCase());
                    })
                    )

            break;
            case 'Cliente':
                setDescobertasFiltro(descobertas.filter(function(equipamento) {
                    return equipamento.razaosocial.toLowerCase().includes(palavraChave.toLowerCase());
                    })
                    )

            break;

            case 'Endereço IP':
                setDescobertasFiltro(descobertas.filter(function(equipamento) {
                    return equipamento.enderecoip.toLowerCase().includes(palavraChave.toLowerCase());
                    })
                    )

            break;

            case 'Data de Cadastro':
                const parametroDataColeta = new Date(palavraChave);
                setDescobertasFiltro(descobertas.filter(function(equipamento) {
                    const parData2 = new Date(equipamento.datacadastro);
                    return parData2.getDate() == parametroDataColeta.getDate()
                    })
                    )

            break;
        }
    }
 


    return <Principal atualizar = {loading} breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">  
        <Typography>Você esta em:</Typography>          
            <Link color="inherit" to={'#'} >
                Novos Equipamentos Descobertos
            </Link>                
        </Breadcrumbs>
    }>
        <div >
        
        <Grid container spacing={1}>
            <Grid item xs ={7}>
               
            </Grid>
            <Grid item xs = {5}>
                <TablePagination
                    component="div"
                    count={paginas}
                    page={page}                
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    labelRowsPerPage='Linhas por página'
                    />
            </Grid>
        </Grid>
        </div>


   
    <Grid container spacing ={1} style={{ paddingTop: '5px', paddingBottom:'5px', marginTop:'30px'}}>
        <Grid item xs ={ 3} style={{paddingLeft : '1px', paddingRight : '1px'}}>
                <Tooltip title = 'Opções de Busca'>
                    <TextField
                        size='small'
                        variant='outlined'
                        fullWidth
                        onChange={()=>{
                            if(refRazaoSocial.current){
                                refRazaoSocial.current.value = ''
                                setDescobertasFiltro(descobertas)
                            }
                            
                        }}
                        select
                        onClick={(e)=>{
                            setColunaFiltro(e.target.value)
                        }}
                        SelectProps={{native:true}}
                            InputProps={{
                                startAdornment : <InputAdornment position='start'>
                                    <Typography>Buscar por: </Typography>
                                </InputAdornment>
                            }}
                        >
                        <option value='Cliente'>Cliente</option>
                        <option value='Data de Cadastro'>Data de Cadastro</option>
                        <option value = 'Endereço IP'>Endereço IP</option>
                        <option value = 'Serial'>Serial</option>                                                
                        <option value='Marca'>Marca</option>
                        <option value='Modelo'>Modelo</option>
                       
                    </TextField>
                    </Tooltip>
            </Grid>
        <Grid item xs = {4} >
            <TextField
                InputProps={{
                    startAdornment : <InputAdornment position='start'><Typography variant='caption'>Filtro: </Typography></InputAdornment>, 
                    endAdornment : 
                            (colunaFiltro != 'Data de Cadastro' && colunaFiltro != 'Data de Coleta') ?  <InputAdornment position='end'>
                           
                        <IconeLocalizarClienteModal 
                        disabled={colunaFiltro && colunaFiltro != 'Cliente'} renderButton={true} idusuario={3} idempresa={props.match.params.chaveEmpresa} selectCliente={(value)=>{
                            if(refRazaoSocial.current){
                                refRazaoSocial.current.value = value.razaosocial
                                getDataFilter()
                            }
                        }}/> 
                    </InputAdornment>: ''
                }}
                variant='outlined'
                size='small'
                fullWidth
                inputRef={refRazaoSocial}
                type={(colunaFiltro == 'Data de Cadastro') ? 'date' : ''}
                onKeyDown={(e)=>{
                    if(e.keyCode == 13){
                        getDataFilter()
                    }
                }}
                onChange={(e)=>{
                    if((colunaFiltro == 'Data de Cadastro')){
                        getDataFilter()
                    }
                }}
            />
        </Grid>
        <Grid item xs ={1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Tooltip title = 'Limpar filtro de pequisa'>
                <Button fullWidth variant = 'outlined'  style={{ backgroundColor:"#fff"}} onClick={()=>{
                 setDescobertasFiltro(descobertas)
                 refRazaoSocial.current.value = ''
                
                 
            }}><DesativarIcon/></Button></Tooltip>
        </Grid>
        <Grid item xs ={ 1} style={{ paddingRight : '1px'}}>
            <Tooltip title = 'Buscar registros atualizados'>
                <Button fullWidth variant = 'outlined'  style={{ backgroundColor:"#fff"}} onClick={()=>{
                 getDataDescobertas()
                 refRazaoSocial.current.value = ''
                
                 
            }}><CachedIcon/></Button></Tooltip>
        </Grid>
        
        
        <Grid item xs ={3} style={{}}>
            <Tooltip title ='Click aqui para habilitar o monitoramento deste(s) equipamento(s)'>
            <Badge color='primary' badgeContent={descobertasFitro.reduce((acumulado, item)=>{
                        if(item.selecionado =='t'){
                            return acumulado + 1;                       
                         }
                         return acumulado
                    },0)}>
            <Button 
                variant = 'outlined' 
                fullWidth style={{backgroundColor:"#fff", fontSize:'10px'}} 
                disabled={descobertasFitro.reduce((acumulado, item)=>{
                    if(item.selecionado =='t'){
                        return acumulado + 1;                       
                     }
                     return acumulado
                },0) == 0} onClick={()=>{
                    Swal.fire({
                        title : 'Datacount', 
                        text : 'Deseja HABILITAR o monitoramento para o(s) equipamento(s)?', 
                        icon : 'question', 
                        showConfirmButton : true, 
                        confirmButtonText : 'Sim', 
                        showCancelButton : true, 
                        cancelButtonText : 'Não'
                    }).then(resposta=>{
                        if(resposta.isConfirmed){
                            
                            Conexao.post('coletasController/habilitarListaMonitoramento?idempresa',{
                                listaColetas : descobertasFitro.filter(item =>item.selecionado =='t').map((item)=>{
                                    return item.idcoleta
                                })
                            }).then(response=>{
                                if(response.data.status =='OK'){
                                    Swal.fire({
                                        title : 'Datacount', 
                                        text:'Operação realizada com sucesso !', 
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer  : 1500, 
                                        icon : 'success'
                                    })
                                    getDataDescobertas()
                                    sessionStorage.removeItem('timeUltimaLeitura')
                                }else{
                                    Swal.fire({
                                        title : 'Datacount', 
                                        text:'Operação não realizada', 
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer  : 1500, 
                                        icon : 'warning'
                                    })
                                }
                            }).catch(error=>{
                                Swal.fire({
                                    title : 'Datacount', 
                                    text:'Ocorreu um erro inesperado !', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer  : 1500, 
                                    icon : 'error'
                                })
                            })
                        }
                    })
                    
                   
                }}>
                    <AtivarIcon /> Monitorar 
            </Button>
            </Badge>
            </Tooltip>
       
            <Tooltip title ='Click aqui para remover este(s) equipamento(s) definitivamente'>
                <Badge color='primary' badgeContent={descobertasFitro.reduce((acumulado, item)=>{
                        if(item.selecionado =='t'){
                            return acumulado + 1;                       
                         }
                         return acumulado
                    },0)}>
            <Button 
                variant = 'outlined' 
                fullWidth
                disabled={descobertasFitro.reduce((acumulado, item)=>{
                    if(item.selecionado =='t'){
                        return acumulado + 1;                       
                     }
                     return acumulado
                },0) == 0} 
                 style={{backgroundColor:"#fff", fontSize:'10px', marginLeft :'5px'}} 
                onClick={()=>{
                    Swal.fire({
                        title : 'Datacount', 
                        text : 'Deseja REMOVER definitivamente este(s) equipamento?', 
                        icon : 'question', 
                        showConfirmButton : true, 
                        confirmButtonText : 'Sim', 
                        showCancelButton : true, 
                        cancelButtonText : 'Não'
                    }).then(resposta=>{
                        if(resposta.isConfirmed){
                           
                            Conexao.post('coletasController/desativarListaColeta?idempresa',{
                                listaColetas : descobertasFitro.filter(item =>item.selecionado =='t').map((item)=>{
                                    return item.idcoleta
                                })
                            }).then(response=>{
                                if(response.data.status =='OK'){
                                    Swal.fire({
                                        title : 'Datacount', 
                                        text:'Operação realizada com sucesso !', 
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer  : 1500, 
                                        icon : 'success'
                                    })
                                  //  getClientes()
                                  sessionStorage.removeItem('timeUltimaLeitura')
                                }else{
                                    Swal.fire({
                                        title : 'Datacount', 
                                        text:'Operação não realizada', 
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer  : 1500, 
                                        icon : 'warning'
                                    })
                                }
                            }).catch(error=>{
                                Swal.fire({
                                    title : 'Datacount', 
                                    text:'Ocorreu um erro inesperado !', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer  : 1500, 
                                    icon : 'error'
                                })
                            })
                        }
                    })
                    
                   
                }}>
                    <LockIcon /> Remover
            </Button>
            </Badge>
            </Tooltip>
        </Grid>
        
    </Grid>
    
   <div className='div-form-container' style={{paddingTop : '0px!important'}}>
    <Grid container style = {{marginTop:"0px"}} spacing={1}>
        <Table  className='table table-bordered table-striped table-hover table-small table-sm' style ={{fontSize:'10px!important'}}>
            <TableHead>
                <TableCell align='center' style = {{width:"4%"}}><Typography><Checkbox checked={checkdAll} style={{padding:'px!important'}} color='primary' onClick={()=>{
                    const temp = descobertasFitro;
                    if(checkdAll){
                        setCheckedAll(false)
             
                        for(let i = 0; i < temp.length; i++){
                            temp[i].selecionado = 'f';
                        }
                        setDescobertasFiltro(temp)
                    }else{
                        setCheckedAll(true)
             
                        for(let i = 0; i < temp.length; i++){
                            temp[i].selecionado = 't';
                        }
                        setDescobertasFiltro(temp)
                    }
                    
                }}/></Typography> </TableCell>
                <TableCell align='center' style = {{width:"8%"}}><Typography variant='caption' style ={{cursor:'pointer', fontSize:'10px!important',fontWeight : colunaFiltro =='Endereço IP'? "bold" : ''}}>Endereço IP </Typography> </TableCell>
                <TableCell align='center' style = {{width:"6%"}}><Typography variant='caption' style ={{cursor:'pointer',fontSize:'10px!important',fontWeight : colunaFiltro =='Data de Cadastro'? "bold" : ''}}>Data </Typography> </TableCell>
                <TableCell align='left' style = {{width:"10%"}}><Typography variant='caption' style ={{cursor:'pointer',fontSize:'10px!important',fontWeight : colunaFiltro =='Serial'? "bold" : ''}}>Número Série</Typography> </TableCell>
                <TableCell align='left' style = {{width:"15%"}}><Typography variant='caption' style ={{cursor:'pointer',fontSize:'10px!important',fontWeight : colunaFiltro =='Marca'? "bold" : ''}}>Marca</Typography> </TableCell>
                <TableCell align='left' style = {{width:"25%"}}><Typography variant='caption' style ={{cursor:'pointer',fontSize:'10px!important',fontWeight : colunaFiltro =='Modelo'? "bold" : ''}}>Modelo</Typography> </TableCell>                
                <TableCell align='left' valign='middle' style = {{width:"30%"}}><Typography variant='caption' style ={{cursor:'pointer',fontSize:'10px!important',fontWeight : colunaFiltro =='Cliente'? "bold" : ''}}>Cliente</Typography> </TableCell>
                

            </TableHead>

            <TableBody>
           {
            descobertasFitro && descobertasFitro.map(function(descoberta, i){
                return <TableRow key ={i} onClick={()=>{
                    
                    if(selecionado && selecionado.idcliente == descoberta.idcliente  && selecionado.numeroserie == descoberta.numeroserie){
                        setSelecionado([])
                    }else{
                        setSelecionado(descoberta)
                    }
                }} >
                <TableCell align='center' style={{padding: '0px!important'}}>
                    <Checkbox style={{padding: '0px!important'}} checked = {descoberta.selecionado =='t'} onClick={()=>{
                    const temp = descobertasFitro
                    temp[i].selecionado = temp[i].selecionado == 't' ? 'f' : 't';
                    console.log(descobertasFitro)
                    setDescobertasFiltro(temp)
                 
                }}/></TableCell>
                <TableCell align='center' ><Typography variant='caption' style ={{fontSize:'10px!important'}}>{descoberta.enderecoip}</Typography></TableCell>
                <TableCell align='center' ><Typography variant='caption' style ={{fontSize:'10px!important'}}>{descoberta.datacadastro}</Typography></TableCell>
                <TableCell align='left' ><Typography variant='caption' style ={{fontSize:'10px!important'}}>{descoberta.numeroserie}</Typography></TableCell>
                <TableCell align='left'><Typography variant='caption' style ={{fontSize:'10px!important'}}>{descoberta.marca}</Typography></TableCell>
                <TableCell align='left' ><Typography variant='caption' style ={{fontSize:'10px!important'}}>{descoberta.modelo}</Typography></TableCell>                
                <TableCell align='left' ><Typography variant='caption' style ={{fontSize:'10px!important'}}>{descoberta.razaosocial}</Typography></TableCell>
                

            </TableRow>
            })
           }
            </TableBody>
           
              
        </Table>
    </Grid>
    </div>
   
   

    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(NovasColetasDisponiveis)