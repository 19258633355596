import * as React from 'react';
import { styled } from '@material-ui/styles';
import {TextField,   Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
    ,Switch,Checkbox, TableRow, Badge, Menu, Tooltip} from '@material-ui/core'

import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import IconeVisaoAtual from '@material-ui/icons/Search'
import ArchiveIcon from '@material-ui/icons/Archive';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconeOnLine from '@material-ui/icons/PowerSettingsNew'
import IconeOffLine from '@material-ui/icons/LinkOffOutlined'
import IconeDesabilitado from '@material-ui/icons/BlockOutlined'
import IconeRelatorio from '@material-ui/icons/Print'
import IconeDescobertas from '@material-ui/icons/Add'
import IconeParqueTotal from '@material-ui/icons/Apps'
import IconeFilter from '@material-ui/icons/FilterList'
import { FunnelSharp as FilterIcon } from 'react-ionicons'

const StyledMenu = styled((props) => (
  <Menu

    elevation={0}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 3,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: '#444',
    
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: '#444'
      },
    },
  },
}));

export default function BotaoMenuFiltros(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style ={{padding : '0px'}}>
      <Tooltip title ='Opções de Busca (Inativos, Sem Conexão, etc)'>
        <Button
          id="demo-customized-button1"
          aria-controls="demo-customized-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="outlined"
          disableElevation
          fullWidth
          onClick={handleClick} 
          style ={{backgroundColor : '#fff', border :'none',  height: '38px'}}
          endIcon={<KeyboardArrowDownIcon />}
        >
        <FilterIcon
          color={'#666'}
        />
        </Button>
      </Tooltip>
      <StyledMenu
        id="demo-customized-menu1"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button1',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <div style ={{textAlign:'center'}}>
          <Typography variant='subtitle1'>Opções de Busca</Typography>
        </div>
        <Divider/>
        <br/>

        <div style={{paddingLeft : '10px', paddingRight : '10px'}}>
        <Grid container spacing ={1}>
                            <Grid item xs ={12}>
                                <Checkbox color='primary' 
                                  checked={props.inativos} onChange={()=>{
                                  props.setInativos();
                                }}/>
                                <Typography  variant='caption'>Exibir equipamentos desativados?</Typography>                                                                                                      
                            </Grid>
                        </Grid>


                        <Grid container spacing ={1}>
                        <Grid item xs ={12}>
                                                                
                            <Checkbox color='primary'  checked={props.semConexao} onChange={()=>{
                                  props.setSemConexao();
                                }}/>
                            <Typography  variant='caption'>Ver desconectados?</Typography>
                                                
                        
                            </Grid>
                        </Grid>


                    <Grid container spacing ={1}>
                        <Grid item xs ={12}>
                        
                
                        <Checkbox className='chk-select-coleta' disabled={!props.semConexao}  checked={props.apenasDesconectados && props.semConexao} onChange={()=>{
                                  props.setApenasDesconectados();
                                }}/>
                        <Typography  variant='caption'>Apenas desconectados?</Typography>
                        
                        </Grid>
                    </Grid>
                    </div> 
      </StyledMenu>
    </div>
  );
}
