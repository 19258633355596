import React, {useState, useEffect, useRef, Fragment} from 'react'

import {TextField,   Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow, Badge} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link , useHistory } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ExtensionTwoToneIcon from '@material-ui/icons/ExtensionTwoTone';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import DisplayToner from '../../components/DisplayToner'
import DisplayLinearToner from '../../components/DisplayLinearToner'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'; //case 2 
import SettingsPowerTwoToneIcon from '@material-ui/icons/SettingsPowerTwoTone';//energsaving
import ReportProblemIcon from '@material-ui/icons/ReportProblem';//case 5
import PrintIcon from '@material-ui/icons/Print';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import HelpIcon from '@material-ui/icons/Help';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import TablePagination from '@material-ui/core/TablePagination';
import ModalDetalhesLeitura from './ModalDetalhesColeta'
import BlockIcon from '@material-ui/icons/Block';
import Alert from '@material-ui/lab/Alert'
import LinkIcon from '@material-ui/icons/Link';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ModalFullDetalhes from '../../components/ModalFull2'
import DetalhesColeta from './DetalhesColeta'
import GestureIcon from '@material-ui/icons/Gesture'
import DoorOpen from '@material-ui/icons/MeetingRoom'
import AccountTree from '@material-ui/icons/AccountTree';
/*
https://pt.stackoverflow.com/questions/277865/diferentes-timezones-em-uma-aplica%C3%A7%C3%A3o-com-postgresql
*/


let temporizador = null;

const ContadoresAoVivo = (props)=>{
    let history = useHistory();
    const classes = useStyles();
    const [idLeitura, setIdLeitura]= React.useState(-1);
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [pause, setPause] = useState(false)
  
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [coleta, setColeta] = useState([])
    const [filtro, setFiltro] = useState([])
 
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [ coletas, setColetas] = useState([])
    const [horaAtualizacao, setHoraAtualizacao] = useState('');


    const [page, setPage] = React.useState(2);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const refTipoDataPesquisa = React.useRef();
    const refDataInicial = React.useRef();
    const refDataFinal = React.useRef();
    const refPlaca = React.useRef();
    const refNumeroSerie = React.useRef();
    const refModelo = React.useRef();
    const refMarca = React.useRef();
    
    const refRazaoSocial = React.useRef();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        getData();
    }, [])

    useEffect(()=>{
        temporizador = setInterval(()=>{
            getData();
            
        }, 120000); 

        return ()=>{
            clearInterval(temporizador)
        }
    }, [])

    

    const clearForm = () =>{
        setPause(false)
        if(refTipoDataPesquisa.current){
            refTipoDataPesquisa.current.value  ='COLETA';
        }
        if(refDataInicial.current){
            refDataInicial.current.value  ='';
        }
        if(refDataFinal.current){
            refDataFinal.current.value  ='';
        }
        if(refPlaca.current){
            refPlaca.current.value  ='';
        }
        if(refNumeroSerie.current){
            refNumeroSerie.current.value  ='';
        }
        if(refModelo.current){
            refModelo.current.value  ='';
        }
        if(refMarca.current){
            refMarca.current.value  ='';
        }
        if(refRazaoSocial.current){
            refRazaoSocial.current.value  ='';
        }
     
        
        
        
        
        
    }
   
    const getData = () =>{
        if(pause || coleta.idcoleta){
            return;
        }
        getRegistros();
    }
    const getRegistros = () =>{
        
        
        setLoading(true)
        setHoraAtualizacao(renderHoraColeta())
        Conexao.post('coletasController/getContadoresAoVivo?action=vivo', {
            idempresa : props.match.params.chaveEmpresa, 
            tipodata : refTipoDataPesquisa.current ?  refTipoDataPesquisa.current.value : '', 
            datainicial : refDataInicial.current ?  refDataInicial.current.value : '', 
            datafinal : refDataFinal.current ?  refDataFinal.current.value : '', 
            placa : refPlaca.current ?  refPlaca.current.value : '', 
            numeroserie :refNumeroSerie.current ?  refNumeroSerie.current.value : '' , 
            modelo :refModelo.current ?  refModelo.current.value : '', 
            marca : refMarca.current ?  refMarca.current.value : '', 
            razaosocial  : refRazaoSocial.current ?  refRazaoSocial.current.value : '' 
        }).then(response=>{
            if(response.data.status == 'OK'){
                setColetas(response.data.payload)
            }else{
                setColetas([])
            }
            setLoading(false)
        }).catch(error =>{
            setColetas([])
            setLoading(false)
            Swal.fire({
                title : 'Printerconnect', 
                text : 'Sem conexão com servidor remoto !', 
                showCancelButton : true,
                cancelButtonText : 'Cancelar', 
                showConfirmButton :false, 
                icon : 'error'
            })
        });
    }

   
   const renderStatus = (status, ip, dias, ativo, painel = "") =>{
    if(ativo =='f'){
        return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><Tooltip title ={'Monitoramento Inativo'}><LinkOffIcon className={'icon-status-coleta5'}/></Tooltip> <Typography style = {{fontSize:"9px", marginTop:"0px"}}>Desabilitado</Typography></div>        
    }

    
    if(dias > 0){
        return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><Tooltip title ={'Sem comunicação há '+dias+' dia(s)'}><BlockIcon className={'icon-status-coleta5'}/></Tooltip> <Typography style = {{fontSize:"9px", marginTop:"0px"}}>SEM CONEXÃO</Typography></div>        
    }
    switch(status){
    case 1:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><HelpIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>DESCONHECIDO</Typography></div>
            
        case 2:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><PowerSettingsNewIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>ON LINE</Typography></div>
            
        case 3:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><SettingsPowerIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>ENERGY SAVING</Typography></div>
            
        case 4:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><PrintIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>IMPRIMINDO</Typography></div>

        case 5:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><Tooltip title={painel}><ReportProblemIcon className={'icon-status-coleta'+status}/></Tooltip><Typography style = {{fontSize:"9px", marginTop:"0px"}}>ALERTA</Typography></div>

        case 6:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><Tooltip title={painel}><NotificationsActiveIcon className={'icon-status-coleta'+status}/></Tooltip><Typography style = {{fontSize:"9px", marginTop:"0px"}}>ATENÇÃO</Typography></div>

        case 7:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><Tooltip title={painel}><GestureIcon className={'icon-status-coleta'+status}/></Tooltip><Typography style = {{fontSize:"9px", marginTop:"0px"}}>ATOLAMENTO</Typography></div>

        case 8:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><Tooltip title={painel}><DoorOpen className={'icon-status-coleta'+status}/></Tooltip><Typography style = {{fontSize:"9px", marginTop:"0px"}}>Porta Aberta</Typography></div>

        
        default:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><HelpIcon className={'icon-status-coleta'}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>DESCONHECIDO</Typography></div>
    }
        
   }

   const renderNivelToner = (nivel, cor, rotulo) =>{
        if(!nivel){
            return <DisplayToner width={60} heigth = {60} color = {cor} percentual={0} lineSize={5}  descricao={rotulo}/>
        }
        if(nivel < 0 ){
            return <DisplayToner width={60} heigth = {60} color = {cor} percentual={100} lineSize={6} descricao={rotulo} />
        }else{
            return <DisplayToner width={60} heigth = {60} color = {cor} percentual={nivel} lineSize={6}  descricao={rotulo}/>
        }
        
    
   }

   const renderHoraColeta = () =>{
    let today=new Date();
    let h=today.getHours();
    let m=today.getMinutes();
    let s=today.getSeconds();
    return h.toString().padStart(2,0)+":"+m.toString().padStart(2,0)+":"+s.toString().padStart(2,0);
   }
    return <Principal atualizar = {loading} >
       <Grid container spacing = {0} style ={{paddingBottom:"20px"}}>
            <Grid item xs = {7}><Typography></Typography>
           
            </Grid>
           
        </Grid>
        
        <Grid container spacing = {2}>

 
    
            <Grid item xs = {1}>
                <TextField
                    label ='Placa'
                    variant ='outlined'
                    fullWidth
                    size ='small'
                    InputLabelProps={{shrink : true}}  
                    inputRef={refPlaca}                  
                   />
            </Grid>
            <Grid item xs = {3}>
                <TextField
                    label ='Número Série'
                    variant ='outlined'
                    fullWidth
                    size ='small'
                    InputLabelProps={{shrink : true}} 
                    inputRef={refNumeroSerie}                   
                   />
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Marca'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}
                    inputRef={refMarca}
                    size ='small'
                    />                   
            </Grid>
            <Grid item xs = {4}>
                <TextField
                    label ='Modelo'
                    variant ='outlined'
                    fullWidth
                    size ='small'
                    InputLabelProps={{shrink : true}} 
                    inputRef={refModelo}                   
                   />
            </Grid>
            
            
            <Grid item xs = {2} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' onClick={()=>{
                    setPause(true)
                    getRegistros()
                    }}><SearchIcon/> Pesquisar</Button>
                <Button color='secondary' variant='contained' onClick={()=>{
                    clearForm()
                    getRegistros()
                    }}><ClearIcon/> Limpar</Button>
            </Grid>
        </Grid>
        <Grid container spacing = {2}>
        <Grid item xs = {6}>
                <TextField
                    label ='Razão Social'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}
                    inputRef={refRazaoSocial}
                    size ='small'
                    />                   
            </Grid>
            <Grid item xs = {4}>
                <TextField
                    label ='Setor'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}
                   
                    size ='small'
                    />                   
            </Grid>
            


        </Grid>
      
        <div>
            <Breadcrumbs aria-label="breadcrumb">            
                <Link color="inherit" to={"/home/revenda/"+props.match.params.idempresa} >
                    Home
                </Link>
            <Typography color="textPrimary">
            {
                pause ? <span style={{border:"solid 1px red", backgroundColor:"#fff!important", fontSize:"9px", borderRadius:"5px", padding:"5px", color:"red", backgroundColor:"#fff"}}>Atualizado em : {horaAtualizacao}</span>:<span style={{border:"solid 1px green", fontSize:"9px", borderRadius:"5px", padding:"5px", color:"green" , backgroundColor:"#fff!important", }}>Contadores ao vivo! atualizado em : {horaAtualizacao}</span>
            }
            </Typography>
            </Breadcrumbs>
            <span style = {{fontSize:"9px"}}>Existe(m) {coletas.length} equipamento(s) coletado(s).</span>
            
        </div> 
        <div className='div-form-container'>
        <Grid spacing={1}>
            <Table className='table table-bordered table-striped table-hover ' >
                <TableHead>
                    <TableCell align='center' style = {{width:"10%"}}>Status</TableCell>                
                    <TableCell align='center' style = {{width:"10%"}}>Atualização</TableCell>
                    <TableCell align='left' style = {{width:"50%"}}>Equipamento</TableCell>
                    <TableCell align='center' style = {{width:"20%"}}>Contadores</TableCell>
                    <TableCell align='center' style = {{width:"10%"}} >Suprimentos</TableCell>
                </TableHead>
                
                
                <TableBody>
                   
                {

                    coletas && coletas.map(function(coleta, i){
                        return <TableRow key = {'ROWCOL'+i} className={coleta.statussistema == 5 ? 'linha-coleta-alerta linha-coleta':'linha-coleta'}>
                        <TableCell align='center' style ={{paddingTop:"5px", paddingBottom:"5px"}}>{renderStatus(coleta.statussistema, coleta.enderecoip, coleta.dias, coleta.ativo, coleta.mensagempainel)}</TableCell>
                                     
                        <TableCell align='center' style ={{paddingTop:"5px", paddingBottom:"5px", paddingLeft:"1%", paddingRight:"1%"}}><Typography color='primary' variant='subtitle1' className='div-badge-info'>{coleta.horacoleta.substr(0, 8)}</Typography></TableCell>
                        <TableCell align='left' style ={{paddingTop:"5px", paddingBottom:"5px"}}>
                        <Typography color='primary' variant='caption'><strong >{coleta.razaosocial}</strong></Typography><br/>                         
                              
                    
                            <Typography color='primary' variant='caption' onClick = {()=>{  
                                setColeta(coleta)                           
                               // props.history.push({pathname : '/detalhes_Coleta/revenda/'+props.match.params.idempresa+'/empresa/'+props.match.params.chaveEmpresa+'/coleta/'+coleta.idcoleta, state : coleta});
                            }}><strong className='span-link'>{coleta.numeroserie}</strong><span style ={{fontSize:"10px", marginLeft:"5px"}}>{coleta.modelo}{' ('+coleta.marca+')'}</span>  {coleta.datacadastro == coleta.datacoleta && <span className='span-novo-item-coleta'>NOVO</span>} </Typography>
                            {coleta.monitorartoner=='t' && <div style={{color:"#444"}}><NetworkCheckIcon style={{color:"#00897b"}} /><span style ={{fontSize:"9px"}}>Alerta de suprimento <strong>GLOBAL </strong> 
                                habilitado : C{coleta.monitoramentociano}% M{coleta.monitoramentomagenta}% Y{coleta.monitoramentoamarelo}% K{coleta.monitoramentopreto}% </span> </div>}
                        </TableCell>
                        <TableCell align='center' style ={{paddingTop:"5px", paddingBottom:"5px"}}>
                            
                        <Typography variant='caption' className='conter-total'>{coleta.contadormetros ? coleta.contadormetros :  coleta.contadortotal}</Typography><br/>
                        <Typography variant='caption' className='conter-mono'>{coleta.contadormetros ? 'CM:' +coleta.contadorcentimetros  : '  PB:' + coleta.monototal }</Typography>
                        <Typography variant='caption' className='conter-color'>{coleta.contadormetros  ? ' - POL:' +coleta.contadorpolegadas  : ' - CL:' + coleta.colortotal }</Typography>
                        </TableCell>
                        <TableCell align='center' style ={{paddingTop:"5px", paddingBottom:"5px"}}>
                            <DisplayLinearToner value ={coleta.nivelciano ? coleta.nivelciano : 0} rotulo = 'ciano' alerta={coleta.monitorartoner =='t' && coleta.alertaciano == 't' } nivelAlerta={coleta.nivelalertaciano}/>
                            <DisplayLinearToner value ={coleta.nivelmagenta ? coleta.nivelmagenta : 0} rotulo='magenta' alerta={coleta.monitorartoner =='t' && coleta.alertamagenta == 't'} nivelAlerta={coleta.nivelalertamagenta}/> 
                            <DisplayLinearToner value ={coleta.nivelamarelo ? coleta.nivelamarelo : 0} rotulo ='amarelo' alerta={coleta.monitorartoner =='t' && coleta.alertaamarelo == 't'} nivelAlerta={coleta.nivelalertaamarelo}/>
                            <DisplayLinearToner value ={coleta.nivelpreto ? coleta.nivelpreto : 0} rotulo = 'preto' alerta={coleta.monitorartoner =='t' &&  coleta.alertapreto == 't'} nivelAlerta={coleta.nivelalertapreto}/>
                            
                            { /*<div className="div-container-nivel-toner">
                                <div className="div-container-cirular-nivel">{renderNivelToner(coleta.nivelcian, "#304ffe", 'C')}</div>
                                <div className="div-container-cirular-nivel">{renderNivelToner(coleta.nivelamarelo, "#b71c1c", 'Y')}</div> 
                                <div className="div-container-cirular-nivel">{renderNivelToner(coleta.nivelmagenta, "#ffa000", 'M')}</div>
                                <div className="div-container-cirular-nivel">{renderNivelToner(coleta.nivelpreto, "#000", 'K')}</div>
                         </div>*/}
                        </TableCell>
                    </TableRow>
                    })
                    
                    }
                    {
                        coletas.length == 0 && <TableRow>
                        <TableCell colSpan={6} align='left'>
                            <Alert severity='info'>{loading ?'Aguarde... buscando informações.' : '* Não existem contadores ao vivo neste momento !'}</Alert>
                        </TableCell>
                       
                        </TableRow>
                    }
                </TableBody>
            </Table>
            <Typography variant='caption' style ={{fontSize:"9px"}}>* Visualização de contadores coletados com diferença de até 1 (uma) hora.</Typography>
        </Grid>                     
        </div>
        <div className='fabIncluir'>
           
        </div>
      <ModalDetalhesLeitura idleitura = {idLeitura} closeModal = {()=>setIdLeitura(-1)}/>
      <ModalFullDetalhes 
        showModal={coleta && coleta.idcoleta} 
        marginTop
        setOpen = {()=>setColeta([])}
        titulo ={coleta.idcoleta && coleta.numeroserie+' - '+coleta.modelo}>
            <DetalhesColeta state = {coleta}/>
        </ModalFullDetalhes>
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(ContadoresAoVivo)