import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'

import {withRouter} from 'react-router-dom'
import Principal from '../Template/TemplateRevenda';
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import RemoveIcon from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PersonIcon from '@material-ui/icons/Person';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import Build from '@material-ui/icons/Build'



const DcaInventory = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [colunaFiltro, setColunaFiltro] = useState('Cliente')
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [filtro, setFiltro] = useState([])
    const [dataTecnico, setDataTecnico] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dcas, setDcas] = useState([])
    const [dcasFiltro, setDcasFiltro] = useState([])
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [ registros, setRegistros] = useState(0)
    const refTipoDataPesquisa = React.createRef();
    const refDataPesquisa = React.createRef();
    const refChaveInstalacao = React.createRef();
    const refRazaoSocial = React.createRef();


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

  

    useEffect(()=>{
        getDcas();
    }, [page])


    const getDcas = () =>{
        setLoading(true)
        Conexao.post('dcaController/getDca', {
            idusuario : props.match.params.idempresa, 
            idempresa : props.match.params.chaveEmpresa, 
            tipoData : refTipoDataPesquisa.current ? refTipoDataPesquisa.current.value : '', 
            dataPesquisa : refDataPesquisa.current ? refDataPesquisa.current.value : '', 
            chaveInstalacao : refChaveInstalacao.current ? refChaveInstalacao.current.value : '', 
            razaoSocial : refRazaoSocial.current ? refRazaoSocial.current.value : '', 
            limit: rowsPerPage,
            offSet :  page

        }).then( response =>{
            if(response.data.status == 'OK'){
                setDcas(response.data.payload.dados)
                setDcasFiltro(response.data.payload.dados)
                setRegistros(response.data.payload.registros)
            }else{
                setDcas([])
                setDcasFiltro([])
            }
            setLoading(false)
        }).catch( error =>{
            setDcas([])
            setDcasFiltro([])
            setLoading(false)
        });
    }

   

    const renderUltimoAcesso = (acesso) =>{
        if(acesso == 'printerconnect-app-collector'){
            return <PersonIcon color='primary' />
        }else{
            return <SettingsApplicationsIcon  color='primary'/>
        }
    }

    return <Principal atualizar = {loading} getData={getDcas} breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">  
        <Typography>Você esta em:</Typography>          
            <Link color="inherit" to={'#'} >
                Inventory DCA
            </Link>                
        </Breadcrumbs>
    }>
            <div style={{paddingLeft : '10px', paddingTop : '5px', paddingBottom : '5px',marginTop : '10px', }}>
            <Grid container spacing = {2} style={{paddingBottom:'0px!important', paddingTop : '0px!important'}}>            
                <Grid item xs = {7}></Grid>
                <Grid item xs = {5}>
               
                </Grid>
            </Grid>
        </div>
        
      
        
        <Grid container spacing ={0} style={{ paddingTop: '5px', paddingBottom:'5px', marginTop:'20px'}}>
        <Grid item xs = {3} >
            <TextField
                InputProps={{
                    startAdornment : <InputAdornment position='start'><Typography variant='caption'>{colunaFiltro.toString().toUpperCase()}</Typography></InputAdornment>
                }}
                variant='outlined'
                size='small'
                fullWidth
                inputRef={refRazaoSocial}
                
                onKeyDown={(e)=>{
                    if(e.keyCode == 13){
                        const palavraChave = refRazaoSocial.current.value;
                        switch(colunaFiltro){
                            case 'Chave':
                                setDcasFiltro(dcas.filter(function(dca) {
                                    return dca.chavecoletor.toLowerCase().includes(palavraChave.toLowerCase());
                                  })
                                 )
        
                            break;
                            case 'Cliente':
                                setDcasFiltro(dcas.filter(function(dca) {
                                    return dca.razaosocial.toLowerCase().includes(palavraChave.toLowerCase());
                                  })
                                 )
        
                            break;
                            case 'Criação':
                                setDcasFiltro(dcas.filter(function(dca) {
                                    return dca.datacadastro.toLowerCase().includes(palavraChave.toLowerCase());
                                  })
                                 )
        
                            break;
           
                            case 'Instalação':
                                setDcasFiltro(dcas.filter(function(dca) {
                                    return dca.dataativacao.toLowerCase().includes(palavraChave.toLowerCase());
                                  })
                                 )
        
                            break;
        
                            case 'Acesso':
                                setDcasFiltro(dcas.filter(function(dca) {
                                    return dca.dataatualizacao.toLowerCase().includes(palavraChave.toLowerCase());
                                  })
                                 )
        
                            break;
                            case 'HostName':
                                dcasFiltro(dcas.filter(function(dca) {
                                    return dca.hostname.toLowerCase().includes(palavraChave.toLowerCase());
                                  })
                                 )
        
                            break;
                        }
                    }
                }}
            />
        </Grid>
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Tooltip title = 'Buscar rregistros'>
                <Button variant = 'outlined'  style={{height:'100%', backgroundColor:"#fff"}} onClick={()=>{
                
                refRazaoSocial.current.value = ''
                getDcas()
                 
            }}><SearchIcon/></Button></Tooltip>
        </Grid>
        </Grid>
       <div className='div-form-container'>
        <Grid container style = {{marginTop:"10px"}} spacing={1}>
            <Table  className='table table-bordered table-striped table-hover table-small table-sm'>
                <TableHead>
                 
                    <TableCell align='left' style = {{width:"10%"}}>
                        <Typography  >Chave</Typography>
                    </TableCell>
                    <TableCell align='left' style = {{width:"20%"}}>
                    <Typography  >Cliente</Typography>
                    </TableCell>       
                    <TableCell align='center' style = {{width:"8%"}}>
                    <Typography  >Criação</Typography>
                    </TableCell>
                    <TableCell align='center' style = {{width:"8%"}}>
                    <Typography >Instalação</Typography>
                    </TableCell>
                    <TableCell align='center' style = {{width:"8%"}}>
                    <Typography  >Atualização</Typography>
                    </TableCell>
                   

                    <TableCell align='center' style = {{width:"10%"}}>
                    <Typography  >HostName</Typography>
                    </TableCell>
                    <TableCell align='center' style = {{width:"10%"}}>
                    <Typography  >Agente</Typography>
                    </TableCell>
                    <TableCell align='center' style = {{width:"8%"}}>
                    <Typography  >Endereço IP</Typography>
                    </TableCell>

                    <TableCell align='center' style = {{width:"8%"}}>Excluir</TableCell>
                </TableHead>
                <TableBody>
                   
                   {
                       dcasFiltro && dcasFiltro.map(function(dca, i){
                           return <TableRow >
                           <TableCell align='left' style={{fontSize:"10px"}}>{dca.chavecoletor}</TableCell>
                           <TableCell align='left' style={{fontSize:"10px"}}>{dca.razaosocial}</TableCell>
              
                            <TableCell align='center'>
                               <LabeData fontData='10px'  data = {dca.datacadastro} hora ={dca.horacadastro.substr(0,8)}  fontHora = {9}/>
                               </TableCell>
                            <TableCell align='center'>
                                <LabeData fontData='10px'  data = {dca.dataativacao && dca.dataativacao} hora ={dca.horaativacao && dca.horaativacao.substr(0,8)}  fontHora = {9}/>
                            </TableCell>
                            <TableCell align='center'>
                                <LabeData fontData='10px'  data = {dca.dataatualizacao && dca.dataatualizacao} hora ={dca.horaatualizacao && dca.horaatualizacao.substr(0,8)}  fontHora = {9}/>
                            </TableCell>
                                             
                            <TableCell align='center' style={{fontSize:"10px"}}>
                                <div>{dca.hostname}</div>
                               
                            </TableCell>
                            <TableCell align='center' style={{fontSize:"10px"}}>
                                
                                <div>{renderUltimoAcesso(dca.ultimoacessovia)}</div>
                            </TableCell>
                            <TableCell align='center' style={{fontSize:"10px"}}>
                            {dca.enderecoip}
                            </TableCell> 
                            <TableCell align='center' style={{fontSize:"10px"}}>
                            <Button variant='outlined' disabled={dca.dataatualizacao} color='secondary' onClick={()=>{
                                Swal.fire({
                                    title : 'Datacount', 
                                    text: 'Deseja remover esta chave de coleta?', 
                                    icon : 'question', 
                                    showConfirmButton : true, 
                                    confirmButtonText : 'Sim', 
                                    showCancelButton : true, 
                                    cancelButtonText : 'Não'
                                }).then(resp=>{
                                    if(resp.isConfirmed){
                                        setLoading(true)
                                        Conexao.post('dcaController/removeDca', {
                                            idusuario : dca.idusuario, 
                                            idempresa : dca.idempresa, 
                                            idcliente : dca.idcliente, 
                                            iddca : dca.iddca, 
                                
                                
                                        }).then( response =>{
                                            if(response.data.status == 'OK'){
                                              getDcas();
                                            }else{
                                                Swal.fire({
                                                    title : 'Datacount', 
                                                    text: 'Não foi possivel remover!', 
                                                    icon : 'warning', 
                                                    showConfirmButton : false, 
                                                    confirmButtonText : 'Sim', 
                                                    showCancelButton : false, 
                                                    cancelButtonText : 'Não'
                                                })
                                            }
                                            setLoading(false)
                                        }).catch( error =>{
                                            Swal.fire({
                                                title : 'Datacount', 
                                                text: 'Não foi possivel remover!', 
                                                icon : 'error', 
                                                showConfirmButton : false, 
                                                confirmButtonText : 'Sim', 
                                                showCancelButton : false, 
                                                cancelButtonText : 'Não'
                                            })
                                            setLoading(false)
                                        });
                                    }
                                })
                            }}><RemoveIcon /></Button>
                            </TableCell> 
                             
                       </TableRow>
                       })
                   }
                   {
                       dcas.length == 0  && <TableRow>
                       <TableCell colSpan={9} align='left'>* Não existe(m) registro(s)</TableCell>
                      
                   </TableRow>
                   }
                </TableBody>
            </Table>
        </Grid>   
        </div>                  
    
        <div className='fabIncluir'>
        {/*<ModalNovoDca idusuario={props.match.params.idempresa} idempresa={props.match.params.chaveEmpresa} getData = {()=>getDcas()}/>*/}
        </div>
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(DcaInventory)