import React, {useState, useEffect, useRef, Fragment} from 'react'

import {TextField,   Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow, Badge, Checkbox,Switch} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Block';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import AtivarIcon from '@material-ui/icons/Done'
import {Link , useHistory } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ExtensionTwoToneIcon from '@material-ui/icons/ExtensionTwoTone';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import LabeData from '../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import DisplayToner from '../../components/DisplayToner'
import DisplayLinearToner from '../../components/DisplayLinearToner'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'; //case 2 
import SettingsPowerTwoToneIcon from '@material-ui/icons/SettingsPowerTwoTone';//energsaving
import ReportProblemIcon from '@material-ui/icons/ReportProblem';//case 5
import PrintIcon from '@material-ui/icons/Print';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import HelpIcon from '@material-ui/icons/Help';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import SearchIcon from '@material-ui/icons/Search';

import TablePagination from '@material-ui/core/TablePagination';
import ModalDetalhesLeitura from './ModalDetalhesColeta'
import BlockIcon from '@material-ui/icons/Block';
import Alert from '@material-ui/lab/Alert'
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ModalFullDetalhes from '../../components/ModalFull2'
import DetalhesColeta from './DetalhesColeta'
import GestureIcon from '@material-ui/icons/Gesture'
import DoorOpen from '@material-ui/icons/MeetingRoom'
import CachedIcon from '@material-ui/icons/Cached'
import LinkOff from '@material-ui/icons/LinkOff'
import Slider from '@material-ui/core/Slider'
import FilterIcon from '@material-ui/icons/DateRange'
import FilterIcone2 from '@material-ui/icons/FilterList'
import DetailIcon from '@material-ui/icons/Apps'
import ModalHabilitaMonitorIndividual from './ModalMonitoramentoIndividual'
import IconeLocalizarClienteModal from '../../components/IconeLocalizarClienteModal'
import TemplateRevenda from '../Template/TemplateRevenda';
import SideBarGestaoColetas from './SideBarGestaoColetas'
import SideBarPadrao from './SideBarPadrao';
import {getDataRepository, updateStatusColeta, inativarMonitoramento} from '../../providers/Repository'




let temporizador = null;

const GestaoDesabilitados = (props)=>{
    let history = useHistory();
    const classes = useStyles();
    const [idLeitura, setIdLeitura]= React.useState(-1);
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [colunaFiltro, setColunaFiltro] = useState('Cliente')
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [filtro, setFiltro] = useState([])
    const [selecionado, setSelecionado]= useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [ coletas, setColetas] = useState([])
    const [ coleta, setColeta] = useState([])
    const [ registros, setRegistros] = useState(0)
    const [totalEquipamentosNovos, setTotalEquipamentosNovos]= useState(0)
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [totalRegistros, setTotalRegistros]= useState([])
    const [registrosFiltrados, setRegistrosFiltrados] = useState([])
    const [dias, setDias]= useState(0)
    const [chaveDca, setChaveDca]= useState('')
    const [checkdAll, setCheckedAll]= React.useState(false);
    const {usuario} = props.location.state




    const refDataInicial = React.useRef();
   
    const refRazaoSocial = React.useRef();

    const [cliente, setCliente]= useState([])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        displayPage(registrosFiltrados, newPage, rowsPerPage)
        
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        displayPage(registrosFiltrados,0, event.target.value)
    };




    useEffect(()=>{
        
        setPage(0)
    }, [])


    useEffect(()=>{
        if(props.location.state){

            if(!props.location.state.filtrar){

                getData();
                
            }        
           
        }
       
    }, [page, rowsPerPage])



    useEffect(()=>{
        if(cliente.idcliente){
            getFilterData(cliente.razaosocial);
           
        }
       
    }, [cliente.idcliente])

    

    
    useEffect(()=>{
       if(props.location.state){
        if( props.location.state.filtrar){
            setCliente({
                idcliente: props.location.state.idcliente, 
                idempresa : props.location.state.idempresa, 
                razaosocial : props.location.state.razaosocial
            })
        }else{
            getData();
            setPage(0)  
        }
        
       
       }else{
        getData();
        setPage(0)
       }
    }, [])


    
    
    
    const getData = (periodoDias = 0) =>{

        setLoading(true)

        getDataRepository(props.match.params.chaveEmpresa, 'aoVivo').then(data=>{
            //console.log(data)
            const temp = data.filter(item =>  item.ativo =='f' )
           
            setRegistros(temp)
    
            setRegistrosFiltrados(temp);

           // setLoading(false)
        }).catch(error=>{

            console.log('Erro repository ' + error)

           // setLoading(false)
        })
        
        setTimeout(()=>{

            setLoading(false)

        }, 1200)
        
        
        
       
    }

    useEffect(()=>{
        if(registrosFiltrados.length > 0 ){

            displayPage(registrosFiltrados, page, rowsPerPage)

        }
       
    }, [registrosFiltrados])

    useEffect(()=>{

       setRegistrosFiltrados(registros)
       
    }, [registros])

   

    
    function displayPage(dados, pagina, regs) {
        
        const startIndex = (pagina) * regs;

        const endIndex = startIndex + regs;

        const pageItems = registrosFiltrados.slice(startIndex, endIndex);

        setColetas(pageItems)

        setTotalRegistros(registrosFiltrados.length);

    }

   


   
    const renderStatus = (status, ip, dias, ativo, painel = "") =>{

        if(ativo =='f'){

            return <div><Tooltip title ={'Monitoramento Inativo'}><LinkOffIcon className={'icon-status-coleta5'}/></Tooltip> <Typography style = {{fontSize:"9px", marginTop:"0px"}}>Desabilitado</Typography></div>        

        }

        if(ativo == 'f'){

            return <div><Tooltip title ={'Este equipamento esta desativado, atualizações de coleta não são processadas'}><LockIcon className={'icon-status-coleta5'}/></Tooltip> <Typography style = {{fontSize:"9px", marginTop:"0px"}}>DESATIVADA</Typography></div>        

        }

       /* if(dias > 0){
            return <div><Tooltip title ={'Sem comunicação há '+dias+' dia(s)'}><BlockIcon className={'icon-status-coleta5'}/></Tooltip> <Typography style = {{fontSize:"9px", marginTop:"0px"}}>SEM CONEXÃO</Typography></div>        
        }*/



    switch(status){
        case 1:return <div><HelpIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"8px", marginTop:"0px"}}>DESCONHECIDO</Typography></div>
            
        case 2:return <div><PowerSettingsNewIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"8px", marginTop:"0px"}}>PRONTO</Typography></div>
            
        case 3:return <div><SettingsPowerIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"8px", marginTop:"0px"}}>ENERGY SAVING</Typography></div>
            
        case 4:return <div><PrintIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"8px", marginTop:"0px"}}>IMPRIMINDO</Typography></div>

        case 5:return <div><ReportProblemIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"8px", marginTop:"0px"}}>OCORRÊNCIA</Typography></div>

        case 6:return <div><Tooltip title={painel}><NotificationsActiveIcon className={'icon-status-coleta'+status}/></Tooltip><Typography style = {{fontSize:"8px", marginTop:"0px"}}>ATENÇÃO</Typography></div>

        case 7:return <div><Tooltip title={painel}><GestureIcon className={'icon-status-coleta'+status}/></Tooltip><Typography style = {{fontSize:"8px", marginTop:"0px"}}>ATOLAMENTO</Typography></div>

        case 8:return <div><Tooltip title={painel}><DoorOpen className={'icon-status-coleta'+status}/></Tooltip><Typography style = {{fontSize:"8px", marginTop:"0px"}}>Porta Aberta</Typography></div>

        default:return <div><HelpIcon className={'icon-status-coleta'}/><Typography style = {{fontSize:"8px", marginTop:"0px"}}>DESCONHECIDO</Typography></div>
    }
        
   }


   const getFilterData = (value) =>{

        const palavraChave = value

        if(registros.length == 0){

            return;

        }

        let tempData = null

        switch(colunaFiltro){

            case 'Serial':
                tempData = registros.filter(function(equipamento) {
                    return equipamento.numeroserie.toLowerCase().includes(palavraChave.toLowerCase());
                })
                setRegistrosFiltrados(tempData);
               

            break;

            case 'Marca':
                tempData = registros.filter(function(equipamento) {
                    return equipamento.marca.toLowerCase().includes(palavraChave.toLowerCase());
                })
                 setRegistrosFiltrados(tempData);

            break;

            case 'Modelo':
                tempData=registros.filter(function(equipamento) {
                    return equipamento.modelo.toLowerCase().includes(palavraChave.toLowerCase());
                    })
                 setRegistrosFiltrados(tempData);


            break;

            case 'Marca':
                tempData = registros.filter(function(equipamento) {
                    return equipamento.marca.toLowerCase().includes(palavraChave.toLowerCase());
                    })
                    setRegistrosFiltrados(tempData);

            break;

            case 'Cliente':
                tempData = registros.filter(function(equipamento) {
                    return equipamento.razaosocial.toLowerCase().includes(palavraChave.toLowerCase());
                    })
                
                    setRegistrosFiltrados(tempData);
            break;

            case 'Endereço IP':
                tempData = registros.filter(function(equipamento) {
                    return equipamento.enderecoip.toLowerCase().includes(palavraChave.toLowerCase());
                })                                    
                 setRegistrosFiltrados(tempData);
            break;

            case 'Data de Cadastro':
               
                const parametroDataCadastro = new Date(palavraChave);
                tempData = registros.filter(function(equipamento) {
                const parData1 = new Date(equipamento.datacadastro);
                    return parData1.getDate() == parametroDataCadastro.getDate()
                })
            
                setRegistrosFiltrados(tempData);
            break;

            case 'Data de Coleta':
                const parametroDataColeta = new Date(palavraChave);
                tempData  = registros.filter(function(equipamento) {
                    const parData2 = new Date(equipamento.datacoleta);
                        return parData2.getDate() == parametroDataColeta.getDate()
                    })
                setRegistrosFiltrados(tempData);
            break;
        }
    }

    return <TemplateRevenda atualizar = {loading} full={true} breadcrumbs={
                <Breadcrumbs aria-label="breadcrumb">  
                    <Typography>Você esta em:</Typography>          
                        <Link color="inherit" to={'#'} >
                            Equipamentos Desabilitados
                        </Link>                
                </Breadcrumbs>
    }>

        
       <div style={{marginTop : '-10px'}}>
       <Grid container spacing={1}>
        <Grid item xs = {9}>
        
        </Grid>
        <Grid item xs ={2} style={{   textAlign:'right'}}>               
                <Typography  variant='subtitle1' style ={{marginTop : '5px'}}>Linhas por página</Typography> 
            </Grid>
            <Grid item xs ={1}>
                <TextField
                    select
                    SelectProps={{native : true}} 
                    variant='outlined'
                    size='small'
                    fullWidth 
                    value={rowsPerPage}    
                    onChange={(e)=>{setRowsPerPage(e.target.value)}}               
                    >
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                </TextField>
            </Grid>
       </Grid>

       
        </div>
   
       
        
        <Grid container spacing ={1} style={{marginTop:'30px'}}>
 
        <Grid item xs ={ 2} >
                <Tooltip title = 'Opções de Busca'>
                    <TextField
                        size='small'
                        variant='outlined'
                        defaultValue={colunaFiltro}
                        fullWidth
                        onChange={(e)=>{
                            setPage(0)
                            if(refRazaoSocial.current){
                                refRazaoSocial.current.value = ''
                            }
                            setColunaFiltro(e.target.value)
                        }}
                        select
                       
                        SelectProps={{native:true}}
                            InputProps={{
                                startAdornment : <InputAdornment position='start'>
                                    <Typography>Buscar por: </Typography>
                                </InputAdornment>
                            }}
                        >
                        <option value = 'Endereço IP'>Endereço IP</option>
                        
                        <option value='Data de Cadastro'>Data de Cadastro</option>
                        <option value='Data de Coleta'>Data de Leitura</option>
                        
                        <option value = 'Serial'>Serial</option>
                        <option value='Marca'>Marca</option>                        
                        <option value='Modelo'>Modelo</option>
                        <option value='Cliente'>Cliente</option>
                       
                    </TextField>
                    </Tooltip>
            </Grid>

            <Grid item xs = {4} >
                <TextField
                    InputProps={{
                        startAdornment : <InputAdornment position='start'>
                            <Typography variant='caption'>Filtro: </Typography>
                        </InputAdornment>, 
                        endAdornment : 
                            (colunaFiltro != 'Data de Cadastro' && colunaFiltro != 'Data de Coleta') ?  <InputAdornment position='end'>
                           
                        <IconeLocalizarClienteModal 
                        disabled={colunaFiltro && colunaFiltro != 'Cliente'} renderButton={true} idusuario={3} idempresa={props.match.params.chaveEmpresa} selectCliente={(value)=>{
                            setCliente({
                                idcliente: value.idcliente, 
                                idempresa : value.idempresa, 
                                razaosocial : value.razaosocial
                            })
                        }}/> 
                    </InputAdornment>: ''
                        
                    }}
                    variant='outlined'
                    inputRef={refRazaoSocial}
                    size='small'
                    fullWidth
                    type={(colunaFiltro == 'Data de Cadastro' || colunaFiltro == 'Data de Coleta') ? 'date' : ''}
                    value={cliente.razaosocial}                    
                    onKeyDown={(e)=>{
                        if(e.keyCode == 13){
                            getFilterData(e.target.value)
                        }                        
                        
                    }}
                    onChange={(e)=>{
                        if((colunaFiltro == 'Data de Cadastro' || colunaFiltro == 'Data de Coleta')){
                            getFilterData(e.target.value)
                        }
                    }}
                />
            </Grid>
  
            <Grid item xs ={ 1}>
                <Tooltip title='Clique aqui para limpar o filtro de pesquisa'><Button fullWidth  variant = 'outlined' 
                 className='btn-warning'
                 onClick={()=>{
                    setCliente([]);
                    if(refRazaoSocial.current){
                                refRazaoSocial.current.value = ''
                            }
                    setRegistrosFiltrados(registros)
                    
                }}><ClearIcon color='#f00!important'/></Button></Tooltip>
            </Grid>
            
            <Grid item xs ={ 1}>
                <Tooltip title='Clique aqui para buscar dados atualizados'>
                    <Button fullWidth variant = 'outlined' className='btn-info'  onClick={()=>{
                    setCliente([]);
                    if(refRazaoSocial.current){
                                refRazaoSocial.current.value = ''
                            }
                    getData()
                    
                }}><CachedIcon color='#f00!important'/></Button></Tooltip>
            </Grid>

            
            <Grid item xs ={ 1} >
            <Tooltip title ='Click aqui para habilitar o monitoramento deste equipamento'>
            <Badge color='primary' badgeContent={coletas.reduce((acumulado, item)=>{
                    if(item.selecionado =='t'){
                        return acumulado + 1;
                    }
                    return acumulado;
                }, 0)}>
            <Button 
                variant = 'outlined' 
                fullWidth
                className='btn-gravar'
                disabled={coletas.reduce((acumulado, item)=>{
                    if(item.selecionado =='t'){
                        return acumulado + 1;
                    }
                    return acumulado;
                }, 0) == 0}  onClick={()=>{
                    
                    Swal.fire({
                        title : 'Datacount', 
                        text : 'Deseja HABILITAR o monitoramento para este equipamento?', 
                        icon : 'question', 
                        showConfirmButton : true, 
                        confirmButtonText : 'Sim', 
                        showCancelButton : true, 
                        cancelButtonText : 'Não'
                    }).then(resposta=>{
                        if(resposta.isConfirmed){
                            const tempList = coletas.filter(item =>item.selecionado =='t').map((item)=>{
                                return item.idcoleta
                            })
                            
                            
                            
                           
                            Conexao.post('coletasController/habilitarListaMonitoramento?idempresa',{
                                listaColetas : tempList
                            }).then(response=>{
                                if(response.data.status =='OK'){
                                    Swal.fire({
                                        title : 'Datacount', 
                                        text:'Operação realizada com sucesso !', 
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer  : 1500, 
                                        icon : 'success'
                                    })
                                    updateStatusColeta(tempList, 't').then(resp=>{
                                        getData()
                                    }).catch(error=>{

                                    })
                                    
                                }else{
                                    Swal.fire({
                                        title : 'Datacount', 
                                        text:'Operação não realizada', 
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer  : 1500, 
                                        icon : 'warning'
                                    })
                                }
                            }).catch(error=>{
                                Swal.fire({
                                    title : 'Datacount', 
                                    text:'Ocorreu um erro inesperado !', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer  : 1500, 
                                    icon : 'error'
                                })
                            })                           
                        }
                    })
                    
                   
                }}>
                    <AtivarIcon /> Reativar 
            </Button>
            </Badge>
            </Tooltip>
        </Grid>
        <Grid item xs ={ 1} >
            <Tooltip title ='Click aqui para remover este equipamento definitivamente'>
                <Badge color='primary' badgeContent={coletas.reduce((acumulado, item)=>{
                    if(item.selecionado =='t'){
                        return acumulado + 1;
                    }
                    return acumulado;
                }, 0)}>
            <Button 
                fullWidth
                variant = 'outlined'
                className='btn-error' 
                disabled={coletas.reduce((acumulado, item)=>{
                    if(item.selecionado =='t'){
                        return acumulado + 1;
                    }
                    return acumulado;
                }, 0) == 0} 
                onClick={()=>{
                   
                    Swal.fire({
                        title : 'Datacount', 
                        text : 'Deseja REMOVER definitivamente este equipamento?', 
                        icon : 'question', 
                        showConfirmButton : true, 
                        confirmButtonText : 'Sim', 
                        showCancelButton : true, 
                        cancelButtonText : 'Não'
                    }).then(resposta=>{
                        if(resposta.isConfirmed){

                            const tempList = coletas.filter(item =>item.selecionado =='t').map((item)=>{
                                return item.idcoleta
                            })


                            Conexao.post('coletasController/desativarListaColeta?idempresa',{
                                listaColetas : tempList
                            }).then(response=>{
                                if(response.data.status =='OK'){
                                    Swal.fire({
                                        title : 'Datacount', 
                                        text:'Operação realizada com sucesso !', 
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer  : 1500, 
                                        icon : 'success'
                                    })
                                    inativarMonitoramento(tempList).then(resp=>{
                                        getData()
                                    }).catch(error=>{
                                        getData()
                                    })
                                }else{
                                    Swal.fire({
                                        title : 'Datacount', 
                                        text:'Operação não realizada', 
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer  : 1500, 
                                        icon : 'warning'
                                    })
                                }
                            }).catch(error=>{
                                Swal.fire({
                                    title : 'Datacount', 
                                    text:'Ocorreu um erro inesperado !', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer  : 1500, 
                                    icon : 'error'
                                })
                            })
                        }
                    })
                    
                   
                }}>
                    <LockIcon /> Remover
            </Button>
            </Badge>
            </Tooltip>
        </Grid>
            
       
        </Grid>
      
     
        <div className='div-form-container'>
        <Grid spacing={1}>
            <Table className='table table-striped table-hover table-small table-sm' >
                <TableHead>    
                <TableCell align='center' style = {{width:"3%", }}>
                <Checkbox checked={checkdAll} color='primary' onClick={()=>{
                    const temp = coletas;
                    if(checkdAll){
                        setCheckedAll(false)
             
                        for(let i = 0; i < temp.length; i++){
                            temp[i].selecionado = 'f';
                        }
                        setColetas(temp)
                    }else{
                        setCheckedAll(true)
             
                        for(let i = 0; i < temp.length; i++){
                            temp[i].selecionado = 't';
                        }
                        setColetas(temp)
                    }
                    
                }}/>
                </TableCell>    
                    <TableCell align='center' style = {{width:"5%",  fontWeight : colunaFiltro == 'Endereço IP' ? 'bold' : ''}}>Conexão</TableCell>  
                    
                    <TableCell align='center' style = {{width:"8%" ,  fontWeight : colunaFiltro == 'Data de Cadastro' ? 'bold' : ''}}>Cadastro</TableCell> 
                    <TableCell align='center' style = {{width:"8%" ,  fontWeight : colunaFiltro == 'Data de Coleta' ? 'bold' : ''}}>Leitura</TableCell>
                    <TableCell align='left' style = {{width:"10%" ,  fontWeight : colunaFiltro == 'Serial' ? 'bold' : ''}} >Serial</TableCell>
                    <TableCell align='left' style = {{width:"20%" , fontWeight : colunaFiltro == 'Modelo' ? 'bold' : ''}} >Modelo</TableCell>
                    <TableCell align='left' style = {{width:"33%", fontWeight : colunaFiltro == 'Cliente' ? 'bold' : ''}} >Cliente</TableCell>
                    <TableCell align='center' style = {{width:"10%"}}>Medidores</TableCell>
                    <TableCell align='center' style = {{width:"5%"}}>Nível Suprimento</TableCell>                   
                </TableHead>                                
                <TableBody>                
                {
                    coletas && coletas.map(function(coleta, i){
                        return <React.Fragment>
                                    <TableRow  key = {'ROWCOL'+i} className={selecionado && selecionado.idcliente == coleta.idcliente  && selecionado.numeroserie == coleta.numeroserie ? 'selectedRow' : ''}  style ={{
                                    backgroundColor : selecionado && selecionado.idcliente == coleta.idcliente  && selecionado.numeroserie == coleta.numeroserie ? "#ECEFF1" : '', 
                                    /*opacity : selecionado.idcoleta && selecionado.idcoleta != coleta.idcoleta ? 1 : 1 ,*/
                                    borderBottom : selecionado.idcoleta && selecionado.idcoleta != coleta.idcoleta ? '' : 'none!important' ,
                                    cursor :'default'
                                }}>
                        <TableCell align='center' style ={{ verticalAlign:'center'}}>
                        <div style={{display:'flex', justifyContent:'center', alignContent:'center', alignItems : 'center' , width:'100%', height:'100%', height : '40px'}}>
                        <Checkbox className='chk-select-coleta' id={'chk-select-'+i} checked={coleta.selecionado =='t'}  onChange={(e)=>{
                                 
                                   const temp = [...coletas]; // Cria uma cópia do array coletas
                                   
                                   temp[i] = { ...temp[i], selecionado: temp[i].selecionado == 'f' ? 't' : 'f' }; // Atualiza a propriedade selecionado no objeto correspondente
                                   
                                   
                                   setColetas(()=>temp); // Atualiza o estado coletas com a cópia do array atualizado
                                   
                                   
                                }}/>
                        </div>
                                          
                        </TableCell>
       
                        <TableCell align='center' style ={{ verticalAlign:'center', backgroundColor : colunaFiltro == 'Endereço IP' ? '#ECEFF1' : ''}} >
                        <div ><span style ={{fontSize:"9px"}}>{coleta.enderecoip}</span></div>
                        {renderStatus(coleta.statussistema, coleta.enderecoip, coleta.dias, coleta.ativo, coleta.mensagempainel) }
                     
                        </TableCell>                    
                                  
                        <TableCell align='center' style ={{ verticalAlign:'center', backgroundColor : colunaFiltro == 'Data de Cadastro' ? '#ECEFF1' : ''}}>
                            
                            <Typography color='primary' variant='caption' style ={{fontSize:"9px", verticalAlign:'center'}}>{coleta.datacadastro}</Typography><br/>
                            <Typography color='primary' variant='caption' style ={{fontSize:"9px", verticalAlign:'center'}}>{coleta.horacadastro ? coleta.horacadastro.substr(0, 8) : ''}</Typography>
                        </TableCell>
                        <TableCell align='center' style ={{ verticalAlign:'center',  backgroundColor : colunaFiltro == 'Data de Coleta' ? '#ECEFF1' : ''}}>
                            
                            <Typography color='primary' variant='caption' style ={{fontSize:"9px"}}>{coleta.datacoleta}</Typography><br/>
                            <Typography color='primary' variant='caption' style ={{fontSize:"9px"}}>{coleta.horacoleta ? coleta.horacoleta.substr(0, 8) : ''}</Typography>
                        </TableCell>
                        <TableCell align='left' style ={{  verticalAlign:'center', backgroundColor : colunaFiltro == 'Serial' ? '#ECEFF1' : ''}}>  
                            <Tooltip title='Clique para acessar informações e funções da coleta'>
                                <Button variant='contained' fullWidth style={{marginTop :'5%'}} onClick={()=>{ 

                                        if(selecionado && selecionado.idcliente == coleta.idcliente  && selecionado.numeroserie == coleta.numeroserie){

                                            setSelecionado([])

                                        }else{
                                            
                                            setSelecionado(coleta)

                                        }

                                    }}>
                                <Typography color='primary' className='texto-limitado'  ><strong style ={{fontSize:"11px"}}>{coleta.numeroserie}</strong> </Typography>
                                </Button>
                            </Tooltip>                                                                                                                                               
                        </TableCell>
                       
                        <TableCell align='left' style ={{ verticalAlign:'center' , backgroundColor : (colunaFiltro == 'Modelo' ||  colunaFiltro == 'Marca') ? '#ECEFF1' : ''}}>    
                             <div>
                                <Badge badgeContent={coleta.diasCadastro <= 0 ? 'Novo' : 0 } color='secondary' className='novo'>                                                                             
                                    <Typography color='primary'  ><strong style ={{fontSize:"11px"}}>{coleta.modelo}</strong></Typography>
                                </Badge>  
                            </div>                                             
                            <span style ={{fontSize:"10px"}}>{coleta.marca}</span>                                                           
                        </TableCell>
                        <TableCell align='left' style ={{ verticalAlign:'center', backgroundColor : colunaFiltro == 'Cliente' ? '#ECEFF1' : ''}}>
                            <Typography color='primary'  ><strong style ={{fontSize:"11px"}}>{coleta.razaosocial}</strong></Typography>                          
                            
                                                        
                            {coleta.monitorartoner=='t' && <div style={{color:"#444"}}><NetworkCheckIcon style={{color:"#00897b"}} /><span style ={{fontSize:"9px"}}>Alerta <strong>GLOBAL </strong> 
                                habilitado : C{coleta.monitoramentociano}% M{coleta.monitoramentomagenta}% Y{coleta.monitoramentoamarelo}% K{coleta.monitoramentopreto}% </span> </div>}
                            
                        </TableCell>
                            <TableCell align='center' style ={{ verticalAlign:'center'}}>                            
                            <Typography variant='caption' className='conter-total'>{coleta.contadortotal}</Typography><br/>
                            <Typography variant='caption' className='conter-mono'>PB: {coleta.monototal}</Typography>
                            <Typography variant='caption' className='conter-color'> CL: {coleta.colortotal}</Typography>
                        </TableCell>
                        <TableCell align='center'style ={{ verticalAlign:'center'}} >
                            <DisplayLinearToner value ={coleta.nivelciano ? coleta.nivelciano : 0} rotulo = 'ciano' alerta={coleta.monitorartoner =='t' && coleta.alertaciano == 't' } nivelAlerta={coleta.nivelalertaciano}/>
                            <DisplayLinearToner value ={coleta.nivelmagenta ? coleta.nivelmagenta : 0} rotulo='magenta' alerta={coleta.monitorartoner =='t' && coleta.alertamagenta == 't'} nivelAlerta={coleta.nivelalertamagenta}/> 
                            <DisplayLinearToner value ={coleta.nivelamarelo ? coleta.nivelamarelo : 0} rotulo ='amarelo' alerta={coleta.monitorartoner =='t' && coleta.alertaamarelo == 't'} nivelAlerta={coleta.nivelalertaamarelo}/>
                            <DisplayLinearToner value ={coleta.nivelpreto ? coleta.nivelpreto : 0} rotulo = 'preto' alerta={coleta.monitorartoner =='t' &&  coleta.alertapreto == 't'} nivelAlerta={coleta.nivelalertapreto}/>
                                            
                        </TableCell> 
                        
                    </TableRow>
                    
                    
                    </React.Fragment>
                    })
                    
                    }
                    {
                        coletas.length == 0 && <TableRow>
                        <TableCell colSpan={8} align='left'>
                            <Alert severity='info'>{loading ?'Aguarde... buscando informações.' : '* Não existem contadores para o filtro especificado.'}</Alert>
                        </TableCell>
                       
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </Grid>                     
        </div>
        <div className='fabIncluir'>
           
        </div>
     
      <ModalFullDetalhes 
        showModal={coleta && coleta.idcoleta} 
        marginTop
        setOpen = {()=>{
            setColeta([])
            //setSelecionado([])
        }}
        titulo ={coleta.idcoleta && coleta.numeroserie+' - '+coleta.modelo}>
            <DetalhesColeta state = {coleta} showModal={coleta && coleta.idcoleta} />
        </ModalFullDetalhes>

        <SideBarGestaoColetas disableButons ativarColeta  Conexao={Conexao} getData = {getData} setColeta = {(value)=>{
            setColeta(selecionado)
        }}  showModal={selecionado && selecionado.idcoleta} coleta= {selecionado} closeModal={()=>{
            setSelecionado([])
            setColeta([])
            }}/>
        <SideBarPadrao chaveDca = {chaveDca}/>
    </TemplateRevenda>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(GestaoDesabilitados)